import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: 'Ambimusic',
      POSTFIX: '',
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [{ code: 'EN', label: 'English' }],
  DEFAULT_LANGUAGE: { code: 'EN', label: 'English' },
  LOCAL_STORAGE_VERSION: 1,
};
