import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  files$ = new EventEmitter();
  constructor() {}

  uploadFiles(data: { files: File[]; playlist: number; newPlaylistName: string }) {
    this.files$.emit(data);
  }
}
