import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from 'angularfire2/database';

import { ZoneSchedule } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { BitfTryCatch } from '@decorators';

@Injectable({
  providedIn: 'root',
})
export class ZoneScheduleService extends ApiSuperService {
  constructor(protected http: HttpClient, protected fireDb: AngularFireDatabase) {
    super('zoneschedules', http, mapItem, mapItems);
  }

  @BitfTryCatch()
  create(item) {
    const fbRef = this.fireDb.object(`zones/${item.zoneId}`);
    fbRef.update({
      fbHash: Date.now(),
      event: 'cms:schedulesHashChanged',
    });
    return super.create(item);
  }

  @BitfTryCatch()
  delete(item) {
    const fbRef = this.fireDb.object(`zones/${item.zoneId}`);
    fbRef.update({
      fbHash: Date.now(),
      event: 'cms:schedulesHashChanged',
    });
    return super.delete(item);
  }
}

export function mapItem(item) {
  return new ZoneSchedule(item);
}
export function mapItems(items) {
  return items.map(item => mapItem(item));
}
