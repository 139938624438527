import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { BitfTryCatch } from '@decorators';

@Component({
  selector: 'am-field-validator',
  templateUrl: './field-validator.component.html',
  styleUrls: ['./field-validator.component.scss'],
})
export class FieldValidatorComponent implements OnInit, OnChanges {
  @Input()
  model;
  @Input()
  validateOn = [];
  constructor() {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    this.validateModel();
  }

  @BitfTryCatch()
  validateModel() {
    // this.validateOn.forEach(item => {
    //   switch (key) {
    //     case value:
    //       break;
    //     default:
    //       break;
    //   }
    // })
  }
}
