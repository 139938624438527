import { Component, OnInit, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormBuilder } from '@angular/forms';
import { tap, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

// tslint:disable-next-line:max-line-length
import { ConfirmDialogComponent } from '@shared/common-utilities/confirm-dialog/confirm-dialog.component';
import { ZoneService, StripeProductService, DialogsService } from '@services';
import { Zone } from '@models';
import { MatTableDataSource } from '@shared/table-data-source/mat-table-data-source';
import { IBitfCloseEvent } from '@app/interfaces';
import { toEventData } from '@common/utils/to-event-data';
@Component({
  selector: 'am-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit, AfterViewInit {
  @Input()
  listType = 'subscriptions';
  @Output()
  zoneSelected = new EventEmitter();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;
  filtersForm;
  displayedColumns: any[];
  dataSource: MatTableDataSource<Zone, ZoneService>;
  constructor(
    private zoneService: ZoneService,
    private stripeProductService: StripeProductService,
    public dialogService: DialogsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.createFiltersForm();
    if (this.listType === 'subscriptions') {
      this.displayedColumns = ['status', 'zoneName', 'assignedTo', 'dates', 'isWithBox', 'actions'];
    } else if (this.listType === 'notAssignedZones') {
      this.displayedColumns = ['status', 'dates', 'isWithBox', 'actions'];
    }
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<Zone, ZoneService>({
      service: this.zoneService,
      paginator: this.paginator,
      buildFilterQuery: this.buildFilterQuery.bind(this),
      buildWhereCondition: this.buildWhereCondition.bind(this),
    });

    this.dataSource.fetchData();
  }

  private createFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      query: [],
    });
    this.filtersForm.controls.query.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.dataSource.fetchData();
        })
      )
      .subscribe();
  }

  private buildFilterQuery() {
    const filters: any = {
      order: 'createdAt DESC',
    };
    if (this.listType === 'subscriptions') {
      filters.include = this.buildIncludeCondition();
    }
    filters.where = this.buildWhereCondition();
    return filters;
  }

  private buildIncludeCondition() {
    const include = [
      {
        relation: 'location',
        scope: {
          include: {
            relation: 'company',
          },
        },
      },
      {
        relation: 'company',
      },
    ];
    return include;
  }

  private buildWhereCondition() {
    let where: any = {};
    if (this.listType === 'subscriptions') {
      const query = this.filtersForm.get('query').value;
      if (query) {
        where = {
          or: [
            { name: { like: `%${query}%` } },
            { company: { name: { like: `%${query}%` } } },
            { location: { name: { like: `%${query}%` } } },
          ],
        };
      }
    } else if (this.listType === 'notAssignedZones') {
      where = { companyId: null, locationId: null };
    }
    return where;
  }

  renewSubscription(sub) {
    console.log(sub);
  }

  cancelSubscription(zone) {
    const dialogRef = this.dialogService.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      maxWidth: '400px',
      autoFocus: false,
      data: {
        title: 'Delete',
        message: `Do you want to delete this subscription?
          Note: The zone will be available until the end of subscription`,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(toEventData())
      .subscribe(confirmed => {
        if (confirmed) {
          this.stripeProductService.unsubscribeFromProductPlan(zone.id).subscribe(() => {
            // this.fetchSubscriptions();
            this.dataSource.fetchData();
          });
        }
      });
  }

  addZone(zone) {
    this.zoneSelected.emit(zone);
  }
}
