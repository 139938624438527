import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';

import { AmComponent } from './am.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogsService } from './core/services';
import { BitfMatOkCancelDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/material';

@NgModule({
  declarations: [AmComponent, BitfMatOkCancelDialogComponent],
  imports: [CoreModule, SharedModule, AppRoutingModule, TranslateModule.forRoot()],
  bootstrap: [AmComponent],
  providers: [DialogsService],
})
export class AppModule {}
