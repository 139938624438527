import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FiltersStatusService {
  filters: any = {};

  constructor() {}
}
