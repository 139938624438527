/**
 * Methods for get sources infos
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const sourceManagerParsersMixin = {
  sourceManagerParsersMixinLoaded: true,

  /**
   * @description This function parse a single library item, adding his own fallback if includeFallback and
   * adding metadata if the item is included inside a playlist or if is a playlist
   */
  _parseLibraryItem({ libraryItem, playlist, includeFallback, queueIndex, playlistIndex, scheduling }) {
    try {
      const libraryItemType = libraryItem.type;
      const parsedItem = {
        libraryItemId: libraryItem.id,
        type: libraryItemType,
      };

      if (typeof queueIndex !== 'undefined') {
        parsedItem.queueIndex = queueIndex;
      }
      if (typeof playlistIndex !== 'undefined') {
        parsedItem.playlistIndex = playlistIndex;
      }

      if (includeFallback) {
        parsedItem.fallback = this._parseLibraryItemFallback(libraryItem);
      }
      if (libraryItemType === 'Playlist') {
        parsedItem.data = [];
        libraryItem.playlist.playlistItems.forEach((playlistItem, index) => {
          const data = this._parseLibraryItemData(playlistItem.libraryItem);
          data.playlistIndex = index;
          parsedItem.data.push(data);
        });
        parsedItem.metaData = {
          playlist: {
            name: libraryItem.playlist.name,
            playlistId: libraryItem.playlist.id,
            isShuffle: libraryItem.playlist.isShuffle,
          },
        };
      } else {
        parsedItem.data = this._parseLibraryItemData(libraryItem);
        // Note song inside a playlist
        if (playlist) {
          parsedItem.metaData = {
            playlist: {
              name: playlist.name,
              playlistId: playlist.id,
              isShuffle: playlist.isShuffle,
            },
          };
        }
      }

      if (scheduling) {
        parsedItem.metaData = parsedItem.metaData || {};
        parsedItem.metaData.scheduling = scheduling;
      }

      return parsedItem;
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'sourceManagerParsersMixin',
        functionName: '_parseLibraryItem',
      });
    }
  },

  /**
   * @description This function parse a library item and create the the zone source node
   */
  _parseLibraryItemData(libraryItem) {
    try {
      const type = libraryItem.type;
      switch (type) {
        case 'Song':
          return {
            id: libraryItem.song.id,
            type,
            uri: encodeURI(`${this.environment.apiUrl}songs/${libraryItem.song.id}/get-uri`),
            name: libraryItem.song.name,
            artist: libraryItem.song.artist,
            fileName: libraryItem.song.fileName,
          };
          break;
        case 'Playlist':
          return undefined;
          break;
        case 'Stream':
          return {
            id: libraryItem.stream.id,
            type,
            url: encodeURI(libraryItem.stream.url),
            name: libraryItem.stream.name,
            imageUrl: libraryItem.stream.imageUrl,
          };
          break;
      }
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'sourceManagerParsersMixin',
        functionName: '_parseLibraryItemData',
      });
    }
  },

  /**
   * @description This function parse a library item and return his own fallbackItem (without considering the zone fallback)
   */
  _parseLibraryItemFallback(libraryItem) {
    try {
      const type = libraryItem.type;
      switch (type) {
        case 'Song':
        case 'Playlist':
          return undefined;
          break;
        case 'Stream':
          if (!libraryItem.stream.fallbackLibraryItem) {
            return undefined;
          }
          return libraryItem.stream.fallbackLibraryItem.playlist.playlistItems.map(playlistItem =>
            this._parseLibraryItemData(playlistItem.libraryItem)
          );
          break;
      }
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'sourceManagerParsersMixin',
        functionName: '_parseLibraryItemFallback',
      });
    }
  },
};
