import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

const SHARED_MODULES = [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule];
const SHARED_COMPONENTS = [];
const SHARED_DIRECTIVES = [];
const SHARED_BITF_COMPONENTS = [];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_BITF_COMPONENTS],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_BITF_COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonSharedModule {
  // This should only be called inside the CoreModule!
}
