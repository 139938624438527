import { Component, OnInit } from '@angular/core';

import { StoreService } from '@core/services';
import { AuthService } from '@core/services/api';

import { APP_VERSION, BUILD_DATE } from '@env/version';
import { environment } from '@env';
@Component({
  selector: 'am-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  appVersion = APP_VERSION;
  appBuildAt = BUILD_DATE;
  environment = '';

  logoPath;
  userSubscription;
  constructor(public storeService: StoreService, public authService: AuthService) {}

  ngOnInit() {
    this.environment = environment.name !== 'production-env' ? `- ${environment.name}` : '';
  }
}
