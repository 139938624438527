import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { from, of } from 'rxjs';
import { switchMap, catchError, tap } from 'rxjs/operators';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { SessionService, NotifierService } from '@core/services';
import { ApiService } from '@core/services/api';
import { BitfTryCatch } from '@decorators';
import { Song, Playlist, PlaylistItem } from '@models';

@Component({
  selector: 'am-dock-uploader',
  templateUrl: './dock-uploader.component.html',
  styleUrls: ['./dock-uploader.component.scss'],
})
export class DockUploaderComponent implements OnInit, OnDestroy {
  isUploading = false;
  isUploadComplete = false;
  isUploadingFileNumber = 0;
  uploadingFileName = '';
  uploadFileErrorMessage = '';
  nOfFilesToUpload = 0;
  filesUploadedPercentage = 0;
  songTags = {};
  uploadQueue: any[] = [];
  subscription;

  constructor(
    private sessionService: SessionService,
    private apiService: ApiService,
    private notifierService: NotifierService,
    private bitfErrorHandlerService: BitfErrorHandlerService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.subscription = this.sessionService.files$.subscribe(data => {
      this.handleFilesUpload(data);
    });
  }

  onRefreshLibrary() {
    this.resetUploader();
    if (this.location.path() === '/library') {
      location.reload();
    } else {
      this.router.navigate(['/library']);
    }
  }

  cancelUpload() {
    this.resetUploader();
  }

  private handleFilesUpload(data) {
    if (data.newPlaylistName) {
      const playlist = new Playlist({ name: data.newPlaylistName });
      this.apiService.libraryItem.createItem(playlist).subscribe(
        libraryItem => {
          data.newPlaylist = libraryItem.playlist;
          this.addNewFilesToUploadQueue(data);
        },
        () => {
          // Playlist creation failed but keep upload files
          this.addNewFilesToUploadQueue(data);
        }
      );
    } else {
      this.addNewFilesToUploadQueue(data);
    }
  }

  private addNewFilesToUploadQueue(data) {
    const shouldCallUploadNextFile = !this.uploadQueue.length;

    const files = [].slice.call(data.files).filter(this.filterAudioFiles);
    if (files.length === 0) {
      return;
    }

    const newUploaders = files.map(file => {
      return {
        uploader$: this.createUploaderObservable(file, data),
        fileName: file.name,
        numberOfTries: 0,
      };
    });
    this.uploadQueue.push(...newUploaders);

    this.nOfFilesToUpload += files.length;
    this.isUploadComplete = false;

    // NOTE: if the queue is not empty before to add it means that there is already an uploader active
    // which will cnsume the whole queue, so we don't have to start another one
    if (shouldCallUploadNextFile) {
      this.uploadNextFile();
    }
  }

  private createUploaderObservable(file, { playlist, newPlaylist }) {
    return from(this.apiService.song.extractMeta(file)).pipe(
      catchError((error, obs) => {
        this.bitfErrorHandlerService.handle(error, {
          className: 'DockUploaderComponent',
          functionName: 'extractMeta',
        });
        return of({});
      }),
      tap((tags: any) => {
        this.songTags = tags;
        this.uploadingFileName = tags.name || file.name;
        this.isUploadingFileNumber = this.nOfFilesToUpload - this.uploadQueue.length + 1;
        this.filesUploadedPercentage = (this.isUploadingFileNumber / this.nOfFilesToUpload) * 100;
      }),
      // NOTE: look for duplicated song
      switchMap(() =>
        this.apiService.song.find({
          where: { fileName: file.name },
          include: 'libraryItem',
        })
      ),
      // NOTE: upload the file, or return the libraryItem if is a duplicate
      switchMap(duplicateSong => {
        if (duplicateSong && duplicateSong[0] && duplicateSong[0].libraryItem) {
          return of(duplicateSong[0].libraryItem);
        }
        return this.apiService.song.createSongFile(file).pipe(
          catchError(err => {
            this.notifierService.error(`Error uploading: ${this.uploadingFileName}`);
            throw err;
          })
        );
      }),
      // NOTE: create the song item in the DB and return the library item or return the library item straight
      // if is a duplicate
      switchMap((libraryItem: any) => {
        if (libraryItem.instanceOf === 'LibraryItem') {
          // return the libraryItem related to the duplicated song
          return of(libraryItem);
        }
        const song = new Song(Object.assign({}, { fileName: file.name }, this.songTags));
        song.name = song.name || file.name;
        return this.apiService.libraryItem.createItem(song).pipe(
          catchError(err => {
            this.notifierService.error(`Error creating the song in the DB: ${this.uploadingFileName}`);
            throw err;
          })
        );
      }),
      // NOTE: add the song to the playlist if specified. Run as async
      tap(libraryItem => {
        if (libraryItem) {
          this.addSongToPlaylist(playlist || newPlaylist, libraryItem);
        }
      }),
      // NOTE: This is an existing playlist so update queueHash for zones that are using this playlist.
      // Run as async
      tap(() => {
        if (playlist || newPlaylist) {
          this.apiService.playlist.refreshHashes(playlist || newPlaylist);
        }
      })
    );
  }

  private uploadNextFile() {
    const uploader = this.uploadQueue[0];
    if (!uploader) {
      this.uploadComplete();
      return;
    }
    this.isUploading = true;
    if (uploader.numberOfTries === 2) {
      // NOTE: remove from the queue that file which has problems with the upload
      this.uploadFileErrorMessage = `Error uploading ${uploader.fileName}`;
      this.uploadQueue.shift();
      this.uploadNextFile();
    }
    uploader.uploader$.subscribe(
      () => {
        this.uploadQueue.shift();
        this.uploadNextFile();
      },
      () => {
        uploader.numberOfTries++;
        this.uploadNextFile();
      }
    );
  }

  @BitfTryCatch()
  addSongToPlaylist(playlist, libraryItem) {
    if (playlist) {
      const playlistItem = new PlaylistItem({
        playlistId: playlist.id,
        libraryItem,
      });
      this.apiService.playlistItem.create(playlistItem).subscribe();
    }
  }

  private filterAudioFiles(file) {
    const index = file.name.lastIndexOf('.mp3');
    return file.name.substring(index) === '.mp3';
  }

  private uploadComplete() {
    this.isUploading = false;
    this.isUploadComplete = true;
    this.nOfFilesToUpload = 0;
    this.uploadQueue = [];
    this.notifierService.success('Upload completed!');
  }

  private resetUploader() {
    this.isUploading = false;
    this.isUploadComplete = false;
    this.uploadQueue = [];
    this.uploadFileErrorMessage = '';
    this.nOfFilesToUpload = 0;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
