/**
 * Methods for schedules infos
 * All of this methods are callable via playerEngineInstance.mehtod
 * // NOTE: we don't need to store queue and playlist indexes since are scheduled and depends from the time
 * @mixin
 */
export const sourceManagerSchedulesMixin = {
  sourceManagerSchedulesMixinLoaded: true,

  _startSchedulesRunner() {
    if (this._scheduleRunnerHandler) {
      return;
    }

    this._scheduleRunner();
    this._scheduleRunnerHandler = setInterval(() => {
      this._scheduleRunner();
    }, 1000);
  },

  _scheduleRunner() {
    if (this.playerEngineMode === 'player') {
      this._playerScheduleRunner();
    } else {
      this._remoteScheduleRunner();
    }
  },

  // This runner will do a push if the current item is a stream and the schedule change
  _playerScheduleRunner() {
    try {
      // If we are playing a playlist backup the indexes
      if (!this.activeScheduleItem) {
        this.backupQueueIndex = this.queueIndex;
        this.backupPlaylistIndex = this.playlistIndex;
      }
      const activeScheduleItem = this._getActiveScheduleItem();
      // The schedule is not active or is just finished
      if (!activeScheduleItem) {
        this.isSchedulingActive = false;

        // Play the standard queue
        this.queueItems = this.zoneQueueItems;

        // From schedule to the standard queue
        if (this.activeScheduleItem) {
          // Reset the indexes to resume the standard queue
          this.queueIndex = this.backupQueueIndex;
          this.playlistIndex = this.backupPlaylistIndex;

          if (this.isFirstStateReceived) {
            // if was playing a stream then we have to dec the indexes to resume the stream
            const previousLibraryItem = this.queueItems[this.queueIndex];
            if (previousLibraryItem && previousLibraryItem.libraryItem.type === 'Stream') {
              this._decrementIndexes();
            }

            if (!this.zone.source) {
              this._updateZoneSource({
                event: 'pe:playback:queueChanged',
              });
            } else {
              // If a stream is playing we have to send a push with the new source, because the stream never ends
              // We have to send this event on FB (not internal) because the slave player
              // must receive the updated indexes
              this._updateZone({
                event: 'pe:playback:forceNext',
                queueIndex: this.queueIndex,
                playlistIndex: this.playlistIndex,
              });
            }
          }
          this.activeScheduleItem = undefined;
        }
      } else {
        this.isSchedulingActive = true;

        if (!this.queueItems || !this.queueItems[0] || this.queueItems[0] !== activeScheduleItem) {
          // Assign to the queue the active schedule
          this.queueItems = [activeScheduleItem];

          // this flag seems not to be used
          this.isScheduleJustChanged = true;
        }

        // If a stream is playing we have to send a forceNext to ask to terminate the stream
        if (!this.activeScheduleItem || activeScheduleItem.id !== this.activeScheduleItem.id) {
          // Reset indexes since a new schedule item is just added to the queue
          this.queueIndex = 0;
          this.playlistIndex = -1;

          if (this.isFirstStateReceived) {
            if (!this.zone.source) {
              this._updateZoneSource({
                isPlaying: true,
                event: 'pe:playback:queueChanged',
              });
            } else {
              // Force the termination of the current item (needed for streams)
              // NOTE: with this setup if the zone is in stop the stream will not start
              // The zone must be left in play (should be ok)
              // We have to send this event on FB (not internal) because the slave player
              // must receive the updated indexes
              this._updateZone({
                event: 'pe:playback:forceNext',
                queueIndex: this.queueIndex,
                playlistIndex: this.playlistIndex,
              });
            }
          }
        }
        this.activeScheduleItem = activeScheduleItem;
      }
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'sourceManagerSchedulesMixin',
        functionName: '_scheduleRunner',
      });
    }
  },

  _remoteScheduleRunner() {
    try {
      const activeScheduleItem = this._getActiveScheduleItem();
      if (!activeScheduleItem) {
        this.isSchedulingActive = false;

        // Play the standard queue
        this.queueItems = this.zoneQueueItems;
      } else {
        this.isSchedulingActive = true;

        if (!this.queueItems || !this.queueItems[0] || this.queueItems[0] !== activeScheduleItem) {
          // Assign to the queue the active schedule
          this.queueItems = [activeScheduleItem];
        }
      }
      this.activeScheduleItem = activeScheduleItem;
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'sourceManagerSchedulesMixin',
        functionName: '_remoteScheduleRunner',
      });
    }
  },

  _getActiveScheduleItem() {
    try {
      if (!this.zoneSchedules || !this.zoneSchedules.length) {
        return false;
      }
      let activeScheduleItem;
      this.zoneSchedules.forEach(zoneSchedule => {
        zoneSchedule.libraryItem.schedule.scheduleItems.forEach(scheduleItem => {
          if (this._timeIsInRange(scheduleItem)) {
            activeScheduleItem = scheduleItem;
          }
        });
      });
      return activeScheduleItem;
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'sourceManagerSchedulesMixin',
        functionName: '_getActiveScheduleItem',
      });
    }
  },

  _timeIsInRange(scheduleItem) {
    try {
      const now = this._getTime();
      let startTime;
      let endTime;
      if (scheduleItem.schedulingMode === 'daily' && scheduleItem.dailySchedule) {
        const dailySchedule = scheduleItem.dailySchedule;
        startTime = dailySchedule.startTime.split(':');
        endTime = dailySchedule.endTime.split(':');
      } else if (scheduleItem.schedulingMode === 'weekly' && scheduleItem.weeklySchedule) {
        const dayIndex = this._getDayIndex();
        const weeklySchedule = scheduleItem.weeklySchedule[dayIndex];
        if (!weeklySchedule || !weeklySchedule.startTime || !weeklySchedule.endTime) {
          return false;
        }
        startTime = weeklySchedule.startTime.split(':');
        endTime = weeklySchedule.endTime.split(':');
      }
      if (!startTime || !endTime) {
        return false;
      }
      startTime = this._lineariseTime(startTime);
      endTime = this._lineariseTime(endTime);
      if (endTime > startTime) {
        if (now >= startTime && now < endTime) {
          return true;
        }
      } else {
        if (now >= startTime || now < endTime) {
          return true;
        }
      }
      return false;
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'sourceManagerSchedulesMixin',
        functionName: '_timeIsInRange',
      });
    }
  },

  _lineariseTime([hours, minutes]) {
    return +hours * 60 + +minutes;
  },

  _getDayIndex() {
    let day = new Date();
    day = day.getDay();
    if (day === 0) {
      return 6;
    } else {
      return day - 1;
    }
  },

  _getTime() {
    if (this.zone._mockTime) {
      return this._lineariseTime(this.zone._mockTime.split(':'));
    } else {
      const date = new Date();
      return this._lineariseTime([date.getHours(), date.getMinutes()]);
    }
  },

  setMockTime(mockTime) {
    if (!mockTime) {
      mockTime = false;
    }
    this._updateZone({
      event: 'pe:debug',
      _mockTime: mockTime,
    });
  },

  goToFade() {
    this._updateZone({
      event: 'pe:debug:goToFade',
    });
  },
};
