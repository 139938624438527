import { Injectable } from '@angular/core';

// tslint:disable-next-line:max-line-length
const silentSoundSrc =
  'data:audio/mp3;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU2LjM2LjEwMAAAAAAAAAAAAAAA//OEAAAAAAAAAAAAAAAAAAAAAAAASW5mbwAAAA8AAAAEAAABIADAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV6urq6urq6urq6urq6urq6urq6urq6urq6v////////////////////////////////8AAAAATGF2YzU2LjQxAAAAAAAAAAAAAAAAJAAAAAAAAAAAASDs90hvAAAAAAAAAAAAAAAAAAAA//MUZAAAAAGkAAAAAAAAA0gAAAAATEFN//MUZAMAAAGkAAAAAAAAA0gAAAAARTMu//MUZAYAAAGkAAAAAAAAA0gAAAAAOTku//MUZAkAAAGkAAAAAAAAA0gAAAAANVVV';

@Injectable({
  providedIn: 'root',
})
export class AudioPoolService {
  public audios: HTMLAudioElement[];
  private index = 0;
  public unlocked = false;

  constructor() {
    this.audios = [
      document.createElement('audio'),
      document.createElement('audio'),
      document.createElement('audio'),
    ];
    this.audios.forEach(audio => {
      audio.src = silentSoundSrc;
    });
  }

  unlockIfNeeded() {
    let p = Promise.resolve();
    if (!this.unlocked) {
      const playPromises = this.audios.map(audio => {
        const pause = () => {
          audio.pause();
        };
        audio.src = silentSoundSrc;
        return Promise.resolve(audio.play())
          .then(pause)
          .catch(pause);
      });
      p = Promise.all(playPromises).then(() => {
        this.unlocked = true;
      });
    }

    return p;
  }

  getAudio() {
    const audio = this.audios[this.index];
    this.index = (this.index + 1) % this.audios.length;
    return audio;
  }
}
