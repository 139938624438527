/**
 * Methods for get sources infos
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const sourceManagerIndexesMixin = {
  sourceManagerIndexesMixinLoaded: true,

  _incrementIndexes() {
    this._updateIndexes('inc');
  },

  _decrementIndexes() {
    this._updateIndexes('dec');
  },

  _updateIndexes(operation = 'inc') {
    let currentQueueItem = this.queueItems[this.queueIndex];
    let libraryItem = currentQueueItem && currentQueueItem.libraryItem;
    if (!currentQueueItem) {
      // Queue changed reset indexes
      this.queueIndex = this.playlistIndex = 0;
      return;
    }

    if (libraryItem && libraryItem.type === 'Playlist' && libraryItem.playlist.isShuffle) {
      this._updatePlaylistIndexesWithShuffle(operation, currentQueueItem, libraryItem);
    } else {
      this._doUpdateIndexes(operation, currentQueueItem, libraryItem);
      // Check if the next / prev item is a playlist with isShuffle we have to run the shuffle function
      currentQueueItem = this.queueItems[this.queueIndex];
      libraryItem = currentQueueItem && currentQueueItem.libraryItem;
      if (libraryItem.type === 'Playlist' && libraryItem.playlist.isShuffle) {
        this._updatePlaylistIndexesWithShuffle(operation, currentQueueItem, libraryItem, false);
      }
    }

    // NOTE this shouldn't be needed, the Map should be cleared on the last next/prev in the shuffled
    // playlist
    // If the source change check if we have to reset the shuffleQueueItemsPlayed map
    // currentQueueItem = this.queueItems[this.queueIndex];
    //   libraryItem = currentQueueItem && currentQueueItem.libraryItem;
    //   if (libraryItem.type === 'Playlist' && libraryItem.playlist.isShuffle) {
    //     this.shuffleQueueItemsPlayed = {
    //       [playlist.id]: this.shuffleQueueItemsPlayed[playlist.id] || []
    //     };
    //   }
  },

  _updatePlaylistIndexesWithShuffle(operation, currentQueueItem, libraryItem, skipCurrentSong = true) {
    try {
      const playlist = libraryItem.playlist;
      if (!playlist) {
        return;
      }
      const playlistItems = playlist.playlistItems;
      if (!playlistItems.length) {
        return;
      }
      let itemsToBePlayed = [...playlistItems];

      // Clear other playlist nodes to keep shuffleQueueItemsPlayed clean
      this._purgeShuffleMap();

      const shuffleQueueItemsPlayed = this.shuffleQueueItemsPlayed[playlist.id];

      if (shuffleQueueItemsPlayed.length) {
        // Check if we have played the whole playlist
        // Comparing the lenght of the two arrays is not eough since the playlist can change due to some CMS playlist
        // updates

        // Check if every element in the current queue have been played
        itemsToBePlayed = playlistItems.filter(
          item => !shuffleQueueItemsPlayed.find(item2 => item2.id === item.id)
        );
        if (!itemsToBePlayed.length) {
          // All the elements in the current queue have been played
          shuffleQueueItemsPlayed.length = 0;
          // Play next item in the queue if exist
          // this.playlistIndex = 0;
          if (operation === 'inc') {
            this._incrementQueueIndex();
          }
          if (operation === 'dec') {
            this._decrementQueueIndex();
          }

          currentQueueItem = this.queueItems[this.queueIndex];
          if (
            currentQueueItem.libraryItem.type === 'Playlist' &&
            currentQueueItem.libraryItem.playlist.isShuffle
          ) {
            // Next item is a playlist with shuffle
            libraryItem = currentQueueItem && currentQueueItem.libraryItem;
            this._updatePlaylistIndexesWithShuffle(operation, currentQueueItem, libraryItem);
          }
          return;
        }
      } else {
        const sourceInPlaylist = playlistItems.find(item => {
          const currentSourceId = this.zone.source && this.zone.source.data && this.zone.source.data.id;
          if (item.libraryItem.song.id === currentSourceId) {
            return true;
          }
          return false;
        });
        if (sourceInPlaylist && skipCurrentSong && this.zone.isPlaying) {
          // Consider the current source as played
          // because when you enter in the zone and _updatePlaylistIndexesWithShuffle is not called
          // we need to mark this song as played
          shuffleQueueItemsPlayed.push(sourceInPlaylist);
          // Remove this from the itemsToBePlayed
          itemsToBePlayed = itemsToBePlayed.filter(item => item.id !== sourceInPlaylist.id);
        }
      }
      // Shuffle the full array every time, this is because the playlist could changed at any time
      // due to a CMS update and we have to consider new items
      if (itemsToBePlayed.length > 1) {
        this._shuffleArray(itemsToBePlayed);
      }

      // Grab a random item from the shuffled list
      // const nextItem =
      //   itemsToBePlayed[Math.floor(Math.random() * itemsToBePlayed.length)];
      // shuffleQueueItemsPlayed.push(nextItem);
      shuffleQueueItemsPlayed.push(itemsToBePlayed[0]);
      this.playlistIndex = playlistItems.indexOf(itemsToBePlayed[0]);

      console.log(shuffleQueueItemsPlayed.map(a => `${a.libraryItem.id} - ${a.libraryItem.song.name}`));
      // console.log(
      //   shuffleQueueItemsPlayed.map(
      //     a => `playlist item:${a.id} - libraryItem:${a.libraryItem.id} - ${a.libraryItem.song.name}`
      //   )
      // );
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'sourceManagerIndexesMixin',
        functionName: '_updatePlaylistIndexesWithShuffle',
      });
      return;
    }
  },

  _purgeShuffleMap() {
    // This is needed in case we put in play an element of another playlist or stream or song
    // we have to reset the random Map otherwise when the playlist will run again will skip prev played items
    // we are storing in shuffleQueueItemsPlayed library item and could be that a library item is inside
    // the shuffled playlist and outside. so we have to create a namespace of random items that is specific
    // for the playlist
    const currentQueueItem = this.queueItems[this.queueIndex];
    const libraryItem = currentQueueItem && currentQueueItem.libraryItem;
    if (libraryItem.type === 'Playlist' && libraryItem.playlist.isShuffle) {
      this.shuffleQueueItemsPlayed = {
        [libraryItem.playlist.id]: this.shuffleQueueItemsPlayed[libraryItem.playlist.id] || [],
      };
    } else {
      this.shuffleQueueItemsPlayed = {};
    }
  },

  _doUpdateIndexes(operation = 'inc', currentQueueItem, libraryItem) {
    try {
      // Fallback index management for streams
      if (!this.isConnected && libraryItem.type === 'Stream') {
        const streamFallbackPlaylistItems = this._getStreamFallbackPlaylistItems(libraryItem);
        if (!streamFallbackPlaylistItems || !streamFallbackPlaylistItems.length) {
          return;
        }
        if (operation === 'inc') {
          this.fallbackQueueIndex++;
          if (!streamFallbackPlaylistItems[this.fallbackQueueIndex]) {
            this.fallbackQueueIndex = 0;
          }
        } else {
          this.fallbackQueueIndex--;
          if (!streamFallbackPlaylistItems[this.fallbackQueueIndex]) {
            this.fallbackQueueIndex = streamFallbackPlaylistItems.length - 1;
          }
        }

        return;
      }

      // playlist index
      if (libraryItem.type === 'Playlist') {
        if (operation === 'inc') {
          if (this.playlistIndex === libraryItem.playlist.playlistItems.length - 1) {
            this._incrementQueueIndex();
          } else {
            this.playlistIndex++;
          }
        } else if (operation === 'dec') {
          if (this.playlistIndex === 0) {
            this._decrementQueueIndex();
          } else {
            this.playlistIndex--;
          }
        }
      } else {
        // song indexes
        if (operation === 'inc') {
          this._incrementQueueIndex();
        } else if (operation === 'dec') {
          this._decrementQueueIndex();
        }
      }
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'sourceManagerIndexesMixin',
        functionName: '_doUpdateIndexes',
      });
    }
  },

  _incrementQueueIndex() {
    // If there is a next queue item play that
    if (this.queueItems[this.queueIndex + 1]) {
      this.queueIndex++;
    } else {
      this.queueIndex = 0;
    }
    // Reset playlistIndex
    this.playlistIndex = 0;
  },

  _decrementQueueIndex() {
    // If there is a prev queue item play that
    if (this.queueItems[this.queueIndex - 1]) {
      this.queueIndex--;
    } else {
      //Play the last  item of the list
      this.queueIndex = this.queueItems.length - 1;
    }
    // If the prev item is a playlist start from the last playlist item
    if (this.queueItems[this.queueIndex].libraryItem.type === 'Playlist') {
      const playlistItems =
        this.queueItems[this.queueIndex].libraryItem.playlist &&
        this.queueItems[this.queueIndex].libraryItem.playlist.playlistItems;

      this.playlistIndex = (playlistItems && playlistItems.length - 1) || 0;
    } else {
      this.playlistIndex = 0;
    }
  },

  // Fisher–Yates shuffle
  // NOTE: this is a mutable operation
  _shuffleArray(array) {
    var m = array.length,
      t,
      i;

    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  },
};
