import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Box } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';

@Injectable({
  providedIn: 'root',
})
export class BoxService extends ApiSuperService {
  constructor(protected http: HttpClient) {
    super('boxes', http, mapBox, mapBoxs);
  }
}

export function mapBox(box) {
  return new Box(box);
}
export function mapBoxs(boxes) {
  return boxes.map(box => mapBox(box));
}
