import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { IBitfApiResponse } from '@interfaces';
import { User } from '@models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  constructor(public injector: Injector) {
    super('users', injector, environment.mockApiUrl, 'mockDbParser');
  }

  getMe(): Observable<IBitfApiResponse<User>> {
    return super.request<User>({
      path: '/me',
      method: 'GET',
      disableHideLoader: true,
    });
  }
}
