export class LinearTween {
  constructor({ from = 0, to = 0, duration = 1, startTime = 0 }) {
    this.from = from;
    this.to = to;
    this.duration = duration;
    this.startTime = startTime;
  }

  update(time) {
    const elapsed = time - this.startTime;
    return this.from + ((this.to - this.from) * elapsed) / this.duration;
  }
}
