<footer class="d-none d-sm-block"
  *ngIf="!authService.user">
  <mat-toolbar class="d-flex justify-content-between">
    <img *ngIf="storeService.store.logoUrl"
      [src]="storeService.store.logoUrl"
      class="d-none d-lg-block main-logo" />
    <img *ngIf="storeService.store.logoSymbolUrl"
      [src]="storeService.store.logoSymbolUrl"
      class="d-block d-lg-none main-logo" />
    <div class="d-flex flex-column">
      <nav>
        <div class="d-flex justify-content-end mat-caption">
          <a target="_blank"
            mat-button
            href="https://ambimusic.com/">ambimusic.com</a>
          <a target="_blank"
            mat-button
            href="https://ambimusic.com/terms/">Terms</a>
          <a target="_blank"
            mat-button
            href="https://ambimusic.com/privacy-policy">Privacy Policy</a>
        </div>
      </nav>
      <div class="text-right mat-caption pr-3 copyright">
        Ambimusic Srl Via dei Pepi, 76r 50122 Firenze Italy | v{{appVersion}} - {{appBuildAt | date}}
        {{environment}}
      </div>
    </div>
  </mat-toolbar>
</footer>