import { Subject } from 'rxjs';

import { runMixin } from './lib/run/run.mixin.js';
import { connectDisconnectMixin } from './lib/run/run-connect-disconnect.mixin.js';
import { watchersMixin } from './lib/run/run-watchers.mixin.js';

import { stateManagerMixin } from './lib/state-manger.mixin.js';
import { playbackManagerMixin } from './lib/playback-manager.mixin.js';
import { queueManagerMixin } from './lib/queue-manager.mixin.js';
import { sourceManagerMixin } from './lib/source-manager/source-manager.mixin.js';
import { sourceManagerParsersMixin } from './lib/source-manager/source-manager-parsers.mixin.js';
import { sourceManagerIndexesMixin } from './lib/source-manager/source-manager-indexes.mixin.js';
import { sourceManagerSchedulesMixin } from './lib/source-manager/source-manager-schedules.mixin.js';
import { zoneManagerMixin } from './lib/zone-manager.mixin.js';
import { scheduleManagerMixin } from './lib/schedule-manager.mixin.js';
import { utilsMixin } from './lib/utils.mixin.js';
import { silenceDetectionMixin } from './lib/silence-detection/silence-detection.mixin.js';
import { boxManagerMixin } from './lib/box-manager.mixin.js';

import { AudioFader } from './lib/audio-fader/audio-fader.js';

/** Class representing a player engine. */
export class PlayerEngine {
  /**
   * Convert a string containing two comma-separated numbers into a point.
   * @constructor
   * @param {Object} configObj.firebase.fireDb - in the form returned by angularfire (observable) this.fireDb.object(`zones/${this.zoneId}`)
   * @param {Object} configObj.firebase.database - standard form this.fireDb.database() where you can bind to .on('value', cb)
   * @param {number} configObj.zoneId - Zone id
   * @param {Object} configObj.api.request - Http helper based on observable. Must be configured with proper retry, error handling and Auth token
   * @param {Object} configObj.api.fetch - Http helper based on observable. No need to configure Auth and prepernd api path to the URL
   * @param {Object} configObj.cacheEngine - localstorage or similar chache engine with the localstorage interface
   * @param {string} configObj.environment.storageVersion - env config
   * @param {string} configObj.environment.pingUrl - env config
   * @return {PlayerEngine} A new player engine instance All methods inside the mixins are available.
   */
  constructor(configObj = {}) {
    this._initProps();
    Object.assign(this, configObj);

    // Apply mixins
    Object.assign(this, runMixin);
    Object.assign(this, stateManagerMixin);
    Object.assign(this, playbackManagerMixin);
    Object.assign(this, queueManagerMixin);
    Object.assign(this, sourceManagerMixin);
    Object.assign(this, zoneManagerMixin);
    Object.assign(this, scheduleManagerMixin);
    Object.assign(this, utilsMixin);
    Object.assign(this, silenceDetectionMixin);
    Object.assign(this, boxManagerMixin);

    this._onInit();
  }

  _onInit() {
    this._initStateManager();
  }

  _initProps() {
    this.name = 'PlayerEngine';
    /**
     * This variable defines the player egine mode: 'player' | 'remoteControl'
     * eg: player will run schedule engine
     * eg: remote will forward only pe:playback events
     * @public
     */
    this.playerEngineMode = 'player';
    /**
     * It Contains the zone infos with settings and fallbackLibraryItem (fallback playlist)
     * @public
     */
    this.zone = {};
    /**
     * It Contains the current playing queue
     * @public
     */
    this.queueItems = [];
    /**
     * It Contains the zone queue: all the library items associated to this zone
     * @public
     */
    this.zoneQueueItems = [];
    this.shuffleQueueItemsPlayed = {};
    /**
     * It Contains the zone schedules: all the schedules associated to this zone
     * @public
     */
    this.zoneSchedules = [];
    /**
     * It Contains the active schedule
     * @public
     */
    this.activeScheduleItem = undefined;
    /**
     * True if connected to internet, flag managed by firebase ref('.info/connected') event
     * @public
     */
    this.isConnected = undefined;
    this.isReConnected = false;

    this.firebase = {
      fbDb: undefined,
      databaseRef: undefined,
      zoneRef: undefined,
      boxRef: undefined,
    };
    this.fbHash = undefined;
    /**
     * Observable taht emit new playerEngine states. This observable return a full zone element
     * @public
     */
    this.state$ = new Subject();
    this.zoneStateSubscription = undefined;
    this.isFirstStateReceived = false;
    this.isScheduleJustChanged = false;

    // Ping params
    /**
     * Number of times that the pinger will retry to get the json file, default set to 1
     * @public
     */
    this.maxPingRetry = 1;
    /**
     * Ping timeout time
     * @public
     */
    this.pingTimeout = 5000;
    /**
     * Fallback Ping timeout time
     * @public
     */
    this.fallbackPingTimeout = 3000;
    /**
     * Ping interval time
     * @public
     */
    this.pingInterval = 5000;
    this.isPinging = false;

    this.silenceDetectedSource = undefined;
    this.resumedStream = undefined;

    this._updateDbZoneVolume$ = new Subject();
    this._updateDbZone$ = new Subject();

    this.onlineCheckers = [];
    this.socketLib = undefined;

    this._clearCaches();
    this._resetIndexes();

    this.isDestroying = false;
  }

  _resetIndexes() {
    /**
     * Queue index
     * @public
     */
    this.queueIndex = 0;
    this.backupQueueIndex = 0;
    /**
     * Fallback queue index
     * @public
     */
    this.fallbackQueueIndex = 0;
    /**
     * Playlist Index
     * @public
     */
    this.playlistIndex = 0;
    this.backupPlaylistIndex = 0;
  }

  _clearCaches() {
    // TODO delete localstorage
    this.allSourcesCache = undefined;
  }

  onDestroy() {
    try {
      this.isDestroying = true;
      // console.log('plyer engine destroyed');
      if (this.zoneStateSubscription) {
        this.zoneStateSubscription.unsubscribe();
      }
      if (this.zoneBoxSubscription) {
        this.zoneBoxSubscription.unsubscribe();
      }
      if (this.boxNeworkWatcherIntervalHandler) {
        clearInterval(this.boxNeworkWatcherIntervalHandler);
        this.boxNeworkWatcherIntervalHandler = undefined;
      }
      if (this._scheduleRunnerHandler) {
        clearInterval(this._scheduleRunnerHandler);
        this._scheduleRunnerHandler = undefined;
      }
      this.firebase.database.ref('.info/connected').off();

      this.onlineCheckers.forEach(socket => {
        socket.close();
      });
      this.onlineCheckers = [];
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'PlayerEngine',
        functionName: 'onDestroy',
      });
    }
  }
}

PlayerEngine.AudioFader = AudioFader;

export const mixins = {
  runMixin,
  connectDisconnectMixin,
  watchersMixin,
  stateManagerMixin,
  playbackManagerMixin,
  queueManagerMixin,
  sourceManagerMixin,
  sourceManagerParsersMixin,
  sourceManagerIndexesMixin,
  sourceManagerSchedulesMixin,
  zoneManagerMixin,
  scheduleManagerMixin,
  silenceDetectionMixin,
  boxManagerMixin,
};

export const playerEngineClasses = {
  AudioFader,
};
