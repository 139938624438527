import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from 'angularfire2/database';
import { map } from 'rxjs/operators';

import { Stream } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';

@Injectable({
  providedIn: 'root',
})
export class StreamService extends ApiSuperService {
  constructor(protected http: HttpClient, private fireDb: AngularFireDatabase) {
    super('streams', http, mapStream, mapStreams);
  }

  update(item) {
    return super.update(item).pipe(
      map(stream => {
        // Non blocking action
        const streamsAssignedToZones = this.request({
          method: 'get',
          path: `queueItems`,
          filter: {
            where: { libraryItemId: item.libraryItemId },
            fields: { zoneId: true },
          },
        }).subscribe(queueItems => {
          queueItems.forEach(queueItem => {
            const fbRef = this.fireDb.object(`zones/${queueItem.zoneId}`);
            fbRef.update({
              fbHash: Date.now(),
              event: 'cms:queueHashChanged',
            });
          });
        });
        return stream;
      })
    );
  }
}

export function mapStream(stream) {
  return new Stream(stream);
}
export function mapStreams(streams) {
  return streams.map(stream => mapStream(stream));
}
