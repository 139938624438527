import { catchError, debounceTime } from 'rxjs/operators';

/**
 * Methods for update the zone state (Firebase and DB)
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const stateManagerMixin = {
  stateManagerMixinLoaded: true,

  _updateZone(data = {}, internalEvent) {
    try {
      Object.assign(this.zone, data);

      if (this.isConnected && !internalEvent) {
        data.fbHash = data.fbHash || Date.now();
        this.fbHash = data.fbHash;
        if (!data.eventData) {
          data.eventData = null;
        }

        if (this._shouldUpdateZoneOnFb(this.zone.event)) {
          this.firebase.zoneRef.update(data);
        }

        // TODO debounce the API CALL
        if (this._shouldUpdateZoneOnDb(this.zone.event)) {
          // Reduce the amount of calls for the volume change
          if (this.zone.event.includes('volume')) {
            this._updateDbZoneVolume$.next();
          } else {
            this._updateZoneDb();
          }
        }
      }

      // FIXME: this is dirty
      // Check perche' dobbiamo disabilitare questo evento
      if (!this.zone.event.includes('initPlayer')) {
        this._pushZone();
      }
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'stateManagerMixin',
        functionName: '_updateZone',
      });
    }
  },

  _updateZoneSource(data, internalEvent) {
    // NOTE: getSource() could modify indexes
    const source = this.getSource() || false;
    const obj = {
      source: source,
      queueIndex: this.queueIndex,
      playlistIndex: this.playlistIndex,
    };
    Object.assign(obj, data);
    this._updateZone(obj, internalEvent);
  },

  _pushZone(data = {}) {
    // Keep zone updated
    Object.assign(this.zone, { isConnected: this.isConnected }, data);
    // NOTE: forward only player engine events (not cms:)
    if (this.zone.event.includes('pe')) {
      this.state$.next(this.zone);
    }
  },

  _pushBox(boxState) {
    if (!boxState) {
      return;
    }
    // Extend zone with box firebase state
    Object.assign(this.zone, {
      boxState,
      event: 'pe:boxStateChanged',
    });
    // NOTE: disabled push event to box and UI
    // this.state$.next(this.zone);
  },

  _updateBoxRef(state) {
    try {
      this.firebase.boxRef.update(state);
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'stateManagerMixin',
        functionName: '_updateBoxRef',
      });
    }
  },

  _shouldUpdateZoneOnFb(event) {
    if (
      this.zone.event.includes('execute') ||
      this.zone.event.includes('playback') ||
      this.zone.event.includes('volume') ||
      this.zone.event.includes('initPlayer') ||
      this.zone.event.includes('createZoneNode') ||
      this.zone.event.includes('debug')
    ) {
      return true;
    }
    return false;
  },

  _shouldUpdateZoneOnDb(event) {
    if (this.zone.event.includes('playback') || this.zone.event.includes('volume')) {
      return true;
    }
    return false;
  },

  _initStateManager() {
    this._updateDbZoneVolume$.pipe(debounceTime(500)).subscribe(() => {
      this._updateZoneDb();
    });
  },

  _updateZoneDb() {
    this.api
      .request({
        method: 'patch',
        path: `zones/${this.zoneId}`,
        body: this.zone.dbState,
      })
      .pipe(
        catchError(() => {
          return this.api.request({
            method: 'patch',
            path: `zones/${this.zoneId}`,
            body: this.zone.dbState,
          });
        })
      )
      .subscribe(() => {}, () => {});
  },
};
