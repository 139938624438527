import { throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { User, Company, Location, Zone } from '@models';
import { AuthService, mapZones, mapCompanies, mapLocations } from '@core/services/api';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { StoreService } from '@common/core/services';
import { EStoreActions } from '@enums';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ApiSuperService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected storeService: StoreService,
    protected bitfErrorHandlerService: BitfErrorHandlerService
  ) {
    super('customers', http, mapUser, mapUsers);
  }

  delete(user) {
    return super.delete(user).pipe(
      tap(() => {
        // Logout only logged user not id admin is deleting user
        if (this.authService.user.id === user.id) {
          this.authService.user = undefined;
          this.authService.authToken = undefined;
        }
      })
    );
  }

  login(credentials) {
    console.log(`${environment.apiUrl}${this.name}/login?include=user`);
    this.http.post<any>(`${environment.apiUrl}${this.name}/login?include=user`, credentials).subscribe();
    return this.http.post<any>(`${environment.apiUrl}${this.name}/login?include=user`, credentials).pipe(
      map(data => {
        const user = mapUser(data.user);
        this.authService.user = user;
        this.storeService.setStore(store => {
          store.user = user;
        }, EStoreActions.SET_USER);
        this.authService.authToken = {
          id: data.id,
          created: data.created,
          ttl: data.ttl,
          userId: data.userId,
        };
        return user;
      }),
      catchError((error, obs) => {
        if (error.status !== 401) {
          this.bitfErrorHandlerService.catchObs(error, obs, {
            className: 'UserService',
            functionName: 'login',
          });
        }
        return throwError(error);
      })
    );
  }

  rawLogin(credentials) {
    return this.http.post<any>(`${environment.apiUrl}${this.name}/login`, credentials).pipe(
      catchError((error, obs) => {
        if (error.status !== 401) {
          this.bitfErrorHandlerService.catchObs(error, obs, {
            className: 'UserService',
            functionName: 'rawLogin',
          });
        }
        return throwError(error);
      })
    );
  }

  loginWithToken() {
    return this.findById(this.authService.authToken.userId).pipe(
      map(data => {
        const user = mapUser(data);
        this.authService.user = user;
        this.storeService.setStore(store => {
          store.user = user;
        }, EStoreActions.SET_USER);
        return true;
      }),
      catchError(error => {
        // console.error('loginWithToken error', error);
        // Delete token
        this.authService.authToken = undefined;
        return throwError(error);
      })
    );
  }

  resetPassword(email) {
    return this.http.post<any>(`${environment.apiUrl}${this.name}/reset-pasword`, {
      email: email,
    });
  }

  changePassword(password) {
    return this.http.post<any>(`${environment.apiUrl}${this.name}/change-password`, password);
  }

  logout() {
    return this.http.post<any>(`${environment.apiUrl}${this.name}/logout`, {}).pipe(
      tap(() => {
        this.authService.user = undefined;
        this.authService.authToken = undefined;
        this.storeService.setStore(store => {
          store.user = undefined;
        }, EStoreActions.SET_USER);
      })
    );
  }

  getAccessTreeMap(user = this.authService.user) {
    return this.http.get<any>(`${environment.apiUrl}${this.name}/${user.id}/access-tree`).pipe(
      map(accessTreeMap => {
        return {
          Company: accessTreeMap.companies.companies.map(c => new Company(c)),
          Location: accessTreeMap.locations.locations.map(l => new Location(l)),
          Zone: accessTreeMap.zones.zones.map(z => new Zone(z)),
        };
      }),
      catchError((error, obs) =>
        this.bitfErrorHandlerService.catchObs(error, obs, {
          className: 'UserService',
          functionName: 'getAccessTreeMap',
        })
      )
    );
  }

  getAccessTree(user = this.authService.user) {
    return this.getAccessTreeMap(user).pipe(
      map((accessTreeMap: any) => {
        accessTreeMap.Zone.forEach(z => {
          // z.selected = true;
          if (z.companyId) {
            const company = accessTreeMap.Company.find(c => c.id === z.companyId);
            if (company) {
              company.children.push(z);
            }
          }
          if (z.locationId) {
            const location = accessTreeMap.Location.find(l => l.id === z.locationId);
            if (location) {
              location.children.push(z);
            }
          }
        });

        accessTreeMap.Location.forEach(l => {
          // l.selected = true;
          const company = accessTreeMap.Company.find(c => c.id === l.companyId);
          if (company) {
            company.children.push(l);
          }
        });
        // accessTreeMap.Company.forEach(c => (c.selected = true));
        return accessTreeMap.Company;
      }),
      catchError((error, obs) =>
        this.bitfErrorHandlerService.catchObs(error, obs, {
          className: 'UserService',
          functionName: 'getAccessTree',
        })
      )
    );
  }

  findCompaniesTree(user = this.authService.user) {
    return this.http
      .get<Company[]>(`${environment.apiUrl}${this.name}/${user.id}/companies`)
      .pipe(map(mapCompanies));
  }

  findCompanies(user = this.authService.user) {
    return this.http
      .get<Company[]>(`${environment.apiUrl}${this.name}/${user.id}/companies`)
      .pipe(map(mapCompanies));
  }

  findLocations(company, user = this.authService.user) {
    const filter = { where: { companyId: company.id } };
    return this.http
      .get<Location[]>(
        `${environment.apiUrl}${this.name}/${user.id}/locations`,
        company
          ? {
              params: { filter: JSON.stringify(filter) },
            }
          : {}
      )
      .pipe(map(mapLocations));
  }

  findZones(location, filter, user = this.authService.user) {
    return this.http
      .get<Zone[]>(
        `${environment.apiUrl}${this.name}/${user.id}/zones`,
        location
          ? {
              params: {
                filter: JSON.stringify(filter),
              },
            }
          : {}
      )
      .pipe(map(zones => mapZones(zones)));
  }

  addRef(user = this.authService.user, releation, refId) {
    return super.addRef(user, releation, refId);
  }

  deleteRef(user = this.authService.user, releation, refId) {
    return super.deleteRef(user, releation, refId);
  }

  canAddZone() {
    return this.http
      .get(`${environment.apiUrl}zones/count`, {
        params: {
          where: JSON.stringify({ accountId: this.authService.user.accountId }),
        },
      })
      .pipe(
        map((data: any) => {
          // The first one is for free
          if (data.count === 0) {
            return true;
          }
          if (data.count < this.authService.user.zonesBought) {
            return true;
          }
          return false;
        })
      );
  }

  updateStripeUserAccount(body) {
    return super.request({ method: 'patch', body, path: 'customers/update-stripe-user-account' });
  }

  removeSource(body) {
    return super.request({ method: 'post', body, path: 'customers/remove-stripe-source' });
  }
}

export function mapUser(user) {
  return new User(user);
}
export function mapUsers(users) {
  return users.map(user => mapUser(user));
}
