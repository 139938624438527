<mat-dialog-content class="p-0 m-0 h-100 d-flex flex-column">
  <div class="d-flex flex-column flex-grow-1">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div class="d-flex flex-column flex-grow-1">
      <div class="flex-grow-1">
        <p [innerHtml]="data.message" class="mat-body"></p>
      </div>
      <div class="d-flex justify-content-end">
        <button mat-button (click)="onCancel()" class="mr-3">Cancel</button>
        <button mat-raised-button color="accent" (click)="onConfirm()">Confirm</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
