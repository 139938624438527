import { Zone } from '@models';
import { BitfTryCatch } from '@decorators';

export class Account {
  instanceOf = 'Account';
  id?: number;
  createdAt?: string;
  ownerId?: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  get serialised() {
    return {};
  }
}
