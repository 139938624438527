import { Component, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';

import { Zone } from '@models';
import { AuthService } from '@services';

@Component({
  selector: 'am-full-player',
  templateUrl: './full-player.component.html',
  styleUrls: ['./full-player.component.scss'],
})
export class FullPlayerComponent implements AfterViewInit {
  @Input()
  zone: Zone;
  @Output()
  playerEngineReadyEvent = new EventEmitter<any>();
  @ViewChild('webPlayer')
  webPlayer: ElementRef;
  @ViewChild('boxPlayer')
  boxPlayer: ElementRef;
  playerEngine;
  player;
  isReady = false;
  constructor(public authService: AuthService) {}

  ngAfterViewInit() {
    this.player = this.webPlayer || this.boxPlayer;
  }

  playerEngineReady(playerEngine) {
    this.playerEngine = playerEngine;
    this.isReady = true;
    this.playerEngineReadyEvent.emit(this.playerEngine);
  }
}
