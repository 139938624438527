<div *ngIf="messages" class="message-box message">
  TODO messages array
</div>
<div *ngIf="message" class="message-box message">
  {{message}}
</div>

<div *ngIf="warnings" class="message-box warning">
  TODO messages array
</div>
<div *ngIf="warning" class="message-box warning">
  {{warning}}
</div>

<div *ngIf="errors" class="message-box error">
  TODO errors array
</div>
<div *ngIf="error" class="message-box error">
  {{error}}
</div>