import { LibraryItem } from '@models';

export class ZoneSchedule {
  instanceOf = 'ZoneSchedule';
  id: number;
  zoneId: number;
  libraryItemId: number;
  libraryItem;

  constructor(data: any = {}) {
    if (data.libraryItem) {
      data.libraryItem = new LibraryItem(data.libraryItem);
    }
    Object.assign(this, data);
  }

  get serialised() {
    return {
      id: this.id,
      zoneId: this.zoneId,
      libraryItemId: this.libraryItemId,
    };
  }
}
