export class Transaction {
  id: number;
  createdAt: Date;
  modifiedAt: Date;
  cart: any;
  invoiceLink: string;
  invoicePdf: string;
  accountId: number;
  customerId: number;
  metadata: any;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  get serialised() {
    return {};
  }
}
