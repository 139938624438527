import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
/**
 * Methods for load the zone infos
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const zoneManagerMixin = {
  zoneManagerMixinLoaded: true,

  _loadZone() {
    const conditions = {
      include: {
        fallbackLibraryItem: [{ playlist: { playlistItems: 'libraryItem' } }],
      },
    };
    return this.api
      .request({
        method: 'get',
        path: `zones/${this.zoneId}`,
        filter: conditions,
      })
      .pipe(
        map(zone => {
          zone = zone.data || zone;
          if (!zone) {
            throw new Error(`no zone with id ${this.zoneId} found`);
          }

          this._setZoneCache(zone);
          return new Zone(zone);
        }),
        catchError(error => {
          const cache = this._getZoneCache();
          if (cache) {
            return of(new Zone(cache.zone));
          }
          throw `Zone: ${this.zoneId} can't be fetched online and is not in cache`;
        })
      );
  },

  _refreshZone({ isZoneSettingsChange }) {
    subscription = this._loadZone().subscribe(
      zone => {
        console.log('zone updated:', zone);
        Object.assign(this.zone, zone);
        if (isZoneSettingsChange) {
          this._pushZone({ event: 'pe:state:zoneSettingsChanged' });
          return;
        }
        // reset the allSourcesCache cache prop, set by getAllSources method
        this._clearCaches();
        this.triggerSourceUpdateAfterCmsChangeEvent('zoneChanged');
      },
      error => {
        this.errorHandler.handle(error, {
          className: 'zoneManagerMixin',
          functionName: '_refreshZone subscription',
        });
      }
    );
  },

  _setZoneCache(zone) {
    try {
      const cacheObj = {
        created: Date.now(),
        version: this.environment.storageVersion,
        zone,
      };
      this.cacheEngine.setItem(`zone-${this.zoneId}`, JSON.stringify(cacheObj));
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'zoneManagerMixin',
        functionName: '_setZoneCache',
      });
    }
  },

  _getZoneCache() {
    try {
      const cache = this.cacheEngine.getItem(`zone-${this.zoneId}`);
      return JSON.parse(cache) || undefined;
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'zoneManagerMixin',
        functionName: '_getZoneCache',
      });
    }
  },
};

/** Class representing a Zone. */
export class Zone {
  constructor(zone) {
    this.queueHash = '';
    this.skipCrossFade = false;
    // this.zoneHash = '';
    // this.fbHash = '';
    Object.assign(this, zone);
  }

  /**
   * Return the zone state, sent to FB
   */
  get dbState() {
    return {
      queueIndex: this.queueIndex,
      playlistIndex: this.playlistIndex,
      volume: this.volume,
      prevVolume: this.prevVolume,
      isPlaying: this.isPlaying,
      isMute: this.isMute,
      isRandom: this.isRandom,
    };
  }

  get fbState() {
    return {
      queueIndex: this.queueIndex,
      playlistIndex: this.playlistIndex,
      volume: this.volume,
      prevVolume: this.prevVolume,
      isPlaying: this.isPlaying,
      isMute: this.isMute,
      isRandom: this.isRandom,
      source: this.source,
      skipCrossFade: this.skipCrossFade,
    };
  }
}
