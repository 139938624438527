import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from 'angularfire2/database';
import { switchMap, map } from 'rxjs/operators';

import { Zone } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { BoxService } from '@core/services/api';

import { BitfTryCatch } from '@decorators';

@Injectable({
  providedIn: 'root',
})
export class ZoneService extends ApiSuperService {
  constructor(
    protected http: HttpClient,
    private fireDb: AngularFireDatabase,
    private boxService: BoxService
  ) {
    super('zones', http, mapZone, mapZones);
  }

  @BitfTryCatch()
  getNotAssignedZones() {
    return super.find({ where: { companyId: null, locationId: null } });
  }

  @BitfTryCatch()
  create(item, updateFb = false) {
    // Create a new zone as placeholder in FB (do we need this?)
    // if (updateFb) {
    //   const fbRef = this.fireDb.object(`zones/${item.id}`);
    //   fbRef.update(item.state);
    // }
    return super.create(item);
  }

  @BitfTryCatch()
  update(item, updateFb = false) {
    if (updateFb) {
      const fbRef = this.fireDb.object(`zones/${item.id}`);
      const updateObj = Object.assign(item.state, {
        fbHash: Date.now(),
        event: 'cms:zoneHashChanged',
      });
      fbRef.update(updateObj);
    }
    return super.update(item);
  }

  @BitfTryCatch()
  unassignZone(item) {
    return this.boxService.find({ where: { zoneId: item.id } }).pipe(
      switchMap((boxes: any) => {
        const timeoutHandler = setTimeout(() => {
          // NOTE wait that the list is removed from the DOM / and the player engine is destroyed
          this.fireDb.object(`zones/${item.id}`).remove();
          if (boxes) {
            boxes.forEach(box => {
              const fbRef = this.fireDb.object(`boxes/${box.boxId}`);
              fbRef.update({ zoneId: null });
            });
          }
          clearTimeout(timeoutHandler);
        }, 800);
        // We are not deleting the zone, we are setting the name of the zone to '' and unlinking
        // the zone
        Object.assign(item, { locationId: null, companyId: null });
        return super.update(item);
      })
    );
  }

  @BitfTryCatch()
  delete(item) {
    // TODO remove this duplicate code
    return this.boxService.find({ where: { zoneId: item.id } }).pipe(
      switchMap((boxes: any) => {
        const timeoutHandler = setTimeout(() => {
          // NOTE wait that the list is removed from the DOM / and the player engine is destroyed
          this.fireDb.object(`zones/${item.id}`).remove();
          if (boxes) {
            boxes.forEach(box => {
              const fbRef = this.fireDb.object(`boxes/${box.boxId}`);
              fbRef.update({ zoneId: null });
            });
          }
          clearTimeout(timeoutHandler);
        }, 800);
        return super.delete(item);
      })
    );
  }

  getFirstFreeZone() {
    return super.find({
      where: { or: [{ name: '' }, { name: null }] },
      limit: 1,
      order: 'createdAt ASC',
    });
  }
}

export function mapZone(zone) {
  return new Zone(zone);
}
export function mapZones(zones) {
  return zones.map(zone => mapZone(zone));
}
