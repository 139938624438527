import { environment } from '@env';
import { LibraryItem } from '@models';

export class Song {
  instanceOf = 'Song';
  id: number;
  libraryItemId: number;
  libraryItem: LibraryItem;
  fileName: string;
  name: string;
  image?: string;
  artist?: string;
  album?: string;
  genre?: string;
  year?: number;
  songToUpload?: File;

  constructor(data = {}) {
    Object.assign(this, data);
    if (this.libraryItem) {
      this.libraryItem = new LibraryItem(this.libraryItem);
    }
  }

  get serialised() {
    return {
      id: this.id,
      libraryItemId: this.libraryItemId,
      name: this.name,
      fileName: this.fileName,
      image: this.image,
      artist: this.artist,
      album: this.album,
      genre: this.genre,
      year: this.year,
    };
  }

  get uri() {
    return `${environment.apiUrl}songs/${this.id}/get-uri`;
  }
}
