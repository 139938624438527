import { environment } from '@env';
import { Location, Zone } from '@models';
import { BitfTryCatch } from '@decorators';

export class Company {
  instanceOf = 'Company';
  id: number;
  accountId: number;
  name: string;
  logoName: string;
  logoToUpload?: File;
  address: string;
  vat: string;
  children: any = [];
  selected;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  @BitfTryCatch()
  get locations() {
    return this.children.filter(el => el.instanceOf === 'Location');
  }
  set locations(locations) {
    this.children = this.children.concat(locations.map(el => new Location({ ...el, parent: this })));
  }

  @BitfTryCatch()
  get zones() {
    const a = this.children.filter(el => el.instanceOf === 'Zone');
    return a;
  }
  set zones(zones) {
    this.children = this.children.concat(zones.map(el => new Zone({ ...el, parent: this })));
  }

  @BitfTryCatch()
  get logoUrl() {
    if (!this.logoName) {
      return '';
    }
    const baseUrl = `https://${environment.awsS3CompanyLogosBucket}.${
      environment.awsS3CompanyLogosBucketZone
    }`;
    return `${baseUrl}${this.logoName}`;
  }
  set logoUrl(val) {}

  get serialised() {
    // TODO locations and zones mapped and serialised
    return {
      id: this.id,
      accountId: this.accountId,
      name: this.name,
      logoName: this.logoName,
      address: this.address,
      vat: this.vat,
    };
  }
}
