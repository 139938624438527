import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBitfCloseEvent } from '@app/interfaces';
import { EBitfCloseEventStatus } from '@app/enums';

@Component({
  selector: 'am-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent, IBitfCloseEvent<boolean>>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onConfirm() {
    this.dialogRef.close({
      data: true,
      status: EBitfCloseEventStatus.OK,
    });
  }

  onCancel() {
    this.dialogRef.close({
      data: false,
      status: EBitfCloseEventStatus.CANCEL,
    });
  }
}
