import { Component, OnInit, Input, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { Zone } from '@models';
// tslint:disable-next-line:max-line-length
import { ConfirmDialogComponent } from '@shared/common-utilities/confirm-dialog/confirm-dialog.component';
import { BitfTryCatch } from '@decorators';
import { DialogsService } from '@app/core/services';
import { toEventData } from '@common/utils/to-event-data';
import { ERoleActions, ERoleMode } from '@enums';
import { AuthService } from '@services';

@Component({
  selector: 'am-player-queue',
  templateUrl: './player-queue.component.html',
  styleUrls: ['./player-queue.component.scss'],
})
export class PlayerQueueComponent implements OnInit, OnDestroy, OnChanges {
  displayedColumns = ['name', 'actions'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  @Input()
  zone: Zone;
  @Input()
  playerEngine;
  queueItemsList: any = [];
  queueItems: any;
  fbSubscription: Subscription;
  playerEngineReady = false;
  ERoleActions = ERoleActions;
  ERoleMode = ERoleMode;

  constructor(private dialogService: DialogsService, public authService: AuthService) {}

  ngOnInit() {}

  @BitfTryCatch()
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.playerEngine.currentValue && !this.playerEngineReady) {
      this.playerEngineReady = true;
      this.playerEngine.state$.subscribe((state: any) => {
        // Wait for _remoteScheduleRunner in case this emit is triggered bu the box
        // _playerScheduleRunner method
        setTimeout(() => {
          this.updateQueue();
        }, 1000);
      });
    }
  }

  @BitfTryCatch()
  updateQueue() {
    if (this.queueItems === this.playerEngine.queueItems) {
      this.hilightSelectedItem();
    } else {
      this.queueItems = this.playerEngine.queueItems;
      this.parseQueue();
    }
  }

  @BitfTryCatch()
  parseQueue() {
    // console.log('queueItems', this.playerEngine.queueItems);
    this.queueItemsList = [];
    this.queueItems.forEach((queueItem, i) => {
      if (queueItem.libraryItem.type === 'Playlist') {
        queueItem.libraryItem.playlist.playlistItems.forEach((playlistItem, j) => {
          const libraryItemType = playlistItem.libraryItem.type;
          this.queueItemsList.push(
            Object.assign(playlistItem.libraryItem[libraryItemType.toLowerCase()], {
              insideOf: 'Playlist',
              instanceOf: libraryItemType,
              playlistName: queueItem.libraryItem.playlist.name,
              queueIndex: i,
              playlistIndex: j,
            })
          );
        });
      } else {
        const libraryItemType = queueItem.libraryItem.type;
        this.queueItemsList.push(
          Object.assign(queueItem.libraryItem[libraryItemType.toLowerCase()], {
            instanceOf: libraryItemType,
            queueIndex: i,
            queueItemId: queueItem.id,
          })
        );
      }
    });
    this.updateTable(this.queueItemsList);
  }

  updateTable(items) {
    this.dataSource = new MatTableDataSource(items);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.hilightSelectedItem();
  }

  // @BitfTryCatch()
  // deleteItem(queueItem) {
  //   this.askForConfirm({
  //     title: 'Delete',
  //     message: `Do you want to delete ${queueItem.name} ?`
  //   }).subscribe(confirmed => {
  //     if (confirmed) {
  //       this.apiService.queueItem.delete(queueItem).subscribe(result => {
  //         this.loadQueue();
  //       });
  //     }
  //   });
  // }

  playItem(item) {
    this.playerEngine.playItem(item.queueIndex, item.playlistIndex || 0, { skipCrossFade: true });
  }

  askForConfirm(data) {
    const dialogRef = this.dialogService.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      maxWidth: '400px',
      autoFocus: false,
      data,
    });
    return dialogRef.afterClosed().pipe(toEventData());
  }

  @BitfTryCatch()
  private hilightSelectedItem() {
    // Select item
    if (!this.playerEngine || !this.queueItemsList.length) {
      return;
    }
    this.queueItemsList.forEach(queueItem => {
      queueItem.selected = false;
    });

    let currentQueueItemIndex = this.queueItemsList.findIndex(
      item => item.queueIndex === this.playerEngine.queueIndex
    );

    currentQueueItemIndex += this.playerEngine.playlistIndex;

    if (this.queueItemsList[currentQueueItemIndex]) {
      this.queueItemsList[currentQueueItemIndex].selected = true;
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  ngOnDestroy() {
    // this.fbSubscription.unsubscribe();
  }
}
