<h2 mat-dialog-title>{{ dialogData.title | translate }}</h2>
<mat-dialog-content *ngIf="dialogData.message">{{ dialogData.message | translate }}
</mat-dialog-content>

<mat-dialog-actions class="row mt-1"
  [ngClass]="
    !dialogData.cancelText || !dialogData.okText ? 'justify-content-center' : 'justify-content-end'
  ">
  <button mat-flat-button
    (click)="onCancel()"
    *ngIf="dialogData.cancelText">
    {{ dialogData.cancelText | translate }}
  </button>

  <button mat-flat-button
    (click)="onOk()"
    class="ml-3"
    color="accent"
    [mat-dialog-close]="true"
    *ngIf="dialogData.okText">
    {{ dialogData.okText | translate }}
  </button>
</mat-dialog-actions>