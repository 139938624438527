<mat-card *ngIf="playerEngine"
    class="mb-3">
    <div id="audioEls"
        class="d-flex"></div>
    <div class="row align-items-center justify-content-between flex-nowrap overflow-auto">
        <div class="col-auto d-flex flex-column">
            <span *ngIf="player?.playerType === 'webPlayer'">
                V1: ({{player?.audioEls[0]?.volume |number:'1.2-2' }})
                V2: ({{player?.audioEls[1]?.volume | number:'1.2-2' }})
            </span>

            <button mat-raised-button
                color="primary"
                (click)="goToFade()">
                Go to fade
            </button>
        </div>

        <div class="col-auto d-flex flex-column justify-content-center">
            <div>Mock time set: {{ playerEngine?.zone?._mockTime }}</div>
            <input matInput
                class="my-1"
                placeholder="00:00"
                name="mockTime"
                style="width: 120px"
                [(ngModel)]="mockTime" />
            <button mat-raised-button
                color="primary"
                (click)="playerEngine.setMockTime(mockTime)">
                Set mock time
            </button>
            <button mat-raised-button
                color="primary"
                (click)="playerEngine.setMockTime('16:06')">
                Set 16:06
            </button>
            <button mat-raised-button
                color="primary"
                (click)="playerEngine.setMockTime('16:07')">
                Set 16:07
            </button>
            <button mat-raised-button
                color="primary"
                (click)="playerEngine.setMockTime('16:08')">
                Set 16:08
            </button>
            <button mat-raised-button
                color="primary"
                (click)="playerEngine.setMockTime('16:09')">
                Set 16:09
            </button>
        </div>

        <div class="col-auto d-flex flex-column justify-content-center">
            <button mat-raised-button
                color="primary"
                (click)="getAllSources()">
                Get all sources
            </button>
        </div>

        <div class="col-auto d-flex flex-column justify-content-center">
            isConnected:{{ playerEngine.isConnected }}
            <button mat-raised-button
                class="my-1"
                color="primary"
                (click)="playerEngine.connect()">
                Connect
            </button>
            <button mat-raised-button
                color="primary"
                (click)="playerEngine.disconnect()">
                Disconnect
            </button>
        </div>

        <div class="col-auto d-flex flex-column justify-content-center">
            <div>silenceDetectedSource id: {{ playerEngine?.silenceDetectedSource?.id }}</div>
            <button mat-raised-button
                class="my-1"
                color="primary"
                (click)="playerEngine.silenceDetected(playerEngine.zone.source.data)">
                Detect Silence
            </button>
            <button mat-raised-button
                color="primary"
                (click)="playerEngine.streamResumed(playerEngine.silenceDetectedSource)">
                Stream resumed
            </button>
        </div>
    </div>

</mat-card>