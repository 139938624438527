import { LinearTween } from './linear-tween.js';

const NullFader = Object.freeze({
  isRunning: false,
  start() {},
  stop() {},
  tick() {},
});

export class AudioFader {
  constructor({ audioEl, duration, from, to, zoneVolume, operation }) {
    this.audioEl = audioEl;
    this.duration = duration;
    this.from = from;
    this.to = to;
    this.zoneVolume = zoneVolume;
    this.isRunning = false;
    this.operation = operation;
  }

  start() {
    if (Number.isNaN(this.audioEl.duration)) {
      throw new Error('Audio element duration is not a number');
    }
    this.isRunning = true;
    this.startTime = this.audioEl.currentTime;
    this.endTime = Math.min(this.startTime + this.duration, this.audioEl.duration);
    const duration = this.endTime - this.startTime;

    this.linearTween = new LinearTween({
      from: this.from,
      to: this.to,
      duration,
      startTime: this.startTime,
    });
  }

  stop() {
    this.isRunning = false;
  }

  tick() {
    if (!this.isRunning) {
      return;
    }

    const currentTime = this.audioEl.currentTime;
    if (currentTime > this.endTime || this.audioEl.ended) {
      this.audioEl.volume = this.to * this.zoneVolume();
      this.isRunning = false;
    } else {
      const currentVolume = this.linearTween.update(currentTime);
      this.audioEl.volume = currentVolume * this.zoneVolume();
    }
  }

  static get NullFader() {
    return NullFader;
  }
}
