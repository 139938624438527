import { UserSettings, Account } from '@models';
export const defaultUserRights = {
  isSuperAdmin: false,
  canControlPlayers: true,
  canChangeVolume: true,
  zonesBought: 0,
  zonesAccess: [],
  _zonesAccessMap: [],
};

export const defaultUser = {
  ...defaultUserRights,
  name: '',
  surname: '',
  email: '',
  theme: 'blue-dark-theme',
};

export class User {
  id?: number;
  account: Account;
  accountId: number;

  name: string;
  surname: string;
  company: string;
  vatNumber: string;
  city: string;
  address: string;
  postalCode: string;
  state: string;

  email: string;
  password?: string;
  theme: string;
  zonesBought: number;
  isHyperAdmin?: boolean;
  isSuperAdmin: boolean;
  _canControlPlayers: boolean;
  _canChangeVolume: boolean;
  canViewLogs: false;
  settings: UserSettings;

  constructor(user?) {
    if (user) {
      Object.assign(this, user);
      if (user.settings) {
        this.settings = new UserSettings(user.settings);
      } else {
        this.settings = new UserSettings();
      }
      if (user.account) {
        this.account = new Account(user.account);
      }
    } else {
      Object.assign(this, { ...defaultUser, settings: new UserSettings() });
    }
  }

  get serialised() {
    const obj: any = {
      id: this.id,
      name: this.name,
      surname: this.surname,
      company: this.company,
      vatNumber: this.vatNumber,
      city: this.city,
      address: this.address,
      postalCode: this.postalCode,
      state: this.state,
      email: this.email,
      theme: this.theme,
      isSuperAdmin: this.isSuperAdmin,
      canControlPlayers: this.canControlPlayers,
      canChangeVolume: this.canChangeVolume,
      settings: this.settings.serialised,
    };
    if (this.password) {
      obj.password = this.password;
    }
    return obj;
  }

  get hasAllRequiredProfileFields() {
    const requiredFields = [
      'name',
      'surname',
      'company',
      'vatNumber',
      'city',
      'address',
      'postalCode',
      'state',
    ];

    return requiredFields.every(field => this[field]);
  }

  get canControlPlayers(): boolean {
    return this.isSuperAdmin || this._canControlPlayers;
  }

  set canControlPlayers(canControlPlayers: boolean) {
    this._canControlPlayers = canControlPlayers;
  }

  get canChangeVolume(): boolean {
    return this.isSuperAdmin || this._canControlPlayers || this._canChangeVolume;
  }

  set canChangeVolume(canChangeVolume: boolean) {
    this._canChangeVolume = canChangeVolume;
  }
}
