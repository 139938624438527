import { Component, Injector } from '@angular/core';

import { PlayerComponent } from '@shared/player/player/player.component';

// NOTE this is only binded to a state there is not a real player here we don't want to play music
// from this player
@Component({
  selector: 'am-web-list-player',
  templateUrl: './web-list-player.component.html',
  styleUrls: ['./web-list-player.component.scss'],
})
export class WebListPlayerComponent extends PlayerComponent {
  name = 'webListPlayer';

  constructor(protected injector: Injector) {
    super(injector);
  }
}
