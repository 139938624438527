import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AngularFireDatabase } from 'angularfire2/database';
import { parse } from 'id3-parser';
import { convertFileToBuffer } from 'id3-parser/lib/universal/helpers';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { Song } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { BitfTryCatch } from '@decorators';
import { PlaylistService, PlaylistItemService, QueueItemService } from '@core/services/api';

@Injectable({
  providedIn: 'root',
})
export class SongService extends ApiSuperService {
  constructor(
    protected http: HttpClient,
    protected fireDb: AngularFireDatabase,
    protected bitfErrorHandlerService: BitfErrorHandlerService,
    private playlistItemService: PlaylistItemService,
    private playlistService: PlaylistService,
    private queueItemService: QueueItemService
  ) {
    super('songs', http, mapSong, mapSongs);
  }

  create(song: Song) {
    if (song.songToUpload) {
      return this.createSongFile(song.songToUpload).pipe(
        switchMap((songUploaded: any) => {
          song.name = song.name || this.getFilename(song.songToUpload.name);
          // .replace(/(\+)/g, ' ');
          // song.name = song.name
          return super.create(song).pipe(
            catchError((error, obs) =>
              this.bitfErrorHandlerService.catchObs(error, obs, {
                className: 'SongService',
                functionName: 'create',
              })
            )
          );
        })
      );
    } else {
      return super.create(song).pipe(
        catchError((error, obs) =>
          this.bitfErrorHandlerService.catchObs(error, obs, {
            className: 'SongService',
            functionName: 'create',
          })
        )
      );
    }
  }

  delete(song) {
    // Delete the song and update the zones that had this song inside playlist or assigned to the queue
    if (song.fileName) {
      return this.deleteSongFile(song.fileName).pipe(
        catchError((error, obs) =>
          this.bitfErrorHandlerService.catchObs(error, obs, {
            className: 'SongService',
            functionName: 'deleteSongFile',
          })
        ),
        switchMap(() =>
          this.playlistItemService.find({
            where: { libraryItemId: song.libraryItemId },
            include: 'playlist',
          })
        ),
        map((playlistItems: any) => {
          const playlistsMap = new Map();
          playlistItems.forEach(playlistItem => {
            if (!playlistsMap.has(playlistItem.playlist.id)) {
              playlistsMap.set(playlistItem.playlist.id, playlistItem.playlist);
            }
          });
          setTimeout(() => {
            playlistsMap.forEach(playlist => {
              this.playlistService.refreshHashes(playlist);
            });
          }, 5000);
        }),
        switchMap(() =>
          this.queueItemService.find({
            where: { libraryItemId: song.libraryItemId },
          })
        ),
        map((queueItems: any) => {
          setTimeout(() => {
            queueItems.forEach(queueItem => {
              const fbRef = this.fireDb.object(`zones/${queueItem.zoneId}`);
              fbRef.update({
                fbHash: Date.now(),
                event: 'cms:queueHashChanged',
              });
            });
          }, 5000);
        }),
        switchMap(() => super.delete(song)),
        catchError((error, obs) =>
          this.bitfErrorHandlerService.catchObs(error, obs, {
            className: 'SongService',
            functionName: 'delete',
          })
        )
      );
    }
    return super.delete(song).pipe(
      catchError((error, obs) =>
        this.bitfErrorHandlerService.catchObs(error, obs, {
          className: 'SongService',
          functionName: 'delete',
        })
      )
    );
  }

  getUrl(song) {
    return super
      .fetch({
        method: 'get',
        path: song.uri,
      })
      .pipe(
        catchError((error, obs) =>
          this.bitfErrorHandlerService.catchObs(error, obs, {
            className: 'SongService',
            functionName: 'getUrl',
          })
        )
      );
  }

  @BitfTryCatch()
  createSongFile(songToUpload: File) {
    const endpoint = `${environment.apiUrl}accountsongs/${environment.awsS3AccountSongsBucket}/upload`;
    const formData: FormData = new FormData();
    // String(Date.now())
    formData.append('fileKey', songToUpload, songToUpload.name);

    // const headers = new HttpHeaders();
    // headers.set('Content-Length', String(songToUpload.size));
    // const req = new HttpRequest('POST', endpoint, formData, {
    //   reportProgress: true,
    //   headers
    // });
    // return this.http.request(req);

    return this.http.post(endpoint, formData).pipe(
      map((response: any) => ({
        name: response.result.files.fileKey[0].providerResponse.name,
      })),
      catchError((error, obs) =>
        this.bitfErrorHandlerService.catchObs(error, obs, {
          className: 'SongService',
          functionName: 'createSongFile',
        })
      )
    );
  }

  @BitfTryCatch()
  deleteSongFile(fileName) {
    fileName = this.getFilename(fileName); // .replace(/( )/g, '+');
    const endpoint = `${environment.apiUrl}accountsongs/${
      environment.awsS3AccountSongsBucket
    }/files/${fileName}`;
    return this.http.delete(endpoint);
  }

  @BitfTryCatch()
  getFilename(fileName) {
    if (fileName.includes('/')) {
      fileName = fileName.split('/');
      fileName = fileName[fileName.length - 1];
    }
    return fileName;
  }

  // ID3 management
  @BitfTryCatch()
  extractMeta(file) {
    return new Promise((success, error) => {
      convertFileToBuffer(file)
        .then(parse)
        .then(tag => {
          success(this.normaliseTags(tag));
        })
        .catch(err => error(null));
    });
  }

  normaliseTags(tags) {
    return {
      name: tags.title,
      artist: tags.artist || tags.band,
      album: tags.album,
      genre: tags.genre,
      year: tags.year,
      publisher: tags.publisher,
    };
  }
}

export function mapSong(song) {
  return new Song(song);
}
export function mapSongs(songs) {
  return songs.map(song => mapSong(song));
}
