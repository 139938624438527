import { APP_VERSION } from '@env/version';

export function bitfLoadAsset({
  fileUrl,
  id = fileUrl,
  addCacheBust = false,
  addBuildVersion = false,
}: {
  fileUrl: string;
  id: string;
  addCacheBust?: boolean;
  addBuildVersion?: boolean;
}): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      let assetElement;
      // Check if the asset is already loaded
      const alreadyLoadedAsset = document.getElementById(id);
      if (alreadyLoadedAsset) {
        resolve('File already loaded');
        return;
      }

      const fileExtension = fileUrl.split('.');

      if (addBuildVersion) {
        fileUrl += `?v=${APP_VERSION}`;
      } else if (addCacheBust) {
        fileUrl += `?v=${Date.now()}`;
      }

      switch (fileExtension[fileExtension.length - 1] as string) {
        case 'js':
          assetElement = document.createElement('script');
          assetElement.setAttribute('type', 'text/javascript');
          assetElement.setAttribute('src', fileUrl);
          assetElement.setAttribute('id', id);
          break;

        case 'css':
          assetElement = document.createElement('link');
          assetElement.setAttribute('rel', 'stylesheet');
          assetElement.setAttribute('type', 'text/css');
          assetElement.setAttribute('href', fileUrl);
          assetElement.setAttribute('id', id);
          break;

        default:
          reject('File could not be loaded');
          return;
      }
      assetElement.onload = () => {
        resolve();
      };
      assetElement.onerror = error => {
        reject(error);
      };
      document.getElementsByTagName('head')[0].appendChild(assetElement);
    } catch (error) {
      reject(error);
    }
  });
}

export function bitfRemoveAsset({ fileUrl, id = fileUrl }: { fileUrl: string; id: string }) {
  const loadedAsset = document.getElementById(id);
  if (loadedAsset) {
    loadedAsset.parentNode.removeChild(loadedAsset);
  }
}
