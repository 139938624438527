<mat-sidenav-container [hidden]="!appComponentReady">
  <mat-sidenav #sidenav>
    <ng-container #sidenavContent></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-container *ngIf="!isEmbedded && appComponentReady">
      <!-- [class.hidden]="!authService.appReady" -->
      <div class="d-flex flex-column page-container">
        <am-header></am-header>
        <div class="flex-grow-1 minw-0 pb-4">
          <router-outlet></router-outlet>
          <am-dock-uploader></am-dock-uploader>
        </div>
        <am-footer></am-footer>
      </div>
    </ng-container>

    <ng-container *ngIf="isEmbedded && appComponentReady">
      <router-outlet></router-outlet>
    </ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="style-loading"
  [hidden]="appComponentReady">
  <img src="/assets/graphics/ambimusic-logo-dark-theme.png"
    alt="Ambimusic"
    class="style-loading-logo">
</div>