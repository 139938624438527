export enum ERoleMode {
  READ_ONLY = 'READ_ONLY',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
}

export enum ERoles {
  MASTER = 'MASTER',
}

export enum ERoleActions {
  CAN_VIEW_LIBRARY = 'CAN_VIEW_LIBRARY',
  CAN_MANAGE_LIBRARY = 'CAN_MANAGE_LIBRARY',
  CAN_MANAGE_ZONES = 'CAN_MANAGE_ZONES',
  CAN_VIEW_BOXES = 'CAN_VIEW_BOXES',
  CAN_MANAGE_USERS = 'CAN_MANAGE_USERS',
  CAN_VIEW_USERS = 'CAN_VIEW_USERS',
  CAN_MANAGE_ZONE_SETTINGS = 'CAN_MANAGE_ZONE_SETTINGS',
  CAN_MANAGE_ACCOUNT_PROFILE = 'MANAGE_ACCOUNT_PROFILEs',
  CAN_CHANGE_VOLUME = 'CAN_MANAGE_VOLUME',
  CAN_CONTROL_PLAYERS = 'CAN_CONTROL_PLAYERS',
  VIEW_LOG = 'VIEW_LOG',
}
