import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { LibraryItem } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { StreamService, SongService, PlaylistService, ScheduleService } from '@core/services/api';

@Injectable({
  providedIn: 'root',
})
export class LibraryItemService extends ApiSuperService {
  constructor(
    protected http: HttpClient,
    private bitfErrorHandlerService: BitfErrorHandlerService,
    // NOTE: these services are used as this[service]
    private streamService: StreamService,
    private songService: SongService,
    private playlistService: PlaylistService,
    private scheduleService: ScheduleService
  ) {
    super('libraryitems', http, mapLibrary, mapLibraries);
  }

  createItem(item) {
    const service = ApiSuperService.fromModelToService(item.instanceOf);
    let newLibraryItem;
    return super.create(new LibraryItem({ type: item.instanceOf })).pipe(
      switchMap((libraryItem: any) => {
        item.libraryItemId = libraryItem.id;
        newLibraryItem = libraryItem;
        return this[service].create(item).pipe(
          catchError((error, obs) => {
            // The library item has failed to load, delete the libraryItem node
            super.delete(newLibraryItem).subscribe();
            return this.bitfErrorHandlerService.catchObs(error, obs, {
              className: 'LibraryItemService',
              functionName: 'createItem 1',
            });
          })
        );
      }),
      map((newItem: any) => {
        newLibraryItem[newItem.instanceOf.toLowerCase()] = newItem;
        return newLibraryItem;
      }),
      map(mapLibrary),
      catchError((error, obs) =>
        this.bitfErrorHandlerService.catchObs(error, obs, {
          className: 'LibraryItemService',
          functionName: 'createItem 2',
        })
      )
    );
  }

  delete(libraryItem) {
    const service = ApiSuperService.fromModelToService(libraryItem.item.instanceOf);
    // NOTE we shouldn't have foreign keys constraint here because related item is deleted
    // Explicitely
    return this[service].delete(libraryItem.item).pipe(switchMap(() => super.delete(libraryItem)));
  }
}

export function mapLibrary(library) {
  return new LibraryItem(library);
}
export function mapLibraries(users) {
  return users.map(library => mapLibrary(library));
}
