import { forkJoin } from 'rxjs';
/**
 * Connect and Disconnect methods / runMixin partial
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const connectDisconnectMixin = {
  connectDisconnectMixinLoaded: true,
  /**
   * This method is called automatically by the player engine when the connection is restored.
   * Can be called arbitrary if the player engine has been put in disconnected mode isConnected = false;
   * When this method is called a new state$.next() event is emitted with the state content for the current source
   * */
  connect() {
    if (this.isConnected) {
      return;
    } else if (this.isConnected === undefined) {
      this.isConnected = true;
      console.log('connected');
      return;
    } else {
      console.log('re-connected');
    }

    // NOTE if we are here is a reconnect, the box was offline before
    this.isReConnected = true;
    this.isConnected = true;
    this.fallbackQueueIndex = 0;
    // TODO Check if we are not receiving this event the box doesn't start
    this.firebase.database.ref(`zones/${this.zoneId}`).once('value', snapshot => {
      console.log(snapshot.val());
      const fbState = snapshot.val();

      forkJoin(this._loadZone(), this._loadQueue(), this._loadZoneSchedules()).subscribe(
        data => {
          Object.assign(this.zone, data[0]);
          this.zoneQueueItems = data[1];
          this.zoneSchedules = data[2];

          // If there is only one box attached to the zone don't update box indexes with fb indexes
          // but do the opposite keep box indexes
          // if (this.zone.box) {
          //   fbState.attachedBoxes = fbState.attachedBoxes || [];
          //   const otherBoxes = fbState.attachedBoxes.filter(
          //     boxId => +boxId !== +this.boxId
          //   );
          //   if (otherBoxes.length > 0) {
          //     this.queueIndex = fbState.queueIndex;
          //     this.playlistIndex = fbState.playlistIndex;
          //     // this._pushZone({ event: 'pe:playback:connect' });
          //   } else {
          //     // Push the update so the UI can update the current played item
          //     this._pushZone({ event: 'pe:state:connect' });
          //   }
          // } else {
          //   this._pushZone({ event: 'pe:state:connect' });
          // }
          if (this.zone.source) {
            // A fallback song is playing so we haven't to send a new item. wait for the next()
            this._pushZone({ event: 'pe:state:connect' });
          } else {
            // If there is not a source we'll never receive a next event, so we have to
            // call update zone to trigger a new playback
            this._updateZoneSource({ event: 'pe:playback:connect' }, true);
          }
        },
        error => {
          this.errorHandler.handle(error, {
            className: 'connectDisconnectMixin',
            functionName: 'connect',
          });
        }
      );
    });
  },

  /**
   * This method is called automatically by the player engine when the connection drop.
   * It can be called arbitrary if the player engine is in connected mode isConnected = true;
   * to retrieve the fallback content.
   * When this method is called a new state$.next() event is emitted with the fallback content for the current source
   * */
  disconnect() {
    try {
      if (this.isConnected === false) {
        return;
      } else if (this.isConnected === undefined) {
        console.log('discconnected');
      } else {
        console.log('disconnected after being connected');
      }

      this.isConnected = false;
      this.isReConnected = false;
      // If the current item is a stream search for a fallback
      // const currentQueueItem = this.queueItems[this.queueIndex];
      // Delay this operation because it looks like that the box player is not ready at the first call

      const timeoutHandler = setTimeout(() => {
        this._updateZoneSource(
          {
            event: 'pe:playback:disconnect',
          },
          true
        );
        // if (currentQueueItem.libraryItem.type === 'Stream') {
        //   // this.isBox && this._pushZone('pe:playback:disconnect');
        //   this._updateZoneSource({ event: 'pe:playback:disconnect' });
        // } else {
        //   // this.isBox && this._pushZone('pe:playback:disconnect');
        //   this._updateZoneSource({
        //     event: 'pe:playback:disconnect'
        //   });
        // }
        clearTimeout(timeoutHandler);
      }, 1000);
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'connectDisconnectMixin',
        functionName: 'disconnect',
      });
    }
  },
};
