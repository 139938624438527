<div *ngIf="player">
  <am-debug-player *ngIf="authService.canDebug"
    [playerEngine]="playerEngine"
    [player]="player"></am-debug-player>
</div>

<am-player-queue [zone]="zone"
  [playerEngine]="playerEngine"></am-player-queue>

<am-web-dock-player *ngIf="!zone.box"
  [zone]="zone"
  (playerEngineReady)="playerEngineReady($event)"
  class="fixed-bottom"
  #webPlayer>
</am-web-dock-player>

<am-box-dock-player *ngIf="zone.box"
  [zone]="zone"
  (playerEngineReady)="playerEngineReady($event)"
  class="fixed-bottom"
  #boxPlayer>
</am-box-dock-player>




<!-- <mat-card *ngIf="playerEngine?.queueItems.length">
  <mat-tab-group>
    <mat-tab label="Queue">
    </mat-tab>
  </mat-tab-group>
</mat-card> -->