<div class="d-flex align-items-center overflow-auto"
  #breadcrumbs
  id="breadcrumbs">
  <div *ngFor="let link of links; let last = last; let first = first; let index = index"
    class="mat-caption d-flex align-items-center">
    <a mat-button
      *ngIf="!last"
      (click)="breadcrumbClick(link, index)"
      [ngClass]="first ? 'pl-0 pr-1' : 'px-1'">
      {{ link.title }}
    </a>
    <mat-icon *ngIf="!last">chevron_right</mat-icon>
    <a mat-button
      [ngClass]="last && !first ? 'px-1' : 'pl-0 pr-1'"
      *ngIf="last"
      disableRipple>{{
      link.title
    }}</a>
  </div>
</div>