<div class="p-3 sidenav-menu d-flex flex-column h-100">
  <div>
    <button mat-button
      (click)="closeMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="d-flex flex-column flex-grow-1 mt-2 overflow-auto">
    <div class="flex-grow-1">
      <mat-nav-list>
        <ng-container *ngIf="authService.user">
          <mat-list-item routerLink="library"
            routerLinkActive="selected"
            (click)="closeMenu()">
            Library
          </mat-list-item>

          <mat-list-item routerLink="zones"
            routerLinkActive="selected"
            (click)="closeMenu()">
            Zones
          </mat-list-item>

          <mat-list-item routerLink="users"
            routerLinkActive="selected"
            (click)="closeMenu()"
            *ngIf="authService.canManageUsers">
            Users
          </mat-list-item>

          <mat-list-item routerLink="boxes"
            routerLinkActive="selected"
            (click)="closeMenu()">
            Boxes
          </mat-list-item>

          <mat-divider></mat-divider>
          <h3 mat-subheader>User</h3>

          <mat-list-item *bitfUiRoleManager="{action: ERoleActions.CAN_MANAGE_USERS, mode: ERoleMode.HIDDEN}"
            class="pl-2"
            routerLink="settings/profile"
            routerLinkActive="selected"
            (click)="closeMenu()">
            Profile
          </mat-list-item>
          <mat-list-item class="pl-2"
            routerLink="settings/general"
            routerLinkActive="selected"
            (click)="closeMenu()">
            Settings
          </mat-list-item>
          <mat-list-item class="pl-2"
            routerLink="account/logout"
            (click)="closeMenu()">
            Logout
          </mat-list-item>
        </ng-container>

        <mat-divider></mat-divider>
      </mat-nav-list>
    </div>

    <div>
      <div class="text-right">
        <div class="p-2"><a class="w-100 mat-caption"
            (click)="closeMenu()"
            target="_blank"
            href="https://ambimusic.com/">ambimusic.com</a></div>
        <div class="p-2">
          <a class="w-100 mat-caption"
            (click)="closeMenu()"
            target="_blank"
            href="https://ambimusic.com/privacy-policy">
            Privacy Policy
          </a>&nbsp;&&nbsp;<a class="w-100 mat-caption"
            (click)="closeMenu()"
            target="_blank"
            href="https://ambimusic.com/terms/">Terms</a>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="text-center mat-small my-2">
        v{{appVersion}} - {{appBuildAt | date}} {{environment}}
      </div>
    </div>
  </div>
</div>