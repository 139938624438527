import { Injectable } from '@angular/core';
import { ToastMessagesService } from '@services';
import { EBitfUiMessageType } from '@app/enums';

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  duration = 2500;
  constructor(private toastMessageService: ToastMessagesService) {}

  success(message = 'Done!') {
    this.toastMessageService.show({
      type: EBitfUiMessageType.SUCCESS,
      title: message,
      duration: this.duration,
    });
  }
  error(message = 'Error') {
    this.toastMessageService.show({
      type: EBitfUiMessageType.ERROR,
      title: message,
      duration: this.duration,
    });
  }
}
