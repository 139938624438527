import { Zone } from '@models';
import { BitfTryCatch } from '@decorators';

export class Location {
  instanceOf = 'Location';
  id: number;
  accountId: number;
  companyId: number;
  name: string;
  parent;
  children = [];

  constructor(data = {}) {
    Object.assign(this, data);
  }

  @BitfTryCatch()
  get zones() {
    return this.children.filter(el => el.instanceOf === 'Zone');
  }
  set zones(zones) {
    this.children = this.children.concat(zones.map(el => new Zone({ ...el, parent: this })));
  }

  get serialised() {
    // TODO and zones mapped and serialised
    return {
      id: this.id,
      companyId: this.companyId,
      accountId: this.accountId,
      name: this.name,
    };
  }
}
