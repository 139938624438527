import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'am-responsive-actions',
  templateUrl: './responsive-actions.component.html',
  styleUrls: ['./responsive-actions.component.scss'],
})
export class ResponsiveActionsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  openMenu(event) {
    event.stopPropagation();
  }
}
