import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
/**
 * Methods for get schedules infos
 * All of this methods are callable via playerEngineInstance.mehtod
 *
 * @mixin
 */
export const scheduleManagerMixin = {
  scheduleManagerMixinLoaded: true,

  _loadZoneSchedules() {
    const conditions = {
      where: { zoneId: this.zoneId },
      include: {
        libraryItem: [
          {
            schedule: {
              scheduleItems: {
                libraryItem: [
                  { playlist: { playlistItems: 'libraryItem' } },
                  {
                    stream: {
                      fallbackLibraryItem: {
                        playlist: { playlistItems: 'libraryItem' },
                      },
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    };

    return this.api.request({ method: 'get', path: 'zoneschedules', filter: conditions }).pipe(
      map(schedules => {
        schedules = schedules.data || schedules;
        // NOTE: order schedule by orderIndex, this should be done via API
        schedules.sort((a, b) => {
          if (a.libraryItem.schedule.orderIndex > b.libraryItem.schedule.orderIndex) {
            return 1;
          }
          return -1;
        });

        // NOTE: this should be done from a Schedule model
        schedules.forEach(zoneSchedule => {
          zoneSchedule.libraryItem.schedule.scheduleItems.forEach(scheduleItem => {
            if (scheduleItem.dailySchedule) {
              scheduleItem.dailySchedule = JSON.parse(scheduleItem.dailySchedule);
            }
            if (scheduleItem.weeklySchedule) {
              scheduleItem.weeklySchedule = JSON.parse(scheduleItem.weeklySchedule);
            }
          });
        });

        this._setScheduleCache(schedules);
        return schedules;
      }),
      catchError(() => {
        const cache = this._getScheduleCache();
        if (cache) {
          return of(cache.schedules);
        }
        throw `Schedule for zone ${this.zoneId} can't be fetched online and is not in the cache`;
      })
    );
  },

  _refreshZoneSchedules() {
    this._loadZoneSchedules().subscribe(
      zoneSchedules => {
        this.zoneSchedules = zoneSchedules;
        this._pushZone({ event: 'pe:state:schedulesChanged' });
      },
      error => {
        this.errorHandler.handle(error, {
          className: 'scheduleManagerMixin',
          functionName: '_refreshZoneSchedules',
        });
      }
    );
  },

  _setScheduleCache(schedules) {
    try {
      const cacheObj = {
        created: Date.now(),
        version: this.environment.storageVersion,
        schedules,
      };
      this.cacheEngine.setItem(`zone-${this.zoneId}-schedules`, JSON.stringify(cacheObj));
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'scheduleManagerMixin',
        functionName: '_setScheduleCache',
      });
    }
  },

  _getScheduleCache() {
    try {
      const cache = this.cacheEngine.getItem(`zone-${this.zoneId}-schedules`);
      return JSON.parse(cache) || undefined;
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'scheduleManagerMixin',
        functionName: '_getScheduleCache',
      });
    }
  },

  /**
   * WIP!!! Calculate if the queue has next or prev element availabble
   * @return {boolean} obj.prev - can enable prev button
   * @return {boolean} obj.next - can enable next button
   */
  canEnablePrevNext() {
    this.zone.canEnablePrevNext = true;
    // const queueLenght = this.queueItems.length;
    // if (queueLenght === 0) {
    //   this.zone.canEnablePrevNext = false;
    // } else if (queueLenght > 1) {
    //   this.zone.canEnablePrevNext = true;
    // } else if (queueLenght === 1) {
    //   const currentItem = this.queueItems[this.queueIndex].libraryItem;
    //   const playlistItems =
    //     currentItem.type === 'Playlist' && currentItem.playlist.playlistItems;
    //   this.zone.canEnablePrevNext = playlistItems && playlistItems.length > 1;
    // }
  },
};
