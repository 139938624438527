import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  Injector,
  ElementRef,
} from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { AngularFireDatabase } from 'angularfire2/database';
import { Subject } from 'rxjs';
// import { MatSliderChange } from '@angular/material/slider';
import { environment } from 'environments/environment';
import io from 'socket.io-client';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { Zone } from '@models';
import { ApiService } from '@core/services/api';
import { DialogsService, AuthService } from '@core/services';
import { PlayerEngine } from '@playerEngine/player-engine.class.js';
import { BitfTryCatch } from '@decorators';
// tslint:disable-next-line:max-line-length
import { PlayerInfoDialogComponent } from '@shared/player/player-info-dialog/player-info-dialog.component';
import { AudioPoolService } from '@app/core/services/audio-pool.service';
import { ERoleActions, ERoleMode } from '@enums';

@Component({
  selector: 'am-player',
  template: '',
  styleUrls: [],
})
export class PlayerComponent implements OnInit, OnDestroy {
  @Input()
  zone: Zone;
  @Output()
  playerEngineReady = new EventEmitter();
  public authService: AuthService;
  protected fireDb: AngularFireDatabase;
  protected bitfErrorHandlerService: BitfErrorHandlerService;
  protected apiService: ApiService;
  protected nativeElement;
  protected audioPoolService: AudioPoolService;

  stateSubscription;
  playerEngine: any;
  playerReady = false;
  dialogService: DialogsService;

  volumeChange$ = new Subject();
  track;
  playerType: string;
  playerReducers: any = {};

  ERoleActions = ERoleActions;
  ERoleMode = ERoleMode;

  constructor(protected injector: Injector) {
    this.dialogService = injector.get(DialogsService);
    this.authService = injector.get(AuthService);
    this.fireDb = injector.get(AngularFireDatabase);
    this.bitfErrorHandlerService = injector.get(BitfErrorHandlerService);
    this.apiService = injector.get(ApiService);
    this.nativeElement = injector.get(ElementRef).nativeElement;
    this.audioPoolService = injector.get(AudioPoolService);
    this.dialogService = injector.get(DialogsService);
  }

  @BitfTryCatch()
  ngOnInit() {
    this.initPlayerReducers();

    this.createPlayerEngine();

    this.subscribeToPlayerEngineState();

    this.runPlayerEngine();

    this.subscribeToUiVolumeChanges();

    // NOTE: sta roba che non aspetta niente di asincrono? non va messo nel then del runPlayerEngine?
    setTimeout(() => {
      this.playerEngineReady.emit(this.playerEngine);
    }, 0);

    this.resetTrack();
  }

  private initPlayerReducers() {
    this.playerReducers = {
      updateSource: state => {
        return this.updateSource(state).then(
          () => {
            this.updatePlaybackState();
            // FIXME controllare se lo possiamo eliminare
            // this.playerReducers.volume();
          },
          error => {
            this.bitfErrorHandlerService.handle(error, {
              className: 'PlayerComponent',
              functionName: 'stateSubscription',
            });
          }
        );
      },
      playback: () => this.updatePlaybackState(),
      volume: () => this.updateVolume(),
      forceNext: () => this.forceNext(),
      connected: () => this.connected(),
    };
  }

  private createPlayerEngine() {
    this.playerEngine = new PlayerEngine({
      isBox: false,
      firebase: {
        fireDb: this.fireDb,
        database: this.fireDb.database,
      },
      zoneId: this.zone.id,
      api: {
        request: this.apiService.queueItem.request.bind(this.apiService.queueItem),
        fetch: this.apiService.queueItem.fetch.bind(this.apiService.queueItem),
      },
      cacheEngine: localStorage,
      environment,
      errorHandler: this.bitfErrorHandlerService,
      playerEngineMode: this.zone.box ? 'remoteControl' : 'player',
      socketLib: io,
    });
  }

  private subscribeToPlayerEngineState() {
    this.stateSubscription = this.playerEngine.state$.subscribe((state: any) => {
      if (!this.playerReady) {
        this.playerReady = true;
        // const timeoutHandler = setTimeout(() => {
        //   clearTimeout(timeoutHandler);
        // }, 300);
      }

      if (!state.event.includes('pe:')) {
        return;
      }

      // NOTE we are extending the APP zone instance with the PE zone instance
      Object.assign(this.zone, state);
      // console.log(`Player NEW state event: ${state.event}`, state);

      if (
        state.event.includes('initPlayer') ||
        state.event.includes('next') ||
        state.event.includes('prev') ||
        state.event.includes('playItem') ||
        state.event.includes('queueChanged')
      ) {
        this.playerReducers.updateSource(state);
      }

      if (state.event.endsWith('play') || state.event.includes('stop')) {
        this.playerReducers.playback(state);
      }

      if (state.event.includes('forceNext')) {
        this.playerReducers.forceNext(state);
      }

      if (state.event.includes('volume')) {
        this.playerReducers.volume();
      }

      // NOTE: this event is fired from connect-disconnect.mixin when the zone is online or back online
      if (state.event.includes('pe:state:connect')) {
        console.log(state);
        this.playerReducers.connected();
      }

      if (state.event.includes('debug:goToFade')) {
        this.doGoToFade();
      }
    });
  }

  private runPlayerEngine() {
    this.playerEngine.run().then(
      () => {
        // console.log('playerEngine ready for zone', this.zone.id);
        // const allSources = this.playerEngine.getAllSources();
      },
      error => {
        console.log('error on running player engine', error);
      }
    );
  }

  private subscribeToUiVolumeChanges() {
    this.volumeChange$
      .pipe(
        debounceTime(50),
        map(volume => {
          // console.log(volume, 'fast');
          this.setVolume(volume);
          return volume;
        }),
        debounceTime(300)
      )
      .subscribe(volume => {
        // console.log(volume, 'long');
        this.setVolume(volume);
      });
  }

  // Update state based on firebase action
  @BitfTryCatch()
  updatePlaybackState() {
    if (!this.zone) {
      return;
    }
    if (this.zone.isPlaying) {
      this.hdPlay();
    } else {
      this.hdPause();
    }
  }

  @BitfTryCatch()
  updateVolume() {
    if (!this.zone) {
      return;
    }
    this.hdSetVolume(this.zone.volume);
  }
  // -----------------------------------

  @BitfTryCatch()
  volumeSliderChanged(change: any) {
    this.volumeChange$.next(change.value);
  }

  // NOTE Commands
  @BitfTryCatch()
  private doCommand(action: string, event?, params?) {
    if (event) {
      event.stopPropagation();
    }
    this.playerEngine[action](params);
  }

  play(event?) {
    this.doCommand('play', event);
  }

  pause(event) {
    this.doCommand('pause', event);
  }

  stop(event?) {
    this.doCommand('stop', event);
  }

  next(event?, data = { skipCrossFade: true }) {
    this.doCommand('next', event, data);
  }

  prefetchNext() {
    this.doCommand('prefetchNext');
  }

  prev(event, data = { skipCrossFade: true }) {
    this.doCommand('prev', event, data);
  }

  volumeUp(event) {
    this.doCommand('volumeUp', event);
  }

  volumeDown(event) {
    this.doCommand('volumeDown', event);
  }

  setVolume(vol) {
    this.doCommand('setVolume', undefined, vol);
  }

  toggleMute(event) {
    this.doCommand('toggleMute', event);
  }

  toggleRandom(event) {
    this.doCommand('toggleRandom', event);
  }

  @BitfTryCatch()
  resetTrack() {
    this.track = {
      // tslint:disable-next-line:quotemark
      currentTime: "00':00''",
      currentTimePercent: 0,
      // tslint:disable-next-line:quotemark
      duration: "00'.00''",
    };
  }

  hdSetVolume(val) {}
  hdPlay() {}
  hdPause() {}
  hdStop() {}
  updateSource(state): Promise<any> {
    return Promise.resolve();
  }
  forceNext() {}
  doGoToFade() {}
  connected() {}

  showBoxDetails() {
    console.log('show box details');
    const dialogRef = this.dialogService.dialog.open(PlayerInfoDialogComponent, {
      width: '80%',
      maxWidth: '600px',
      autoFocus: false,
      data: { zone: this.zone, playerEngine: this.playerEngine },
    });

    dialogRef.afterClosed().subscribe();
  }

  stopPropagation(event) {
    if (event) {
      event.stopPropagation();
    }
  }

  // NOTE: debug only
  goToFade() {}

  @BitfTryCatch()
  ngOnDestroy() {
    // console.log('destroyed player component');
    this.stateSubscription.unsubscribe();
    this.playerEngine.onDestroy();
  }
}
