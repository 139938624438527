<div *ngIf="!playerReady">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="playerReady && playerEngine && playerEngine.queueItems.length > 0">
  <!-- <div *ngIf="playerEngine.queueItems.length === 0">
    No queue for: {{zone.name}}
  </div> -->

  <mat-card class="border-top rounded-0 m-0">
    <div *ngIf="zone.source"
      class="d-flex flex-wrap">
      <!-- <img [src]="zone.state?.albumArt"> -->
      <!-- <img *ngIf="zone.source.data.imageUrl"
        class="albumart"
        [src]="zone.source.data.imageUrl" />
      <img *ngIf="!zone.source.data.imageUrl"
        class="albumart"
        src="https://www.allgigs.co.uk/images/object/artist/3439/Foo_Fighters-1-200-200-100-crop.png" /> -->
      <div class="track-info d-flex flex-column justify-content-center">

        <div *ngIf="zone.source?.metaData?.playlist"
          class="d-flex align-items-center">
          <i class="material-icons">list</i>
          <i *ngIf="zone.source.metaData.playlist.isShuffle"
            class="material-icons"
            matTooltip="Shuffle active">shuffle</i>
          {{ zone.source.metaData.playlist.name }}
        </div>

        <div class="d-flex align-items-center">
          <i *ngIf="zone.source.type === 'Stream'"
            class="material-icons">rss_feed</i>

          <i *ngIf="zone.source.type === 'Song'"
            class="material-icons">audiotrack</i>

          <span *ngIf="zone.source.data.name">{{ zone.source.data.name }}</span>

          <span *ngIf="zone.source.data.artist">&nbsp;-&nbsp;{{ zone.source.data.artist }}</span>
        </div>


        <div *ngIf="authService.canDebug">
          QueueIndex: {{ zone.queueIndex }} | PlaylistIndex: {{ zone.playlistIndex }}
        </div>
      </div>

      <div class="d-flex justify-space-between align-items-center mt-2 mt-md-0 player-actions-container"
        *ngIf="playerReady">
        <!-- [disabled]="!zone.canEnablePrevNext" -->
        <div>
          <ng-container
            *bitfUiRoleManager="{action: ERoleActions.CAN_CONTROL_PLAYERS, mode: ERoleMode.HIDDEN}">

            <button mat-mini-fab
              (click)="prev($event)">
              <mat-icon>skip_previous</mat-icon>
            </button>

            <button mat-mini-fab
              *ngIf="!zone.isPlaying"
              (click)="play($event)">
              <mat-icon>play_arrow</mat-icon>
            </button>

            <button mat-mini-fab
              *ngIf="zone.isPlaying"
              (click)="stop($event)">
              <mat-icon>stop</mat-icon>
            </button>

            <!-- [disabled]="!zone.canEnablePrevNext" -->
            <button mat-mini-fab
              (click)="next($event)">
              <mat-icon>skip_next</mat-icon>
            </button>

          </ng-container>
          <!-- <button mat-mini-fab color="{{(zone.isRandom) ? 'primary' :'accent'}}" (click)="toggleRandom()">
            <mat-icon>shuffle</mat-icon>
          </button> -->
        </div>

        <div *ngIf="playerType === 'web' && zone.source.type !== 'Stream'"
          class="track-time d-flex justify-space-between align-center">
          <div class="current-time">{{ track.currentTime }}</div>
          <mat-progress-bar color="accent"
            mode="determinate"
            [value]="track.currentTimePercent"></mat-progress-bar>
          <div class="duration">{{ track.duration }}</div>
        </div>

        <div class="ml-2 d-flex align-items-center flex-grow-1"
          *bitfUiRoleManager="{action: ERoleActions.CAN_CHANGE_VOLUME, mode: ERoleMode.HIDDEN}">
          <button mat-mini-fab
            color="accent"
            (click)="toggleMute()">
            <mat-icon>{{ zone.isMute ? 'volume_up' : 'volume_off' }}</mat-icon>
          </button>
          <mat-slider class="w-100"
            [max]="100"
            [min]="0"
            [step]="5"
            [thumbLabel]="false"
            [value]="zone.volume"
            (input)="volumeSliderChanged($event)"
            (click)="stopPropagation($event)">
          </mat-slider>
          <!-- <button mat-mini-fab (click)=" volumeDown() ">
            <mat-icon aria-label="a ">volume_down</mat-icon>
          </button>

          <div>
            vol: {{zone.volume}}
        </div>

        <button mat-mini-fab
          (click)="volumeUp() ">
          <mat-icon aria-label="a ">volume_up</mat-icon>
        </button> -->
        </div>
      </div>
    </div>
  </mat-card>
</div>