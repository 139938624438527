import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { ApiService } from '@core/services/api';
import { BitfTryCatch } from '@decorators';

@Component({
  selector: 'am-preview-player',
  templateUrl: './preview-player.component.html',
  styleUrls: ['./preview-player.component.scss'],
})
export class PreviewPlayerComponent implements OnInit, OnDestroy {
  @Input()
  item;
  el;
  audioEl;
  isPlaying = false;
  audioElEndedHandler;

  constructor(private apiService: ApiService) {
    this.audioElEndedHandler = this._audioElEndedHandler.bind(this);
  }

  ngOnInit() {}

  @BitfTryCatch()
  createAudioElement() {
    if (this.item) {
      this.audioEl = document.createElement('audio');
      this.audioEl.type = 'audio/mpeg';
      this.audioEl.volume = 0.8;
      this.audioEl.addEventListener('ended', this.audioElEndedHandler);
      if (this.item.instanceOf === 'Song') {
        this.apiService.song.getUrl(this.item).subscribe(songUrl => {
          this.audioEl.src = songUrl;
        });
      } else {
        this.audioEl.src = this.item.url;
      }
    }
  }

  @BitfTryCatch()
  destroyAudioElement() {
    if (this.audioEl) {
      this.audioEl.src = '';
      this.audioEl.removeEventListener('ended', this.audioElEndedHandler);
      this.audioEl = undefined;
    }
  }

  @BitfTryCatch()
  togglePreview() {
    if (this.isPlaying) {
      this.audioEl.pause();
      this.destroyAudioElement();
    } else {
      this.createAudioElement();
      this.audioEl.play();
    }
    this.isPlaying = !this.isPlaying;
  }

  @BitfTryCatch()
  _audioElEndedHandler() {
    this.audioEl.pause();
    this.destroyAudioElement();
    this.isPlaying = false;
  }

  @BitfTryCatch()
  ngOnDestroy() {
    this.destroyAudioElement();
  }
}
