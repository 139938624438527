import { Injectable, Injector } from '@angular/core';

import { BitfAppSessionService } from '@bitf/services/app-session/bitf-app-session.service';
import { BitfLogDnaSenderService } from '@bitf/services/logger/bitf-log-dna-sender.service';
import { NoSleepService } from '@services';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Store } from '@models';
import { EStoreActions } from '@enums';
declare const NoSleep: any;

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  enableNoSleep;
  constructor(
    private bitfLogDnaSenderService: BitfLogDnaSenderService,
    private breakpointObserver: BreakpointObserver,
    protected injector: Injector,
    private noSleepService: NoSleepService
  ) {
    super(injector);
    this.enableNoSleep = this._enableNoSleep.bind(this);
  }

  init() {
    super.init();
    this.initBreakpointListener();
    // NOTE: Prevent device to sleep
    // document.addEventListener('click', this.enableNoSleep, false);
    // NOTE: Prevent device to sleep
    const noSleep = new NoSleep();
    // document.addEventListener(
    //   'click',
    //   function enableNoSleep() {
    //     document.removeEventListener('click', enableNoSleep, false);
    //     noSleep.enable();
    //   },
    //   false
    // );
  }

  _enableNoSleep() {
    document.removeEventListener('click', this.enableNoSleep, false);
    this.noSleepService.enable();
  }

  initBreakpointListener() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(result => {
        if (result.matches) {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = true;
            store.activeBreakpoints.isMobile = false;
          }, EStoreActions.BREACKPOINT);
        } else {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = false;
            store.activeBreakpoints.isMobile = true;
          }, EStoreActions.BREACKPOINT);
        }
      });
  }

  initLogSender() {
    this.bitfLogDnaSenderService.init();
  }
}
