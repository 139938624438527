import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from 'angularfire2/database';

import { QueueItem } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { BitfTryCatch } from '@decorators';

@Injectable({
  providedIn: 'root',
})
export class QueueItemService extends ApiSuperService {
  constructor(protected http: HttpClient, protected fireDb: AngularFireDatabase) {
    super('queueitems', http, mapQueue, mapQueues);
  }

  @BitfTryCatch()
  create(item) {
    // TODO: this has to be done in bulk
    const fbRef = this.fireDb.object(`zones/${item.zoneId}`);
    fbRef.update({ fbHash: Date.now(), event: 'cms:queueHashChanged' });
    return super.create(item);
  }

  @BitfTryCatch()
  delete(item) {
    const fbRef = this.fireDb.object(`zones/${item.zoneId}`);
    fbRef.update({ fbHash: Date.now(), event: 'cms:queueHashChanged' });
    return super.delete(item);
  }
}

export function mapQueue(queue) {
  return new QueueItem(queue);
}
export function mapQueues(queues) {
  return queues.map(queue => mapQueue(queue));
}
