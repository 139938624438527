<mat-card [ngClass]="{ 'mat-elevation-z0': embedded, 'm-0': embedded, 'p-0': embedded }">
  <mat-tab-group>
    <mat-tab label="Personal Data"
      *ngIf="showPanels.includes('personalData')">
      <div *ngIf="user"
        class="p-3 container-fluid d-flex justify-content-center">
        <div class="col-24 col-sm-16 col-md-12">
          <form [formGroup]="detailsForm">
            <div class="row">
              <mat-form-field class="col-24 col-sm-12 col-md-8 my-2">
                <input matInput
                  placeholder="Name"
                  name="name"
                  formControlName="name"
                  required />
                <mat-error *ngIf="fieldName.invalid && fieldName.errors.required">
                  Name {{ fieldRequiredErrMsg }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-24 col-sm-12 col-md-8 my-2">
                <input matInput
                  placeholder="Surname"
                  name="surname"
                  formControlName="surname"
                  required />
                <mat-error *ngIf="fieldSurname.invalid && fieldSurname.errors.required">
                  Surname {{ fieldRequiredErrMsg }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-24 col-sm-12 col-md-8 my-2">
                <input matInput
                  placeholder="Company"
                  name="company"
                  formControlName="company"
                  required />
                <mat-error *ngIf="fieldCompany.invalid && fieldCompany.errors.required">
                  Company {{ fieldRequiredErrMsg }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-24 col-sm-12 col-md-8 my-2">
                <input matInput
                  placeholder="Vat number"
                  name="vatNumber"
                  formControlName="vatNumber" />
              </mat-form-field>
              <mat-form-field class="col-24 col-sm-12 col-md-8 my-2">
                <input matInput
                  placeholder="Address"
                  name="address"
                  formControlName="address"
                  required />
                <mat-error *ngIf="fieldAddress.invalid && fieldAddress.errors.required">
                  Address {{ fieldRequiredErrMsg }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-24 col-sm-12 col-md-8 my-2">
                <input matInput
                  placeholder="City"
                  name="city"
                  formControlName="city"
                  required />
                <mat-error *ngIf="fieldCity.invalid && fieldCity.errors.required">
                  City {{ fieldRequiredErrMsg }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-24 col-sm-12 col-md-8 my-2">
                <input matInput
                  placeholder="Postal Code"
                  name="postalCode"
                  formControlName="postalCode"
                  required />
                <mat-error *ngIf="fieldPostalCode.invalid && fieldPostalCode.errors.required">
                  Postal Code {{ fieldRequiredErrMsg }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-24 col-sm-12 col-md-8 my-2">
                <input matInput
                  placeholder="State"
                  name="state"
                  formControlName="state"
                  required />
                <mat-error *ngIf="fieldState.invalid && fieldState.errors.required">
                  State {{ fieldRequiredErrMsg }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="d-flex justify-content-end">
              <button mat-raised-button
                (click)="updateUser()"
                [disabled]="detailsForm.invalid"
                color="accent">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Change password"
      *ngIf="showPanels.includes('changePassword')">
      <form [formGroup]="passwordForm"
        class="p-3 container-fluid d-flex justify-content-center">
        <div class="col-24 col-sm-16 col-md-12">
          <div class="row">
            <mat-form-field class="col-24 col-sm-12">
              <input matInput
                placeholder="Old Password"
                name="oldPassword"
                formControlName="oldPassword"
                required />
              <mat-error *ngIf="fieldOldPassword.invalid && fieldOldPassword.errors.required">
                Old Password {{ fieldRequiredErrMsg }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-24 col-sm-12">
              <input matInput
                placeholder="New Password"
                name="newPassword"
                formControlName="newPassword"
                required />
              <mat-error *ngIf="fieldNewPassword.invalid && fieldNewPassword.errors.required">
                New Password {{ fieldRequiredErrMsg }}
              </mat-error>
            </mat-form-field>
          </div>

          <am-message-box [error]="errorChangePassword"></am-message-box>

          <div class="d-flex justify-content-end mt-3">
            <button mat-raised-button
              (click)="changePassword()"
              [disabled]="passwordForm.invalid"
              color="accent">
              Change password
            </button>
          </div>
        </div>
      </form>
    </mat-tab>

    <mat-tab label="Account"
      *ngIf="showPanels.includes('account')">
      <div class="p-3 container-fluid d-flex justify-content-center">
        <div class="col-24 col-sm-16 col-md-10">
          <am-message-box warning="if you delete your account you'll lose all your data!"></am-message-box>
          <div class="d-flex justify-content-end">
            <button mat-raised-button
              color="warn"
              (click)="confirmDeleteAccount()">
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>