import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'am-sortable-list',
  templateUrl: './sortable-list.component.html',
  styleUrls: ['./sortable-list.component.scss'],
})
export class SortableListComponent implements OnInit {
  @Input()
  items: any;
  @Output()
  changed = new EventEmitter();
  sortableOptions = {
    onUpdate: (event: any) => {
      this.listChanged();
    },
  };

  constructor() {}

  ngOnInit() {}

  listChanged() {
    // console.log(this.items);
    this.changed.emit(this.items);
  }
}
