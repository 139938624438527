import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';

import { BitfTryCatch } from '@decorators';

@Component({
  selector: 'am-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnChanges {
  @Input()
  links;
  @Output()
  clicked = new EventEmitter();

  @ViewChild('breadcrumbs', { static: true })
  private breadcrumbs: ElementRef;

  constructor() {}

  ngOnInit() {
    this.scrollToEnd();
  }

  ngOnChanges() {
    this.scrollToEnd();
  }

  @BitfTryCatch()
  breadcrumbClick(link, index) {
    // Push out the un needed breadcrumbs
    this.links.length = index + 1;
    this.clicked.emit({ ...link.data, index });
  }

  private scrollToEnd(): void {
    setTimeout(() => {
      const breadcrumbs = document.querySelector('#breadcrumbs');
      if (breadcrumbs) {
        breadcrumbs.scrollLeft += document.querySelector('#breadcrumbs').scrollWidth;
      }
    }, 600);
  }
}
