/**
 * Methods for manage box interactions
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const boxManagerMixin = {
  boxManagerMixinLoaded: true,

  restartBox() {
    this._addBoxAction('restartBox');
  },

  factoryResetBox() {
    this._addBoxAction('factoryResetBox');
  },

  updateBoxFirmware() {
    this._addBoxAction('updateBoxFirmware');
  },

  _addBoxAction(action) {
    let actionsRequired =
      this.zone.boxState && this.zone.boxState.actionsRequired;
    if (!actionsRequired) {
      actionsRequired = [action];
      this._updateBoxRef({ actionsRequired });
      // Wait for FB update and then _boxRefChangeHanler run
      setTimeout(() => {
        this._updateZone({ event: 'pe:boxStateChanged' }, true);
      }, 2000);
      return;
    }
    if (!actionsRequired.includes(action)) {
      this._updateBoxRef({ actionsRequired: [...actionsRequired, action] });
      // Wait for FB update and then _boxRefChangeHanler run
      setTimeout(() => {
        this._updateZone({ event: 'pe:boxStateChanged' }, true);
      }, 2000);
    }
  }
};
