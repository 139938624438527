import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'am-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent implements OnInit {
  @Input()
  message;
  @Input()
  messages;
  @Input()
  warning;
  @Input()
  warnings;
  @Input()
  error;
  @Input()
  errors;
  constructor() {}

  ngOnInit() {}
}
