<div class="library-header">
  <!-- <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field> -->
  <!-- <button mat-fab color="accent" (click)="openAddEditLibraryItemDialog()">
      <mat-icon aria-label="">add</mat-icon>
    </button> -->
</div>

<div class="mat-elevation-z2">
  <table mat-table
    class="dynamically-spaced"
    [dataSource]="dataSource"
    matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell
        *matHeaderCellDef>Name</th>
      <td mat-cell
        *matCellDef="let row">
        <div class="d-flex align-items-center">
          <mat-icon *ngIf="row.instanceOf === 'Stream' && !row.insideOf">rss_feed</mat-icon>
          <mat-icon *ngIf="row.insideOf === 'Playlist'"
            class="material-icons">list</mat-icon>
          <mat-icon *ngIf="row.instanceOf === 'Song' && !row.insideOf"
            class="material-icons">
            audiotrack
          </mat-icon>
          &nbsp;
          <span *ngIf="authService.canDebug">{{row.libraryItemId}}&nbsp;-&nbsp;</span>
          <span *ngIf="row.playlistName">{{row.playlistName}}&nbsp;-&nbsp;</span>
          <span *ngIf="row.name">{{ row.name }}</span>
          <span *ngIf="row.artist">&nbsp;-&nbsp;{{ row.artist }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell
        *matHeaderCellDef
        class="dynamic-width">Actions</th>
      <td mat-cell
        *matCellDef="let row; let rIndex = index"
        class="dynamic-width">
        <!-- NOTE this is not working with PE queue items -->
        <!-- <am-preview-player [item]="row"
          #previewPlayer>
          <button mat-icon-button
            *ngIf="!previewPlayer.isPlaying"
            (click)="previewPlayer.togglePreview()"
            matTooltip="Preview">
            <mat-icon>play_arrow</mat-icon>
          </button>

          <button mat-icon-button
            *ngIf="previewPlayer.isPlaying"
            (click)="previewPlayer.togglePreview()">
            <mat-icon>stop</mat-icon>
          </button>
        </am-preview-player> -->

        <button *bitfUiRoleManager="{action: ERoleActions.CAN_CONTROL_PLAYERS, mode: ERoleMode.HIDDEN}"
          mat-icon-button
          (click)="playItem(row)"
          matTooltip="Play item">
          <mat-icon>play_circle_filled</mat-icon>
        </button>

        <!-- NOTE: removing items from playlist will affect other players -->
        <!-- <button mat-icon-button color="warn" (click)="deleteItem(row)">
          <i class="material-icons">delete</i>
        </button> -->
      </td>
    </ng-container>

    <tr mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.selected]="row.selected"></tr>
  </table>

</div>