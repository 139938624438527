import { ScheduleItem } from '@models';

export class Schedule {
  instanceOf = 'Schedule';
  id: number;
  accountId: number;
  libraryItemId: number;
  name: string;
  orderIndex: number;
  scheduleItems: ScheduleItem[];

  constructor(data: any) {
    if (data.scheduleItems) {
      data.scheduleItems = data.scheduleItems.map(item => new ScheduleItem(item));
    }
    Object.assign(this, data);
  }

  get serialised() {
    return {
      id: this.id,
      accountId: this.accountId,
      libraryItemId: this.libraryItemId,
      name: this.name,
      orderIndex: this.orderIndex,
    };
  }
}
