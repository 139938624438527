import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSortableModule } from 'ngx-sortable';
import { SortablejsModule } from 'ngx-sortablejs';

// import { BREAKPOINTS, DEFAULT_BREAKPOINTS } from '@angular/flex-layout';
import { MATERIAL_MODULES, CDK_MODULES } from '@shared/module-config';

import {
  ConfirmDialogComponent,
  BreadcrumbsComponent,
  ZoneSettingsComponent,
  MessageBoxComponent,
  SortableListComponent,
  TreeComponent,
} from '@shared/common-utilities';
import { FieldValidatorComponent, ValidatorDirective } from '@shared/form-utilities';
import {
  BoxDockPlayerComponent,
  FullPlayerComponent,
  PlayerComponent,
  PlayerInfoDialogComponent,
  PlayerQueueComponent,
  PreviewPlayerComponent,
  WebDockPlayerComponent,
  WebListPlayerComponent,
  WebPlayerComponent,
} from '@shared/player';

import { UserProfileComponent } from '@modules/settings/user-profile/user-profile.component';
import { CartStepComponent } from '@modules/zones/buy-zones/cart-step/cart-step.component';
import { SubscriptionsComponent } from '@modules/shop/subscriptions/subscriptions.component';
import { ResponsiveActionsComponent } from './responsive-actions/responsive-actions.component';
import { SidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';

// tslint:disable-next-line:max-line-length
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { TextMaskModule } from 'angular2-text-mask';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPaginatorIntl } from './paginator/paginator';
import { DebugPlayerComponent } from './player/debug-player/debug-player.component';
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';

// NOTE custom breakpoints
// DEFAULT_BREAKPOINTS[0].mediaQuery = '(min-width: 0px) and (max-width: 300px)';
// DEFAULT_BREAKPOINTS[2].mediaQuery = '(max-width: 299px)';
// export const BreakPointsProvider = {
//   provide: BREAKPOINTS,
//   useValue: DEFAULT_BREAKPOINTS,
// };

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...MATERIAL_MODULES,
    ...CDK_MODULES,
    NgxSortableModule,
    SortablejsModule,
    RouterModule,
    TextMaskModule,
  ],
  providers: [BitfMatSidenavService, { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }],
  declarations: [
    ConfirmDialogComponent,
    BreadcrumbsComponent,
    FieldValidatorComponent,
    ValidatorDirective,
    ZoneSettingsComponent,
    MessageBoxComponent,
    BoxDockPlayerComponent,
    FullPlayerComponent,
    PlayerComponent,
    PlayerInfoDialogComponent,
    PlayerQueueComponent,
    PreviewPlayerComponent,
    WebDockPlayerComponent,
    WebListPlayerComponent,
    WebPlayerComponent,
    TreeComponent,
    SortableListComponent,
    UserProfileComponent,
    CartStepComponent,
    SubscriptionsComponent,
    ResponsiveActionsComponent,
    SidenavMenuComponent,
    DebugPlayerComponent,
    BitfUiRoleManagerDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...MATERIAL_MODULES,
    ...CDK_MODULES,
    NgxSortableModule,
    SortablejsModule,
    ConfirmDialogComponent,
    BreadcrumbsComponent,
    FieldValidatorComponent,
    ValidatorDirective,
    ZoneSettingsComponent,
    MessageBoxComponent,
    BoxDockPlayerComponent,
    FullPlayerComponent,
    PlayerComponent,
    PlayerInfoDialogComponent,
    PlayerQueueComponent,
    PreviewPlayerComponent,
    WebDockPlayerComponent,
    WebListPlayerComponent,
    WebPlayerComponent,
    TreeComponent,
    SortableListComponent,
    UserProfileComponent,
    CartStepComponent,
    SubscriptionsComponent,
    ResponsiveActionsComponent,
    SidenavMenuComponent,
    TextMaskModule,
    BitfUiRoleManagerDirective,
  ],
})
export class SharedModule {}
