<mat-toolbar *ngIf="isUploading || isUploadComplete"
  class="dock-updater justify-content-start">

  <div class="d-flex w-100 align-items-center">
    <ng-container *ngIf="isUploading">
      <div class="spinner col-auto pl-0">
        <mat-progress-spinner *ngIf="nOfFilesToUpload === 1"
          color="primary"
          mode="indeterminate"
          diameter="20"
          strokeWidth="3">
        </mat-progress-spinner>
        <mat-progress-spinner *ngIf="nOfFilesToUpload > 1"
          color="primary"
          mode="determinate"
          [value]="filesUploadedPercentage"
          diameter="20"
          strokeWidth="3">
        </mat-progress-spinner>
      </div>

      <div class="col-auto">({{ isUploadingFileNumber }}/{{ nOfFilesToUpload }})</div>

      <div class="col-auto">
        Uploading: {{ uploadingFileName }} {{ songTags.artist ? '| ' + songTags.artist : '' }}
        {{ songTags.album ? '| ' + songTags.album : '' }}
        {{ songTags.genre ? '| ' + songTags.genre : '' }}
        {{ songTags.year ? '| ' + songTags.year : '' }}
      </div>



      <div class="col-auto ml-auto pr-0">
        <a (click)="cancelUpload()"
          mat-button>Cancel upload</a>
      </div>
    </ng-container>

    <div class="col-auto"
      *ngIf="isUploadComplete">
      Upload complete, <a (click)="onRefreshLibrary()"
        mat-button>refresh library</a>
    </div>

    <div class="col-auto ml-auto"
      *ngIf="uploadFileErrorMessage">
      <strong>{{uploadFileErrorMessage}}</strong>
    </div>

    <div class="col-auto ml-auto pr-0"
      *ngIf="isUploadComplete">
      <button mat-icon-button
        (click)="resetUploader()">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>