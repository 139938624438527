import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
/**
 * Methods for get queue infos
 * All of this methods are callable via playerEngineInstance.mehtod
 *
 * @mixin
 */
export const queueManagerMixin = {
  queueManagerMixinLoaded: true,

  _loadQueue() {
    const conditions = {
      where: { zoneId: this.zoneId },
      include: {
        libraryItem: [
          { playlist: { playlistItems: 'libraryItem' } },
          {
            stream: {
              fallbackLibraryItem: {
                playlist: { playlistItems: 'libraryItem' },
              },
            },
          },
        ],
      },
    };

    // const conditions = {
    //   include: [
    //     {
    //       relation: 'libraryItem',
    //       scope: {
    //         where: { type: { neq: 'Schedule' } },
    //         include: [
    //           {
    //           relation: 'playlist',
    //           scope: {
    //             include: {
    //               relation: 'playlistItems',
    //               scope: {
    //                 include: {
    //                   relation: 'libraryItem',
    //                 }
    //               }
    //             }
    //           }
    //         },
    //         {
    //           relation: 'stream',
    //           scope: {
    //             include: {
    //               relation: 'fallbackLibraryItem',
    //               scope: {
    //                 include: {
    //                   relation: 'playlist',
    //                   scope: {
    //                     include: {
    //                       relation: 'playlistItems',
    //                       scope: {
    //                         include: {
    //                           relation: 'libraryItem',
    //                         }
    //                       }
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       ]
    //       }
    //     }
    //   ]
    // };
    return this.api.request({ method: 'get', path: 'queueitems', filter: conditions }).pipe(
      map(queue => {
        queue = queue.data || queue;
        this._setQueueCache(queue);

        return queue;
      }),
      catchError(error => {
        const cache = this._getQueueCache();
        if (cache) {
          return of(cache.queue);
        }
        throw `Queue for zone ${this.zoneId} can't be fetched online and is not in the cache`;
      })
    );
  },

  _refreshQueue() {
    this._loadQueue().subscribe(
      zoneQueueItems => {
        // reset the allSourcesCache cache prop, set by getAllSources method
        this._clearCaches();
        this.zoneQueueItems = zoneQueueItems;
        this.triggerSourceUpdateAfterCmsChangeEvent('queueChanged');
      },
      error => {
        this.errorHandler.handle(error, {
          className: 'queueManagerMixin',
          functionName: '_refreshQueue subscription',
        });
      }
    );
  },

  _setQueueCache(queue) {
    try {
      const cacheObj = {
        created: Date.now(),
        version: this.environment.storageVersion,
        queue,
      };
      this.cacheEngine.setItem(`zone-${this.zoneId}-queue`, JSON.stringify(cacheObj));
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'queueManagerMixin',
        functionName: '_setQueueCache',
      });
    }
  },

  _getQueueCache() {
    try {
      const cache = this.cacheEngine.getItem(`zone-${this.zoneId}-queue`);
      return JSON.parse(cache) || undefined;
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'queueManagerMixin',
        functionName: '_getQueueCache',
      });
    }
  },

  /**
   * WIP!!! Calculate if the queue has next or prev element availabble
   * @return {boolean} obj.prev - can enable prev button
   * @return {boolean} obj.next - can enable next button
   */
  canEnablePrevNext() {
    this.zone.canEnablePrevNext = true;
    // const queueLenght = this.queueItems.length;
    // if (queueLenght === 0) {
    //   this.zone.canEnablePrevNext = false;
    // } else if (queueLenght > 1) {
    //   this.zone.canEnablePrevNext = true;
    // } else if (queueLenght === 1) {
    //   const currentItem = this.queueItems[this.queueIndex].libraryItem;
    //   const playlistItems =
    //     currentItem.type === 'Playlist' && currentItem.playlist.playlistItems;
    //   this.zone.canEnablePrevNext = playlistItems && playlistItems.length > 1;
    // }
  },
};
