import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { SortablejsModule } from 'ngx-sortablejs';

import { CommonCoreModule } from '@common/core/common-core.module';

import { throwIfAlreadyLoaded, FIREBASE_MODULES } from '@core/module-config';
import { HeaderComponent } from '@core/header/header.component';
import { FooterComponent } from '@core/footer/footer.component';
import { DockUploaderComponent } from '@core/dock-uploader/dock-uploader.component';
import { NotFoundComponent } from '@core/not-found/not-found.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    ...FIREBASE_MODULES,
    SortablejsModule.forRoot({ animation: 150 }),
    CommonCoreModule,
  ],
  exports: [HeaderComponent, FooterComponent, DockUploaderComponent, NotFoundComponent],
  declarations: [HeaderComponent, FooterComponent, DockUploaderComponent, NotFoundComponent],
  // providers: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
