import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Transaction } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService extends ApiSuperService {
  constructor(protected http: HttpClient) {
    super('transactions', http, mapTransaction, mapTransactions);
  }
}

export function mapTransaction(item) {
  return new Transaction(item);
}
export function mapTransactions(items) {
  return items.map(item => mapTransaction(item));
}
