import { Time } from '@angular/common';

import { LibraryItem } from '@models';

export class ScheduleItem {
  instanceOf = 'ScheduleItem';
  id: number;
  accountId: number;
  libraryItemId: number;
  scheduleId: number;
  libraryItem: LibraryItem;
  schedulingMode: string;
  dailySchedule: ISchedule;
  weeklySchedule: ISchedule[];
  orderIndex: number;

  constructor(data?) {
    if (data.libraryItem) {
      data.libraryItem = new LibraryItem(data.libraryItem);
      data.libraryItemId = data.libraryItem.id;
    }
    if (data.dailySchedule) {
      data.dailySchedule = JSON.parse(data.dailySchedule);
      data.dailySchedule = this.setDefaultTime(data.dailySchedule);
    } else {
      // NOTE init with an empty schedule
      data.dailySchedule = { startTime: '00:00', endTime: '00:00', volume: 90 };
    }
    if (data.weeklySchedule) {
      data.weeklySchedule = JSON.parse(data.weeklySchedule);
      data.weeklySchedule.forEach((element, index) => {
        data.weeklySchedule[index] = this.setDefaultTime(element);
      });
    } else {
      data.weeklySchedule = [];
      for (let i = 0; i < 7; i++) {
        data.weeklySchedule[i] = { startTime: '00:00', endTime: '00:00', volume: 90 };
      }
    }
    Object.assign(this, data);
  }

  get item(): LibraryItem {
    if (this.libraryItem) {
      return this.libraryItem.item;
    }
  }

  get serialised() {
    return {
      id: this.id,
      accountId: this.accountId,
      libraryItemId: this.libraryItemId,
      scheduleId: this.scheduleId,
      schedulingMode: this.schedulingMode,
      dailySchedule: JSON.stringify(this.dailySchedule),
      weeklySchedule: JSON.stringify(this.weeklySchedule),
      orderIndex: this.orderIndex,
    };
  }

  private setDefaultTime(data: any): any {
    return {
      startTime: data.startTime ? data.startTime : '00:00',
      endTime: data.endTime ? data.endTime : '00:00',
      volume: 90,
    };
  }
}

export interface ISchedule {
  startTime: Time;
  endtime: Time;
  volume: number;
}
