import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

import { CONSTANTS } from '@app/constants';
import { ApiService } from '@core/services/api';
import { BitfTryCatch } from '@decorators';
import { playerEngineClasses } from '@playerEngine/player-engine.class.js';
import { PlayerComponent } from '@shared/player/player/player.component';
import { ERoleActions, ERoleMode } from '@enums';

const { AudioFader } = playerEngineClasses;

@Component({
  selector: 'am-web-player',
  templateUrl: './web-player.component.html',
  styleUrls: ['./web-player.component.scss'],
})
export class WebPlayerComponent extends PlayerComponent implements OnInit, OnDestroy {
  audioEls = [];
  nativeElement;
  audioElVolumeChangeHandler;
  audioElEndedHandler;
  audioElErrorHandler;
  audioElTimeUpdate;
  playerType = 'webPlayer';
  uri: string;
  url: string;
  crossFadeWatcherInterval;
  healtCheckPlayRetry = 0;
  healthCheckPlayNoAudioEl = 0;
  isHealthCheckRunning = false;

  ERoleActions = ERoleActions;
  ERoleMode = ERoleMode;

  protected apiService: ApiService;

  constructor(injector: Injector) {
    super(injector);
  }

  @BitfTryCatch()
  ngOnInit() {
    this.resetTrack();
    super.ngOnInit();
  }

  @BitfTryCatch()
  hdPlay() {
    const promises = this.audioEls.map(audioEl => {
      return audioEl.play().catch(err => {
        if (err.name === 'NotAllowedError') {
          const dialogRef = this.dialogService.dialog.open(CONSTANTS.okCancelDialogComponent, {
            width: '80%',
            maxWidth: '600px',
            autoFocus: true,
            hasBackdrop: false,
            data: {
              title: 'Info',
              message: 'Press play to resume playback',
              okText: 'OK',
            },
          });
          dialogRef.afterClosed().subscribe();
          throw err;
        }
        return Promise.resolve();
      });
    });
    Promise.all(promises)
      .then(() => {
        this.startTrackHealthCheck();
      })
      .catch(() => this.stop());
  }

  @BitfTryCatch()
  hdPause() {
    this.audioEls.forEach(audioEl => {
      audioEl.pause();
    });
  }

  @BitfTryCatch()
  hdStop() {
    this.audioEls.forEach(audioEl => {
      audioEl.stop();
    });
  }

  @BitfTryCatch()
  hdSetVolume(vol) {
    if (this.audioEls.length > 1) {
      return;
    }
    if (this.audioEls[0]) {
      this.audioEls[0].volume = vol / 100;
    }
  }

  @BitfTryCatch()
  updateSource(state) {
    const isAudioEnded = !this.audioEls.length || (this.audioEls.length === 1 && this.audioEls[0].ended);

    // In case the queue change and there is already an item playing we don't have to add the new item
    if (!this.zone.source || (state.event.includes('queueChanged') && !isAudioEnded)) {
      return Promise.resolve();
    }

    const { uri, url } = this.getUriUrl();
    this.setUriUrl({ uri, url });
    const trackName = this.zone.source.data.name;
    const urlPromise = uri
      ? this.apiService.song.getUrl(this.zone.source.data).toPromise()
      : Promise.resolve(url);

    return urlPromise.then((songUrl: string) => {
      if (songUrl) {
        const newAudioEl = this.createAudioEl(songUrl, {
          uri,
          songUrl,
          trackName,
        });
        this.addAudioElToArray(newAudioEl);
        this.onAudioEnter(newAudioEl);
      }
    });
  }

  private onAudioEnter(audioEl) {
    if (!this.zone.settings.crossFadeEnabled) {
      return;
    }

    let durationP = Promise.resolve();
    if (Number.isNaN(audioEl.duration)) {
      durationP = new Promise(resolve => {
        const onLoadedMetadata = () => {
          audioEl.removeEventListener('loadedmetadata', onLoadedMetadata);
          resolve();
        };
        audioEl.addEventListener('loadedmetadata', onLoadedMetadata);
      });
    }

    durationP.then(() => {
      if (
        audioEl.am.duration >= this.zone.settings.crossFadeDuration * 2 &&
        !this.playerEngine.zone.skipCrossFade
      ) {
        this.fadeVol('fadeIn', audioEl);
      } else {
        audioEl.volume = this.zone.volume / 100;
      }
    });
  }

  @BitfTryCatch()
  forceNext() {
    const currentAudioEl: any = this.audioPoolService.audios.find(
      (audioEl: any) => audioEl.am && audioEl.am.sourceId === this.zone.source.data.id
    );
    if (currentAudioEl) {
      currentAudioEl.am.setFiniteDuration();
    }
  }

  // NOTE: this event is called when the zone back on line we've to check if the audio el is still palaying
  // = enough buffer, otherwise we've to re run the same source, more safe then next since could be
  // a stream and we don't want to skip the stream
  @BitfTryCatch()
  // NOTE: commented in favour of _audioElErrorHandler this is doing the same
  async connected() {
    // const isCurrentAudioElPlaying = await this.isCurrentAudioElPlaying();
    // if (isCurrentAudioElPlaying) {
    //   return;
    // }
    // this.triggerInitPlayer();
  }

  @BitfTryCatch()
  private getUriUrl(): { uri?; url? } {
    const uri = this.zone.source.data.uri;
    const url = this.zone.source.data.url;
    if (uri) {
      return { uri };
    }
    if (url) {
      return { url };
    }
    return {};
  }

  @BitfTryCatch()
  private setUriUrl({ uri, url }: { uri?; url? }) {
    if (uri) {
      this.uri = uri;
      this.url = undefined;
    } else if (url) {
      this.url = url;
      this.uri = undefined;
    }
  }

  // @BitfTryCatch()
  // private isNewSource({ uri, url }: { uri?; url? }) {
  //   let isOnlyOneElementInTheQueue = false;
  //   if (this.playerEngine.queueItems.length === 1) {
  //     const firstQueueItem = this.playerEngine.queueItems[0];
  //     if (
  //       firstQueueItem &&
  //       (firstQueueItem.libraryItem.type !== 'Playlist' ||
  //         firstQueueItem.libraryItem.playlist.playlistItems.length === 1)
  //     ) {
  //       isOnlyOneElementInTheQueue = true;
  //     }
  //   }
  //   const isAudioEnded = !this.audioEls.length || (this.audioEls.length === 1 && this.audioEls[0].ended);
  //   if (isAudioEnded || isOnlyOneElementInTheQueue) {
  //     return true;
  //   }
  //   if (uri) {
  //     return this.uri !== uri;
  //   } else if (url) {
  //     return this.url !== url;
  //   }
  // }

  @BitfTryCatch()
  private createAudioEl(src, debugInfo) {
    if (!src) {
      return;
    }

    const audioEl = this.audioPoolService.getAudio() as any;
    // const audioEl = document.createElement('audio') as any;
    if (this.authService.canDebug) {
      const audioElsContainer = document.getElementById('audioEls');
      audioElsContainer.appendChild(audioEl);
    }
    audioEl.setAttribute('controls', 'controls');
    Object.assign(audioEl, {
      src: src,
      volume: this.zone.settings.crossFadeEnabled ? 0 : this.zone.volume / 100,
    });

    // NOTE: create a AM namespace
    const crossFadeDuration = this.zone.settings.crossFadeEnabled ? this.zone.settings.crossFadeDuration : 0;
    audioEl.am = {
      debugInfo,
      audioFader: AudioFader.NullFader,
      sourceId: this.zone.source.data.id,
      finiteDuration: undefined,
      get duration(): number {
        return Number.isFinite(audioEl.duration)
          ? audioEl.duration
          : audioEl.am.finiteDuration || audioEl.duration;
      },
      setFiniteDuration() {
        audioEl.am.finiteDuration = audioEl.currentTime + crossFadeDuration;
      },
      get ended() {
        return Number.isFinite(audioEl.duration)
          ? audioEl.ended
          : audioEl.am.finiteDuration
            ? audioEl.currentTime > audioEl.am.finiteDuration
            : audioEl.ended;
      },
    };

    this.addEventListeners(audioEl);
    return audioEl;
  }

  @BitfTryCatch()
  private addAudioElToArray(audioEl) {
    if (this.playerEngine.zone.skipCrossFade) {
      this.destroyAllAudioEls();
      this.audioEls = [audioEl];
    } else {
      this.audioEls.push(audioEl);
    }
  }

  @BitfTryCatch()
  private destroyAudioEl(audioEl) {
    if (audioEl) {
      this.removeAudioEventListeners(audioEl);
      audioEl.pause();
      audioEl.am.audioFader.stop();
      audioEl.am = {
        eventListeners: [],
        audioFader: AudioFader.NullFader,
      };
      this.removeAudioElFromDom(audioEl);

      const index = this.audioEls.indexOf(audioEl);
      if (index >= 0) {
        this.audioEls.splice(index, 1);
      }
    }
  }

  @BitfTryCatch()
  private destroyAllAudioEls() {
    let audioEl = this.audioEls[0];
    while (audioEl) {
      this.destroyAudioEl(audioEl);
      audioEl = this.audioEls[0];
    }
  }

  @BitfTryCatch()
  private removeAudioElFromDom(audioEl) {
    if (audioEl && audioEl.parentElement) {
      audioEl.parentElement.removeChild(audioEl);
    }
  }

  // NOTE: Audio elements listerners
  @BitfTryCatch()
  private _audioElTimeUpdate(audioEl) {
    const { currentTime } = audioEl;
    const { duration } = audioEl.am;

    // NOTE: Update UI about track timing
    const audioElIndex = this.audioEls.indexOf(audioEl);
    // NOTE: read the last player wich is the current track
    if (audioElIndex === this.audioEls.length - 1) {
      if (duration && currentTime) {
        Object.assign(this.track, {
          duration: this.parseTime(duration),
          currentTime: this.parseTime(currentTime),
          currentTimePercent: Math.floor((currentTime / duration) * 100),
        });
      }
    }

    if (!this.zone.settings.crossFadeEnabled) {
      return;
    }

    audioEl.am.audioFader.tick();

    if (audioEl.am.ended && !Number.isFinite(audioEl.duration)) {
      this._audioElEndedHandler(audioEl);
    }

    const timeToEnd = duration - currentTime;
    // NOTE: with this checks every song > fadeLenght * 2 will do the cross fade
    // if the next song duration is < fadeLenght * 2 will not do the cross fade and the next song will
    // start at 100% volume to allow the short prev track to being played with no cross fade.
    // It's not a big deal since most of the songs have a fade in already
    if (
      timeToEnd <= this.zone.settings.crossFadeDuration &&
      audioEl.am.duration > this.zone.settings.crossFadeDuration * 2 &&
      !audioEl.am.hasCalledNext
    ) {
      audioEl.am.hasCalledNext = true;

      this.fadeVol('fadeOut', audioEl);

      super.next(null, { skipCrossFade: false });
    }
  }

  @BitfTryCatch()
  private _audioElEndedHandler(audioEl) {
    // NOTE: if the crossfade is not enabled the hasCalledNext is false and this will run
    // if the crossfade is enabled it could be that the current song is shorter than
    // 2 * crossFadeDuration and the cross fade is skipped. In this case hasCalledNext will be still false.
    if (!audioEl.am.hasCalledNext) {
      super.next();
    }

    // Destroy himself
    this.destroyAudioEl(audioEl);
  }

  // @BitfTryCatch()
  // _audioElVolumeChangeHandler() {
  //   this.setVolume(this.audioEl.volume * 100);
  // }
  @BitfTryCatch()
  private _audioElErrorHandler(err) {
    console.error('audio el error, reset it?', err);
    setTimeout(() => {
      this.triggerInitPlayer();
    }, 2000);
  }

  @BitfTryCatch()
  private fadeVol(operation: 'fadeIn' | 'fadeOut', audioEl: any) {
    if (audioEl.am.audioFader.operation === operation) {
      return;
    }
    audioEl.am.audioFader.stop();

    const audioFader = new AudioFader({
      audioEl,
      duration: this.zone.settings.crossFadeDuration,
      from: operation === 'fadeIn' ? 0 : 1,
      to: operation === 'fadeIn' ? 1 : 0,
      zoneVolume: () => this.zone.volume / 100,
      operation,
    });

    audioFader.start();
    audioEl.am.audioFader = audioFader;
  }

  @BitfTryCatch()
  private parseTime(time: number): string {
    if (time < 60) {
      return `00':${this.padNumber(time)}''`;
    }
    if (time < 3600) {
      const hours = Math.floor(time / 60);
      return `${this.padNumber(hours)}':${this.padNumber(time % 60)}''`;
    }
    const min = Math.floor(time % 3600);
    return `${this.padNumber(time / 3600)}:${this.padNumber(min / 60)}':${this.padNumber(min % 60)}''`;
  }

  @BitfTryCatch()
  private padNumber(number: number): string {
    const stringified = String(Math.floor(number));
    if (stringified.length === 2) {
      return stringified;
    }
    return `0${stringified}`;
  }

  @BitfTryCatch()
  private addEventListeners(audioEl, listeners?: string[]) {
    // audioEl.addEventListener(
    //   'volumechange',
    //   this.audioElVolumeChangeHandler
    // );
    audioEl.am.eventListeners = [];
    if (!listeners || listeners.includes('timeupdate')) {
      const eventListerner = this._audioElTimeUpdate.bind(this, audioEl);
      audioEl.am.eventListeners.push({ timeupdate: eventListerner });
      audioEl.addEventListener('timeupdate', eventListerner);
    }
    if (!listeners || listeners.includes('ended')) {
      const eventListerner = this._audioElEndedHandler.bind(this, audioEl);
      audioEl.am.eventListeners.push({ ended: eventListerner });
      audioEl.addEventListener('ended', eventListerner);
    }
    if (!listeners || listeners.includes('error')) {
      const eventListerner = this._audioElErrorHandler.bind(this, audioEl);
      audioEl.am.eventListeners.push({ error: eventListerner });
      audioEl.addEventListener('error', eventListerner);
    }
    if (!listeners || listeners.includes('stalled')) {
      const eventListerner = this._audioElErrorHandler.bind(this, audioEl);
      audioEl.am.eventListeners.push({ stalled: eventListerner });
      audioEl.addEventListener('stalled', eventListerner);
    }
  }

  @BitfTryCatch()
  private removeAudioEventListeners(audioEl) {
    audioEl.am.eventListeners.forEach(listener => {
      const [event, eventHandler] = Object.entries(listener)[0];
      audioEl.removeEventListener(event, eventHandler);
    });
  }

  private async startTrackHealthCheck(isLoopCall = false) {
    if (this.isHealthCheckRunning && !isLoopCall) {
      return;
    }
    this.isHealthCheckRunning = true;
    try {
      const audioEl = this.audioEls[0];
      if (!audioEl && this.zone.isPlaying) {
        // TODO do somehing in case we are in play and we don't have any player
        if (this.healthCheckPlayNoAudioEl > 2) {
          this.triggerInitPlayer();
          this.healthCheckPlayNoAudioEl = 0;
        }
        this.healthCheckPlayNoAudioEl++;
        setTimeout(() => {
          this.startTrackHealthCheck(true);
        }, 3000);
        return;
      }
      this.healthCheckPlayNoAudioEl = 0;

      const isCurrentAudioElPlaying = await this.isCurrentAudioElPlaying();
      if (isCurrentAudioElPlaying === false && this.zone.isPlaying) {
        if (this.healtCheckPlayRetry >= 2) {
          // The html player won't start again. try with a triggerInitPlayer
          this.triggerInitPlayer();
          this.healtCheckPlayRetry = 0;
        }
        this.hdPlay();
        this.healtCheckPlayRetry++;
      } else if (isCurrentAudioElPlaying && !this.zone.isPlaying) {
        this.hdPause();
      } else {
        this.healtCheckPlayRetry = 0;
      }
      // Flush a play in case the audioEl is not playing and the state is play
      this.startTrackHealthCheck(true);
    } catch (error) {
      this.startTrackHealthCheck(true);
    }
  }

  @BitfTryCatch()
  private isCurrentAudioElPlaying(): Promise<boolean> {
    const currentAudioEl: any = this.audioPoolService.audios.find(
      (audioEl: any) => audioEl.am && audioEl.am.sourceId === this.zone.source.data.id
    );
    if (!currentAudioEl) {
      return Promise.resolve(false);
    }

    const startTime = currentAudioEl.currentTime;
    return new Promise(cb => {
      setTimeout(() => {
        // NOTE: we've to read again the current audio el because could be that the audio track changed
        // is new and the track is not yet in play so currentAudioEl.paused === true
        const delayedCurrentAudioEl: any = this.audioPoolService.audios.find(
          (audioEl: any) => audioEl.am && audioEl.am.sourceId === this.zone.source.data.id
        );
        if (!delayedCurrentAudioEl) {
          cb(false);
          return;
        }
        if (!delayedCurrentAudioEl.am || delayedCurrentAudioEl.am.sourceId !== currentAudioEl.am.sourceId) {
          cb(undefined);
          return;
        }
        const endTime = currentAudioEl.currentTime;
        if (currentAudioEl.duration > 0 && startTime !== endTime && !currentAudioEl.paused) {
          cb(true);
          return;
        }
        cb(false);
      }, 2000);
    });
  }

  @BitfTryCatch()
  private triggerInitPlayer() {
    this.destroyAllAudioEls();
    this.playerEngine._pushZone({ event: 'pe:initPlayer' });
  }

  // NOTE: debug only
  doGoToFade() {
    if (this.audioEls[0]) {
      this.audioEls[0].am.setFiniteDuration();
      const duration = this.audioEls[0].am.duration;
      this.audioEls[0].currentTime = duration - this.zone.settings.crossFadeDuration;
      this.audioEls[0].am.audioFader.stop();
      if (this.audioEls[1]) {
        this.audioEls[1].am.audioFader.stop();
      }
    }
  }

  @BitfTryCatch()
  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroyAllAudioEls();
    clearInterval(this.crossFadeWatcherInterval);
  }
}
