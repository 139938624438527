export const environment = {
  name: 'production',
  appName: 'Ambimusic - FE',
  storageVersion: '1.0.0',
  production: true,
  hmr: false,
  apiUrl: 'https://am-api-production.herokuapp.com/api/v1/',
  pingUrl: 'https://s3-eu-west-1.amazonaws.com/am-pinger/pingme.json',
  fallbackPingUrl: 'https://cdn.volumio.org/pingme.json',
  apiUrlOK: 'https://api.ambimusic.com.com/api/v1/',
  awsS3CompanyLogosBucket: 'am-production-company-logos',
  awsS3CompanyLogosBucketZone: 's3-eu-west-1.amazonaws.com/',
  awsS3AccountSongsBucket: 'am-production-account-songs',
  awsS3AccountSongsBucketZone: 's3-eu-west-1.amazonaws.com/',
  redirectAfterLogin: '/zones',
  firebaseConfig: {
    apiKey: 'AIzaSyCBrxzC65FMuPTuvRzDKiq8qbog8O176Rc',
    authDomain: 'am-production-cdb30.firebaseapp.com',
    databaseURL: 'https://am-production-cdb30.firebaseio.com',
    projectId: 'am-production-cdb30',
    storageBucket: 'am-production-cdb30.appspot.com',
    messagingSenderId: '946587038608',
  },
  stripeToken: 'pk_live_Ba7pUr9DQccqbekAp7dDFZiv',
  mockApiUrl: 'http://localhost:3002/',
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    saveLogMessagesInterval: 30000,
    logUrl: 'https://logs.logdna.com/logs/ingest?apikey=bf8cf553dba4a1e882fa09491aaed2c7',
    sendLogs: true,
    sendRequestBody: true,
    sendQueryParams: true,
  },
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 6000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 0, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  registerServiceWorker: false,
};
