import { StripeProduct } from './stripe-product';

export class Product {
  instanceOf = 'Product';
  id: number;
  stripeProductId: number;
  visible: boolean;
  order: number;
  stripeProduct: StripeProduct;

  constructor(data = {}) {
    Object.assign(this, data);
    if (this.stripeProduct) {
      this.stripeProduct = new StripeProduct(this.stripeProduct);
    }
  }

  get serialised() {
    return {};
  }
}
