import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '@core/services/api';
import { environment } from '@env';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // !req.url.includes(`${environment.apiUrl}users`)) &&
    const isCreatingUser =
      req.url.match(new RegExp(`${environment.apiUrl}customers$`)) &&
      req.method === 'POST' &&
      !this.authService.user;
    const isCallingApi = req.url.includes(environment.apiUrl);
    const isOpenApi = req.url.includes('openapis');
    if (isOpenApi && !this.authService.isTokenValid()) {
      return next.handle(req);
    }
    if (isCallingApi && this.authService.authToken && !isCreatingUser) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', this.authService.authToken.id),
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
