<div *ngIf="tree.length">
  <ul *ngFor="let leaf of tree;">
    <li>
      <mat-checkbox [(ngModel)]="leaf.selected" (change)="checkBoxClicked(leaf)">
        {{leaf.name}}
      </mat-checkbox>
      <!-- <mat-checkbox *ngIf="true" [(ngModel)]="leaf.selected" (change)="checkBoxClicked(leaf)">
        {{leaf.name}}
      </mat-checkbox> -->
    </li>
    <div *ngIf="leaf?.children?.length">
      <am-tree [tree]="child" (treeChanged)="treeChanged.emit(tree)" *ngFor="let child of leaf.children"></am-tree>
    </div>
  </ul>
</div>

<div *ngIf="!tree.length && tree.name">
  <ul>
    <li>
      <mat-checkbox [(ngModel)]="tree.selected" (change)="checkBoxClicked(tree)">
        {{tree.name}}
      </mat-checkbox>
      <!-- <mat-checkbox [(ngModel)]="tree.selected" (change)="checkBoxClicked(tree)">
        {{tree.name}}
      </mat-checkbox> -->
    </li>
    <div *ngIf="tree?.children?.length">
      <am-tree [tree]="leaf" (treeChanged)="treeChanged.emit(tree)" *ngFor="let leaf of tree.children"></am-tree>
    </div>
  </ul>
</div>