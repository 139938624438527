/**
 * Methods for handle to playback actions
 * All of this methods are callable via playerEngineInstance.mehtod
 * Note: Firebase and DB are updated only when there is connection
 * @mixin
 */
export const playbackManagerMixin = {
  playbackManagerMixinLoaded: true,
  volumeIncDecValue: 10,
  /**
   * Update the zone, Firebase and DB with -> isPlaying: true
   */
  play() {
    this._updateZone({ isPlaying: true, event: 'pe:playback:play' });
  },

  /**
   * Update the zone, Firebase and DB with -> isPlaying: true, new source, queueIndex and playlistIndex
   */
  playItem(queueIndex = 0, playlistIndex = 0, zoneData = {}) {
    if (this.playerEngineMode === 'remoteControl') {
      this._updateZone(
        Object.assign(
          {
            event: 'pe:execute:playItem',
            eventData: [queueIndex, playlistIndex],
          },
          zoneData
        )
      );
      return;
    }
    this.queueIndex = queueIndex;
    this.playlistIndex = playlistIndex;
    this._purgeShuffleMap();

    this._updateZoneSource(
      Object.assign({ isPlaying: true, event: 'pe:playback:playItem' }, zoneData)
    );
  },

  /**
   * Update the zone, Firebase and DB with -> isPlaying: false
   */
  pause() {
    this._updateZone({ isPlaying: false, event: 'pe:playback:pause' });
  },

  /**
   * Update the zone, Firebase and DB with -> isPlaying: false
   */
  stop() {
    this._updateZone({ isPlaying: false, event: 'pe:playback:stop' });
  },

  /**
   * Update the zone, Firebase and DB with -> new source, queueIndex and playlistIndex
   */
  next(zoneData = {}) {
    try {
      if (this.playerEngineMode === 'remoteControl') {
        this._updateZone(Object.assign({ event: 'pe:execute:next' }, zoneData));
        return;
      }

      // Stream back on line after silence detected we don't have to to a next
      const isStreamResumed = this._resumeStream('pe:playback:next');
      // If the stream is resumed disable next
      if (isStreamResumed) {
        return;
      }

      const currentQueueItem = this.queueItems[this.queueIndex];
      if (
        !this.isReConnected ||
        (currentQueueItem && currentQueueItem.libraryItem.type !== 'Stream')
      ) {
        this._incrementIndexes();
      }

      this._updateZoneSource(
        Object.assign(
          {
            event: 'pe:playback:next',
          },
          zoneData
        )
      );
      this.isScheduleJustChanged = false;
      this.isReConnected = false;
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'playbackManagerMixin',
        functionName: 'next',
      });
    }
  },

  /**
   * Update the zone, Firebase and DB with -> new source, queueIndex and playlistIndex
   */
  prev(data = {}) {
    try {
      if (this.playerEngineMode === 'remoteControl') {
        this._updateZone(Object.assign({ event: 'pe:execute:prev' }, data));
        return;
      }
      this._decrementIndexes();
      this._updateZoneSource(Object.assign({ event: 'pe:playback:prev' }, data));
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'playbackManagerMixin',
        functionName: 'prev',
      });
    }
  },

  /**
   * Update the zone, Firebase and DB with -> currentVolume + 10
   */
  volumeUp() {
    this.setVolume(+this.zone.volume + this.volumeIncDecValue);
  },

  /**
   * Update the zone, Firebase and DB with -> currentVolume - 10
   */
  volumeDown() {
    this.setVolume(+this.zone.volume - this.volumeIncDecValue);
  },

  /**
   * Update the zone, Firebase and DB with -> volume: val
   * @param {number} vol - new volume value
   * @param {boolean} [isMuting] - is muting
   */
  setVolume(volume, isMuting = false) {
    try {
      // Normalise volume
      volume = +volume;
      if (isNaN(volume)) {
        return;
      }
      volume = Math.floor(volume);
      if (volume < 0) {
        volume = 0;
      } else if (volume > 100) {
        volume = 100;
      }
      const storObj = { volume, isMute: isMuting };
      if (!isMuting) {
        storObj.prevVolume = volume;
      } else {
        storObj.volume = 0;
      }
      Object.assign(storObj, { event: 'pe:volume:setVolume' });
      this._updateZone(storObj);
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'playbackManagerMixin',
        functionName: 'setVolume',
      });
    }
  },

  /**
   * Mute the zone {isMute: true, volume: 0}
   */
  mute() {
    this._updateZone({
      isMute: true,
      volume: 0,
      event: 'pe:volume:mute',
    });
  },

  /**
   * Un Mute the zone {isMute: false, volume: this.zone.prevVolume}
   */
  unMute() {
    this._updateZone({
      isMute: false,
      volume: this.zone.prevVolume,
      event: 'pe:volume:unMute',
    });
  },

  /**
   * Update the zone, Firebase and DB with -> isMute: !isMute
   */
  toggleMute() {
    if (this.zone.isMute) {
      this.unMute();
    } else {
      this.mute();
    }
  },
};
