export class Box {
  instanceOf = 'Box';
  id: number;
  accountId: number;
  customerId: number;
  zoneId: number | string;
  boxId: string;
  name: string;
  tunnel: number;
  status: string;
  token: string;
  requireToken: boolean;
  isWaitingForPairing: boolean;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  get serialised() {
    return {
      id: this.id,
      accountId: this.accountId,
      customerId: this.customerId,
      zoneId: this.zoneId,
      boxId: this.boxId,
      name: this.name,
      tunnel: this.tunnel,
      status: this.status,
      token: this.token,
      requireToken: this.requireToken,
      isWaitingForPairing: this.isWaitingForPairing,
    };
  }
}
