export class StripePlan {
  instanceOf = 'StripePlan';
  id: string;
  active: boolean;
  amount: number;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: any;
  nickname: string;
  product: string;
  tiers: [
    {
      amount: number;
      up_to: number;
    }
  ];
  tiers_mode: string;
  transform_usage: any;
  trial_period_days: number;
  usage_type: string;
  // Needed for cart purchase
  quantity: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  get serialised() {
    return {};
  }
}
