import { Stream, Song, Playlist, Schedule } from '@models';

export class LibraryItem {
  instanceOf = 'LibraryItem';
  id: number;
  accountId: number;
  type: string;
  name: string;
  stream?: Stream;
  song?: Song;
  playlist?: Playlist;
  isAmbimusicContent: boolean;

  constructor(data?) {
    if (data.stream) {
      data.stream = new Stream(data.stream);
    }
    if (data.song) {
      data.song = new Song(data.song);
    }
    if (data.playlist) {
      data.playlist = new Playlist(data.playlist);
    }
    if (data.schedule) {
      data.schedule = new Schedule(data.schedule);
    }
    Object.assign(this, data);
  }

  get item() {
    const itemType = this.type.toLowerCase();
    if (this[itemType]) {
      return this[itemType];
    }
  }

  get serialised() {
    return {
      id: this.id,
      accountId: this.accountId,
      type: this.type,
      name: this.name,
    };
  }
}
