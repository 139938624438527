<div class="row d-flex justify-content-between align-items-center">
  <div class="col-24">
    <div *ngIf="!playerReady"
      class="player-loader">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>

  <div class="col-24 col-md-12"
    *ngIf="playerReady">
    <div class="d-flex flex-column justify-content-center">
      <div class="d-flex align-items-center">
        <mat-icon *ngIf="zone.box"
          [matTooltip]="zone.box.name">router</mat-icon>
        <mat-icon *ngIf="!zone.box"
          matTooltip="Web player">wifi_tethering</mat-icon>
        &nbsp;
        <strong>{{ zone.name }}</strong>
      </div>

      <ng-container *ngIf="zone.source">
        <div *ngIf="zone.source.metaData?.playlist"
          class="d-flex align-items-center">
          <i class="material-icons">list</i>
          <i *ngIf="zone.source.metaData.playlist.isShuffle"
            class="material-icons"
            matTooltip="Shuffle active">shuffle</i>
          {{ zone.source.metaData.playlist.name }}
        </div>

        <div class="d-flex align-items-center">
          <i *ngIf="zone.source.type === 'Stream'"
            class="material-icons">rss_feed</i>

          <i *ngIf="zone.source.type === 'Song'"
            class="material-icons">audiotrack</i>

          <span *ngIf="zone.source.data.name">{{ zone.source.data.name }}</span>

          <span *ngIf="zone.source.data.artist">&nbsp;-&nbsp;{{ zone.source.data.artist }}</span>

          <span *ngIf="playerEngine?.queueItems?.length === 0"
            class="align-items-center">
            No queue for this zone
          </span>
        </div>
      </ng-container>


      <div *ngIf="authService.canDebug">
        &nbsp;|&nbsp;{{ zone?.queueIndex }} | {{ zone?.playlistIndex }}
      </div>
    </div>
  </div>

  <!-- PLAYER -->
  <div class="col-24 col-md d-flex align-items-center"
    *ngIf="playerReady">
    <div class="w-100 text-center text-md-right mt-3 mt-md-0">
      <ng-container *bitfUiRoleManager="{action: ERoleActions.CAN_CONTROL_PLAYERS, mode: ERoleMode.HIDDEN}">
        <button mat-mini-fab
          *ngIf="!zone.isPlaying"
          (click)="play($event)">
          <mat-icon>play_arrow</mat-icon>
        </button>

        <button mat-mini-fab
          *ngIf="zone.isPlaying"
          (click)="stop($event)">
          <mat-icon>stop</mat-icon>
        </button>

        <button mat-mini-fab
          (click)="next($event)">
          <mat-icon>skip_next</mat-icon>
        </button>
      </ng-container>

      <ng-container *bitfUiRoleManager="{action: ERoleActions.CAN_CHANGE_VOLUME, mode: ERoleMode.HIDDEN}">
        <button mat-mini-fab
          class="m-0"
          color="accent"
          (click)="toggleMute($event)">
          <mat-icon>{{ zone.isMute ? 'volume_up' : 'volume_off' }}</mat-icon>
        </button>
        <mat-slider [max]="100"
          [min]="0"
          [step]="5"
          [thumbLabel]="false"
          [value]="zone.volume"
          (click)="stopPropagation($event)"
          (input)="volumeSliderChanged($event)">
        </mat-slider>
      </ng-container>
    </div>
  </div>
</div>