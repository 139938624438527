import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map, switchMap } from 'rxjs/operators';

import { Location } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { ZoneService, mapZones } from '@core/services/api';
import { BitfTryCatch } from '@decorators';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends ApiSuperService {
  constructor(protected http: HttpClient, private zoneService: ZoneService) {
    super('locations', http, mapLocation, mapLocations);
  }

  findZones(location) {
    return this.http.get(`${environment.apiUrl}locations/${location.id}/zones`).pipe(map(mapZones));
  }
}

export function mapLocation(location) {
  return new Location(location);
}
export function mapLocations(locations) {
  return locations.map(location => mapLocation(location));
}
