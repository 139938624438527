import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from '@core/services/api';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { UserSettings } from '@models';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService extends ApiSuperService {
  constructor(protected http: HttpClient, protected authService: AuthService) {
    super('customersettings', http, mapUserSettings, mapUsersSettings);
  }
}

export function mapUserSettings(settings) {
  return new UserSettings(settings);
}
export function mapUsersSettings(settings) {
  return settings.map(s => mapUserSettings(s));
}
