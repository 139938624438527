import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiSuperService } from '@core/services/api/api-super.service';
import {
  CompanyService,
  LocationService,
  ZoneService,
  UserService,
  UserSettingsService,
  AuthService,
  LibraryItemService,
  StreamService,
  SongService,
  PlaylistService,
  PlaylistItemService,
  QueueItemService,
  BoxService,
  ScheduleService,
  ScheduleItemService,
  ZoneScheduleService,
  StripeProductService,
} from '@core/services/api';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends ApiSuperService {
  constructor(
    public http: HttpClient,
    public company: CompanyService,
    public location: LocationService,
    public zone: ZoneService,
    public user: UserService,
    public userSettings: UserSettingsService,
    public auth: AuthService,
    public libraryItem: LibraryItemService,
    public stream: StreamService,
    public song: SongService,
    public playlist: PlaylistService,
    public playlistItem: PlaylistItemService,
    public queueItem: QueueItemService,
    public box: BoxService,
    public schedule: ScheduleService,
    public scheduleItem: ScheduleItemService,
    public zoneSchedule: ZoneScheduleService,
    public stripeProduct: StripeProductService
  ) {
    super('api', http, x => x, x => x);
  }
}
