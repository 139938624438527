import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database';

import { ZoneService } from '@core/services/api';
import { NotifierService } from '@core/services';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { numberMask } from '@common/libs/bitforce/utils/bitf-form.utils';

@Component({
  selector: 'am-zone-settings',
  templateUrl: './zone-settings.component.html',
  styleUrls: ['./zone-settings.component.scss'],
})
export class ZoneSettingsComponent implements OnInit {
  @Input()
  zoneSettings;
  @Input()
  hasBox: boolean;
  @Input()
  showCancel = false;
  @Output()
  canceled = new EventEmitter();
  @Output()
  saved = new EventEmitter();
  timezones: Observable<any>;

  autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM');
  public timeMask = [/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/];
  public numberMask;

  constructor(
    private zoneService: ZoneService,
    private http: HttpClient,
    private notifierService: NotifierService,
    private fireDb: AngularFireDatabase
  ) {
    this.numberMask = numberMask({ thousandsSeparatorSymbol: '' });
    this.selectTimezoneComparator = this.selectTimezoneComparator.bind(this);
  }

  ngOnInit() {
    this.timezones = this.http.get('/assets/data/timezones.json');
  }

  save() {
    this.zoneService
      .request({
        method: 'patch',
        path: `zoneSettings/${this.zoneSettings.id}`,
        body: this.zoneSettings,
      })
      .subscribe(
        savedZoneSettings => {
          this.notifierService.success();
          const fbRef = this.fireDb.object(`zones/${this.zoneSettings.zoneId}`);
          fbRef.update({
            fbHash: Date.now(),
            event: 'cms:zoneSettingsHashChanged',
          });
          this.saved.emit(savedZoneSettings);
        },
        () => {
          this.notifierService.error();
        }
      );
  }

  slideToggleChage(change: MatSlideToggleChange) {
    this.zoneSettings[change.source.name] = change.checked;
  }

  selectTimezoneComparator(timezone) {
    return timezone === this.zoneSettings.timezone;
  }

  cancel() {
    this.canceled.emit();
  }
}
