import { NgModule, ErrorHandler } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BitfRetryInterceptor } from '@bitf/interceptors/bitf-retry-interceptor.service';
import { BitfApiUiMessagesInterceptor } from '@bitf/interceptors/bitf-api-ui-messages-interceptor.service';
import { BitfLanguageInterceptor } from '@bitf/interceptors/bitf-language-interceptor.service';
import { BitfApiErrorsInterceptor } from '@bitf/interceptors/bitf-api-errors-interceptor.service';
// tslint:disable-next-line:max-line-length
import { BitfApiEnvelopeMapperInterceptor } from '@bitf/interceptors/bitf-api-envelope-mapper-interceptor.service';

import { BitfErrorHandlerService } from '@bitf/services/error-handler/bitf-error-handler.service';

import { AuthInterceptor } from '@core/interceptors/auth-interceptor.service';
import { APP_VERSION } from '@env/version';

const EXPORTS_COMPONENTS = [];
const COMPONENTS = [];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${APP_VERSION}`);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    // this is a custom auth interceptor for loopback
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // We are not sending language to the server
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BitfLanguageInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiErrorsInterceptor,
      multi: true,
    },
    // APi responses are not formatted to contains ui messages
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BitfApiUiMessagesInterceptor,
    //   multi: true,
    // },
    // We are not using BitfApiService yet so this will not run
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BitfApiEnvelopeMapperInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfRetryInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: BitfErrorHandlerService },
  ],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  exports: [...EXPORTS_COMPONENTS],
})
// NOTE: not used yet
export class CommonCoreModule {}
