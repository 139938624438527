import { Injectable } from '@angular/core';

import { IBitfUiRoleManagerService } from '@interfaces';
import { ERoleActions } from '@enums';
import { AuthService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class UiRoleManagerService implements IBitfUiRoleManagerService {
  constructor(private authService: AuthService) {}

  canI(action): boolean {
    switch (action) {
      case ERoleActions.CAN_VIEW_LIBRARY:
        return this.authService.user.isSuperAdmin;
      case ERoleActions.CAN_MANAGE_LIBRARY:
        return this.authService.user.isSuperAdmin;
      case ERoleActions.CAN_MANAGE_ZONES:
        return this.authService.user.isSuperAdmin;
      case ERoleActions.CAN_VIEW_BOXES:
        return this.authService.user.isSuperAdmin;
      case ERoleActions.CAN_MANAGE_USERS:
        return this.authService.user.isSuperAdmin;
      case ERoleActions.CAN_VIEW_USERS:
        return this.authService.user.isSuperAdmin;
      case ERoleActions.CAN_MANAGE_ACCOUNT_PROFILE:
        return this.authService.user.isSuperAdmin;
      case ERoleActions.CAN_MANAGE_ZONE_SETTINGS:
        return this.authService.user.isSuperAdmin;
      case ERoleActions.CAN_CHANGE_VOLUME:
        return this.authService.user.canChangeVolume;
      case ERoleActions.CAN_CONTROL_PLAYERS:
        return this.authService.user.canControlPlayers;
      case ERoleActions.VIEW_LOG:
        return this.authService.user.canViewLogs;
    }
    return false;
  }
}
