<h2 *ngIf="listType === 'subscriptions'">Subscriptions</h2>

<div class="row">
  <div class="col-24">

    <form [formGroup]="filtersForm">
      <mat-form-field>
        <input matInput
          placeholder="Search by zone, company or location name"
          formControlName="query">
      </mat-form-field>
    </form>

    <div class="mat-elevation-z2">
      <table mat-table
        [dataSource]="dataSource"
        class="dynamically-spaced ">

        <ng-container matColumnDef="zoneName">
          <th mat-header-cell
            *matHeaderCellDef>Zone Name</th>
          <td mat-cell
            *matCellDef="let row">
            {{row.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="assignedTo">
          <th mat-header-cell
            *matHeaderCellDef>Assigned to</th>
          <td mat-cell
            *matCellDef="let row">
            <div *ngIf="row.company"> {{row.company.name}}</div>
            <div *ngIf="row.location">{{row.location.company.name}} / {{row.location.name}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="dates">
          <th mat-header-cell
            *matHeaderCellDef>Subscription dates</th>
          <td mat-cell
            *matCellDef="let row">
            <div *ngIf="row.isSubscriptionCanceled && !row.isExpired">
              <div>This subscription will terminate on: {{row.subscriptionEndAt | date}} </div>
            </div>
            <div *ngIf="!row.isSubscriptionCanceled">
              <div>Subscribed on:{{row.subscriptionStartAt | date}}, </div>
              <div>Next payment date: {{row.subscriptionEndAt | date}} </div>
              <div>Will renew every: {{row.durationInMonths}} months </div>
            </div>
            <div *ngIf="!row.isSubscriptionCanceled && row.isExpired">
              <div>The payments for this subscription reported error, contact us to solve this
                problem or cancel the subscription </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="isWithBox">
          <th mat-header-cell
            *matHeaderCellDef>Has box</th>
          <td mat-cell
            *matCellDef="let row">
            <span *ngIf="row.isWithBox">Yes</span>
            <span *ngIf="!row.isWithBox">No</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell
            *matHeaderCellDef>Status</th>
          <td mat-cell
            *matCellDef="let row"
            [matTooltip]="row.subscriptionId"
            [matTooltipShowDelay]="2000">
            <span *ngIf="row.isSubscriptionExpired && row.status !== 'DISABLED'">
              Subscription Expired, this zone will be disabled on {{row.disableTresholdDate |
              date}}
            </span>
            <span *ngIf="row.status === 'DISABLED'">
              Subscription Expired, the zone have been disabled, you can renew the subscription to
              enable the zone
              again
            </span>
            <div *ngIf="row.isSubscriptionCanceled">Subscription canceled</div>
            <span *ngIf="!row.isSubscriptionExpired  && row.status !== 'DISABLED' && !row.isSubscriptionCanceled">
              Active
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell
            *matHeaderCellDef
            class="dynamic-width"> Actions </th>
          <td mat-cell
            *matCellDef="let row"
            class="dynamic-width">
            <!-- <button mat-raised-button
              color="primary"
              *ngIf="row.isSubscriptionExpired"
              (click)="renewSubscription(row)">
              Renew
            </button> -->

            <!-- <button mat-icon-button
              matTooltip="Invoice"
              (click)="openInvoice(row)">
              <mat-icon>insert_drive_file</mat-icon>
            </button> -->
            <button mat-raised-button
              *ngIf="listType === 'notAssignedZones'"
              color="primary"
              (click)="addZone(row)">
              Select
            </button>

            <button mat-button
              *ngIf="!row.isSubscriptionCanceled && listType === 'subscriptions'"
              color="warn"
              (click)="cancelSubscription(row)">
              <mat-icon>delete</mat-icon> Cancel subscription
            </button>
          </td>
        </ng-container>

        <tr mat-header-row
          *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
          *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

      <mat-paginator [length]="dataSource?.dataCount"
        [pageSizeOptions]="[10, 30, 50]"></mat-paginator>

      <div *ngIf="dataSource?.dataCount === 0">
        Subscriptions not found
      </div>
    </div>

  </div>
</div>