import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from 'angularfire2/database';

import { ScheduleItem } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleItemService extends ApiSuperService {
  constructor(protected http: HttpClient, protected fireDb: AngularFireDatabase) {
    super('scheduleitems', http, mapScheduleItem, mapScheduleItems);
  }
}

export function mapScheduleItem(item) {
  return new ScheduleItem(item);
}

export function mapScheduleItems(items) {
  return items.map(item => mapScheduleItem(item));
}
