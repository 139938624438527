import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PlaylistItem } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';

@Injectable({
  providedIn: 'root',
})
export class PlaylistItemService extends ApiSuperService {
  constructor(protected http: HttpClient) {
    super('playlistitems', http, mapPlaylistItem, mapPlaylistItems);
  }
}

export function mapPlaylistItem(item) {
  return new PlaylistItem(item);
}
export function mapPlaylistItems(items) {
  return items.map(item => mapPlaylistItem(item));
}
