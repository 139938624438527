import { switchMap, map, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { connectDisconnectMixin } from './run-connect-disconnect.mixin.js';
import { watchersMixin } from './run-watchers.mixin.js';
/**
 * Run methods
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const runMixin = Object.assign(
  {
    runMixinLoaded: true,
    firstRun: true,

    /**
     * Run / start the player Engine
     * @return {Promise} - return a promise that is resolved when the zone and the queue have been fetched from the
     * network or from the cache. Reject on errors
     * @description Call this method after this.playerEngine.state$.subscribe()
     * You can call playerEngine.getAllSources(); when the promise is resolved
     * */
    run() {
      return new Promise((success, reject) => {
        forkJoin({
          zone: this._loadZone(),
          zoneSchedules: this._loadZoneSchedules(),
          zoneQueueItems: this._loadQueue(),
        }).subscribe(
          responses => {
            console.log(responses);
            this.zone = responses.zone;
            this.zoneSchedules = responses.zoneSchedules;
            this.zoneQueueItems = responses.zoneQueueItems;

            this._startBox();
            success();
          },
          () => {
            this.errorHandler.handle(error, {
              className: 'runMixin',
              functionName: 'run',
            });
            reject(error);
          }
        );
      });
    },

    _startBox() {
      try {
        // Init indexes with zone indexes (readed from DB or cache)
        // DB / Chache indexes takes precedence since could be that the box is offline so we can't rely on firebase
        this.queueIndex = this.zone.queueIndex;
        this.playlistIndex = this.zone.playlistIndex;

        // NOTE: this must be here and not in the _zoneRefChangeHanler() because if the box is offline
        // that function will not run
        this._startSchedulesRunner();

        // NOTE: Init watchers: functions to figure out if the browser or the box are online / offline
        if (this.isBox) {
          this._initBoxNetworkWatcher();
        } else {
          this._initFbNetworkWatcher();
        }

        // Init firebase zone ref
        this.firebase.zoneRef = this.firebase.fireDb.object(`zones/${this.zone.id}`);

        this.zoneStateSubscription = this.firebase.zoneRef
          .valueChanges()
          .subscribe(this._zoneRefChangeHanler.bind(this), error => {
            this.errorHandler.handle(error, {
              className: 'runMixin',
              functionName: '_zoneRefChangeHanler',
            });
          });

        // NOTE: the box has his own node in FB
        if (this.zone.box) {
          // Init box zone ref
          this.firebase.boxRef = this.firebase.fireDb.object(`boxes/${this.zone.box.boxId}`);
          this.zoneBoxSubscription = this.firebase.boxRef
            .valueChanges()
            .subscribe(this._boxRefChangeHanler.bind(this), error => {
              this.errorHandler.handle(error, {
                className: 'runMixin',
                functionName: '_boxRefChangeHanler',
              });
            });
        }

        // NOTE: force a state change to receive the first state change (browser gets first update
        // automatically but the box doesn't)
        setTimeout(() => {
          this._updateZoneSource({
            event: 'pe:playback:initPlayer',
          });
        }, 1000);

        // Avoid to stuck the player engine in case the firstRun evend is lost
        setTimeout(() => {
          this.isFirstStateReceived = true;
        }, 2000);
      } catch (error) {
        this.errorHandler.handle(error, {
          className: 'runMixin',
          functionName: '_startBox',
        });
        return error;
      }
    },

    _zoneRefChangeHanler(fbState) {
      try {
        if (this.isConnected === false) {
          return;
        }

        if (!fbState) {
          console.log('_zoneRefChangeHanler', fbState);
          this._createFbZoneNode();
          this.isFirstStateReceived = true;
        } else {
          // Skip all non pe:playback:initPlayer, wait for the first initPlayer event to start the box
          if (fbState.event === 'pe:playback:initPlayer' && fbState.fbHash !== this.fbHash) {
            return;
          } else if (fbState.event === 'pe:playback:initPlayer' && fbState.fbHash === this.fbHash) {
            this.isFirstStateReceived = true;
          } else if (fbState.fbHash && this.fbHash && fbState.fbHash === this.fbHash) {
            // NOTE: We are doing optimistic updates because we are calling _pushZone
            // from _updateZone / _updateZoneSource, this is because if the box or web player are offline
            // we need to have the player working
            // To avoid double _pushZone call (then double state$.next)
            // we are skipping here all the self events because the next is already called
            // we are processing events sent by other player engine (fbState.fbHash !== this.fbHash)
            return;
          }

          if (!this.isFirstStateReceived) {
            return;
          }

          // NOTE: update indexes
          this.queueIndex = fbState.queueIndex;
          this.playlistIndex = fbState.playlistIndex;

          // NOTE: Extend the zone with fbState to reflect remote shared state (the db state is readed only at
          // the box start up or on CMS changes)
          Object.assign(this.zone, fbState);
          console.log('_zoneRefChangeHanler', this.zone, fbState);

          // NOTE: update the cache with the data just received
          this._setZoneCache(this.zone);

          // NOTE: we'll reach this point also if the user is changing CMS props for the box he's playing in
          // another tab, since the CMS update will send a fbHash !== this.fbHash
          this._checkForCmsChanges(fbState);

          this._pushZone();
        }
        this.firstRun = false;
      } catch (error) {
        this.errorHandler.handle(error, {
          className: 'runMixin',
          functionName: '_zoneRefChangeHanler',
        });
      }
    },

    _boxRefChangeHanler(fbState) {
      if (this.isConnected === false) {
        return;
      }
      this._pushBox(fbState);
    },

    _createFbZoneNode() {
      try {
        // ???
        if (typeof this.zone.queueIndex === 'number') {
          this.queueIndex = this.zone.queueIndex;
        } else {
          this.queueIndex = 0;
        }
        if (typeof this.zone.playlistIndex === 'number') {
          this.playlistIndex = this.zone.playlistIndex;
        } else {
          this.playlistIndex = 0;
        }

        this._updateZoneSource(
          Object.assign({}, this.zone.fbState, {
            event: 'pe:state:createZoneNode',
          })
        );
      } catch (error) {
        this.errorHandler.handle(error, {
          className: 'runMixin',
          functionName: '_createFbZoneNode',
        });
      }
    },

    _checkForCmsChanges(fbState) {
      try {
        switch (fbState.event) {
          // triggered when the queue associated to the box change
          case 'cms:queueHashChanged':
            this._refreshQueue();
            break;
          // triggered when the fallback associated to the zone change (change the playlist or an item inside)
          case 'cms:zoneHashChanged':
            this._refreshZone();
            break;
          // triggered when the queue associated to the box change
          case 'cms:schedulesHashChanged':
            this._refreshZoneSchedules();
            break;
          // triggered when the zone settings changes, mostly used to update the cross fade
          case 'cms:zoneSettingsHashChanged':
            this._refreshZone({ isZoneSettingsChange: true });
            break;
        }
      } catch (error) {
        this.errorHandler.handle(error, {
          className: 'runMixin',
          functionName: '_checkForCmsChanges',
        });
      }
    },
  },
  connectDisconnectMixin,
  watchersMixin
);
