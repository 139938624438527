<div *ngIf="!isLoading">

  <div *ngIf="!isShowingPrices">

    <div class="row">
      <div class="col-24">
        <form [formGroup]="buyZonesForm"
          id="buyZonesForm">
          <div class="row align-items-center">
            <div class="col-auto">
              <mat-form-field>
                <input matInput
                  type="number"
                  min="1"
                  formControlName="quantity"
                  placeholder="Quantity">
              </mat-form-field>
            </div>
            <div class="col-auto">
              <mat-form-field>
                <mat-select placeholder="Subscription type"
                  formControlName="frequency"
                  (change)="getCartTotals()">
                  <mat-option *ngFor="let f of pricingModel.frequencies"
                    [value]="f">
                    {{f.label}} <span *ngIf="f.discount"> - discount {{f.discount}}%</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-auto"
              *ngIf="!isEmbedded">
              <mat-form-field>
                <input matInput
                  formControlName="couponCode"
                  placeholder="Coupon code"
                  #couponCodeInput>
              </mat-form-field>
            </div>
            <div class="col-auto"
              *ngIf="!isEmbedded">
              <button mat-stroked-button
                *ngIf="!this.couponToApply"
                color="primary"
                (click)="applyCoupon()">Apply coupon</button>
              <button mat-stroked-button
                *ngIf="this.couponToApply"
                color="primary"
                (click)="removeCoupon()">Remove</button>
            </div>
          </div>

          <div class="row">
            <div class="col-auto">
              <mat-checkbox #withBox
                formControlName="withBox">I want add Ambibox to zones</mat-checkbox>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="total-table">
      <div *ngIf="!isCalculatingTotal"
        class="row justify-content-center">
        <div class="col-20">
          <table *ngIf="cart.tiers && cart.tiers.length"
            class="w-100">
            <thead>
              <tr class="bold">
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cartItem of cart.cartSummary;">
                <td [innerHTML]="cartItem.item"></td>
                <td [innerHTML]="cartItem.price"></td>
              </tr>
              <!-- <tr *ngFor="let tier of cart.tiers; let i = index">
                <td>
                  <span>{{tier.tierQuantity}} zones at {{tier.tierPrice}} &euro;</span>
                </td>
                <td>{{tier.price | number:'1.2-2'}} &euro;</td>
              </tr>

              <tr *ngIf="cart.totalBoxes">
                <td>{{cart.quantity}} box enabled zones at {{pricingModel.tiers[0].box.price}}
                  &euro;</td>
                <td>{{cart.totalBoxes | number:'1.2-2'}} &euro;</td>
              </tr>

              <tr>
                <td>Sub total</td>
                <td>{{cart.total | number:'1.2-2'}} &euro;</td>
              </tr>

              <tr *ngIf="cart.discount">
                <td>{{cart.discount.value}} % discount for {{cart.discount.label}} subscription</td>
                <td>- {{cart.discount.amount | number:'1.2-2'}} &euro;</td>
              </tr>

              <tr *ngIf="cart.coupon">
                <td>
                  {{cart.coupon.value}}
                </td>
                <td>- {{cart.coupon.amount | number:'1.2-2'}} &euro;</td>
              </tr>

              <tr *ngIf="cart.totalDiscounted !== undefined">
                <td>Total discounted</td>
                <td>{{cart.totalDiscounted | number:'1.2-2'}} &euro;</td>
              </tr>


              <tr>
                <td>IVA {{pricingModel.vat }}%</td>
                <td>{{cart.vat | number:'1.2-2'}} &euro;</td>
              </tr>


              <tr class="bold">
                <td>Total billed every {{cart?.frequency.durationInMonths}} months </td>
                <td>{{cart.totalWithVat | number:'1.2-2'}} &euro;</td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>

      <div *ngIf="isCalculatingTotal">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

    </div>

  </div>

  <div *ngIf="isShowingPrices">

    <div class="row">
      <div class="col-24">
        <form [formGroup]="showPricesForm"
          class="w-100">
          <mat-form-field>
            <mat-select placeholder="Subscription type"
              formControlName="frequency">
              <mat-option *ngFor="let f of pricingModel.frequencies"
                [value]="f">
                {{f.label}} <span *ngIf="f.discount"> - discount {{f.discount}}%</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-24">
        <table *ngIf="showPricesForm.get('frequency').value">
          <thead>
            <tr>
              <th>Quantity</th>
              <th>Price / Each</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tier of pricingModel.tiers; let i = index">
              <td>
                <span>From {{tier.min}}</span>
                <span *ngIf="tier.max !== 999999"> To {{tier.max}} zones</span>
              </td>
              <td>{{getZonePirce(tier.zone.price)}} &euro;</td>
            </tr>

            <tr>
              <td> Each Ambibox plug-in </td>
              <td>{{getBoxPrice()}} &euro;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>

  <div class="row justify-content-between align-items-center my-2 border-top">
    <div class="col-auto">
      <button *ngIf="!isShowingPrices"
        mat-button
        color="primary"
        form="buyZonesForm"
        (click)="isShowingPrices = !isShowingPrices">Show prices</button>

      <button *ngIf="isShowingPrices"
        mat-button
        (click)="isShowingPrices = !isShowingPrices"
        color="primary"
        form="buyZonesForm">Hide prices</button>
    </div>

    <div class="col-auto">
      <div *ngIf="userPurchasedBoxes"
        class="text-right">NOTE: you have already bought {{userPurchasedBoxes}} boxes</div>
    </div>
  </div>
</div>

<div *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>