import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '@services';
import { NotifierService } from '@core/services';

@Component({
  selector: 'am-cart-step',
  templateUrl: './cart-step.component.html',
  styleUrls: ['./cart-step.component.scss'],
})
export class CartStepComponent implements OnInit {
  canceled = new EventEmitter();
  buyZonesForm: FormGroup;
  showPricesForm: FormGroup;
  pricingModel;
  cart: any;
  isShowingPrices = false;
  isReadyToPay = false;
  couponToApply;
  buyZonesFormValues;
  userPurchasedBoxes = 0;
  isLoading = true;
  isCalculatingTotal = false;
  @Input()
  isEmbedded = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private notifierService: NotifierService
  ) {}

  ngOnInit() {
    this.getPricingModel();
    if (!this.isEmbedded) {
      this.getUserPurchasedZonesCount();
    }
  }

  initForms() {
    this.buyZonesForm = this.formBuilder.group({
      quantity: [1, Validators.required],
      frequency: [this.pricingModel.frequencies[0], Validators.required],
      couponCode: [null],
      withBox: [false],
    });

    this.showPricesForm = this.formBuilder.group({
      frequency: [this.pricingModel.frequencies[0], Validators.required],
    });

    this.buyZonesForm.valueChanges.subscribe(this.getCartTotals.bind(this));
  }

  getCartTotals() {
    this.isReadyToPay = false;
    this.buyZonesFormValues = this.buyZonesForm.value;
    // console.log(this.buyZonesFormValues);
    const quantity = this.buyZonesFormValues.quantity;
    if (!quantity) {
      return;
    }
    const frequency = this.buyZonesFormValues.frequency;

    if (!this.buyZonesForm.valid) {
      this.resetCart();
      this.cart.frequency = frequency;
      this.isReadyToPay = false;
      return;
    }

    this.isCalculatingTotal = true;
    this.resetCart();
    this.cart.quantity = quantity;
    this.cart.frequency = frequency;

    this.apiService.stripeProduct
      .getCartTotals({
        quantity,
        frequencyIndex: this.pricingModel.frequencies.findIndex(f => f === frequency),
        withBox: this.buyZonesFormValues.withBox,
        couponToApply: this.couponToApply,
      })
      .subscribe(
        result => {
          console.log(result);
          Object.assign(this.cart, result);
          this.isReadyToPay = true;
          this.isCalculatingTotal = false;
        },
        () => {
          this.notifierService.error(`Error calculating cart totals`);
          this.isCalculatingTotal = false;
        }
      );
  }

  resetCart() {
    this.cart = {
      tiers: [],
      plans: [],
      total: 0,
      vat: 0,
      totalWithVat: 0,
      frequency: this.pricingModel.frequencies[0],
      quantity: 0,
    };
  }

  getZonePirce(price) {
    const selectedFrequency = this.showPricesForm.value.frequency;
    if (!selectedFrequency) {
      return;
    }
    return Math.round((price - (price / 100) * selectedFrequency.discount) * 100) / 100;
  }

  getBoxPrice() {
    const selectedFrequency = this.showPricesForm.value.frequency;
    if (!selectedFrequency) {
      return;
    }
    return (
      Math.round(
        (this.pricingModel.tiers[0].box.price -
          (this.pricingModel.tiers[0].box.price / 100) * selectedFrequency.discount) *
          100
      ) / 100
    );
  }

  applyCoupon() {
    const couponCode = this.buyZonesForm.value.couponCode;
    if (!couponCode) {
      return;
    }
    this.notifierService.success(`Validating coupon`);
    this.apiService.stripeProduct.getCoupon({ couponCode, quantity: this.cart.quantity }).subscribe(
      result => {
        this.notifierService.success(`Coupon valid!`);
        console.log(result);
        this.couponToApply = result;
        if (this.buyZonesFormValues) {
          this.getCartTotals();
        }
      },
      () => {
        this.notifierService.error(`Coupon not found or not valid`);
      }
    );
  }

  removeCoupon() {
    this.couponToApply = undefined;
    this.buyZonesForm.patchValue({ couponCode: '' });
    if (this.buyZonesFormValues) {
      this.getCartTotals();
    }
  }

  getUserPurchasedZonesCount() {
    this.apiService.zone
      .count({
        isSubscriptionCanceled: false,
        isExpired: false,
      })
      .subscribe(result => {
        this.userPurchasedBoxes = result.count;
        // this.isLoading = false;
      });
  }

  getPricingModel() {
    this.apiService.stripeProduct.getPricingModel().subscribe(
      result => {
        this.pricingModel = JSON.parse(result.pricingModel);
        console.log('pricingModel', this.pricingModel);
        this.initForms();
        this.resetCart();
        this.getCartTotals();

        this.isLoading = false;
      },
      () => {
        this.notifierService.error(`Error loading pricing model`);
      }
    );
  }
}
