import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthService, UserService } from '@core/services/api';
import { UiRoleManagerService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private uiRoleManagerService: UiRoleManagerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (state.url === '/account/login' || state.url === '/account/signup') {
      this.authService.appReady = true;
      return true;
    }
    if (this.authService.user) {
      // This will be used fo future permissions
      // return this.checkUserRoutePermission(state)
      return true;
    } else if (this.authService.authToken) {
      return this.userService.loginWithToken().pipe(
        catchError(error => {
          this.router.navigate(['/account/login']);
          return observableThrowError(error);
        }),
        finalize(() => {
          this.authService.appReady = true;
        })
      );
    } else {
      this.router.navigate(['/account/login']);
      return false;
    }
  }

  // checkUserRoutePermission(state) {
  //   if (!this.authService.canManageBoxes && state.url === '/boxes') {
  //     this.router.navigate(['/zones']);
  //     return false;
  //   }
  //   if (!this.authService.canManageLibrary && state.url === '/library') {
  //     this.router.navigate(['/zones']);
  //     return false;
  //   }
  //   if (!this.authService.canManageUsers && state.url === '/users') {
  //     this.router.navigate(['/zones']);
  //     return false;
  //   }
  //   return true;
  // }
}
