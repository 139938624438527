import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiSuperService } from '@core/services/api/api-super.service';
export class MatTableDataSource<T, Y extends ApiSuperService> implements DataSource<T> {
  private dataSubject = new BehaviorSubject<T[]>([]);
  data$ = this.dataSubject.asObservable();
  isLoading = true;
  dataCount = 0;
  service: Y;
  paginator: MatPaginator;
  sort: MatSort;
  buildFilterQuery;
  buildWhereCondition;
  private subscriptions: Subscription = new Subscription();

  constructor(config) {
    Object.assign(this, config);
    this.subscribeToPaginatorChanges();
  }

  fetchData(doNotCount = false) {
    this.isLoading = true;

    const filterQuery = {
      limit: this.paginator.pageSize,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      ...this.buildFilterQuery(),
    };
    this.service
      .find(filterQuery)
      .pipe(
        catchError(() => of([])),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(data => this.dataSubject.next(data));

    if (!doNotCount) {
      this.countDataItems();
    }
  }

  countDataItems() {
    const whereConditions = this.buildWhereCondition();
    this.service
      .count(whereConditions)
      .pipe(catchError(() => of(0)))
      .subscribe(data => {
        this.dataCount = data.count;
      });
  }

  subscribeToPaginatorChanges() {
    if (!this.paginator) {
      return;
    }
    const sub = this.paginator.page
      .pipe(
        tap(() => {
          this.fetchData(true);
        })
      )
      .subscribe();
    this.subscriptions.add(sub);
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.subscriptions.unsubscribe();
  }
}
