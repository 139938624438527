import { Component, OnInit } from '@angular/core';

// tslint:disable-next-line:max-line-length
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { EBitfCloseEventStatus } from '@app/enums';

import { AuthService } from '@app/core/services';
import { APP_VERSION, BUILD_DATE } from '@env/version';
import { environment } from '@env';
import { ERoleActions, ERoleMode } from '@enums';

@Component({
  selector: 'am-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
})
export class SidenavMenuComponent implements OnInit {
  appVersion = APP_VERSION;
  appBuildAt = BUILD_DATE;
  environment = '';
  ERoleActions = ERoleActions;
  ERoleMode = ERoleMode;

  constructor(public authService: AuthService, private mSidenavService: BitfMatSidenavService) {}

  ngOnInit() {
    this.environment = environment.name !== 'production-env' ? `- ${environment.name}` : '';
  }

  closeMenu() {
    this.mSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
  }
}
