import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AngularFireDatabase } from 'angularfire2/database';
import { forkJoin } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { Playlist } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { ScheduleService, ScheduleItemService } from '@core/services/api';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService extends ApiSuperService {
  constructor(
    protected http: HttpClient,
    protected fireDb: AngularFireDatabase,
    protected bitfErrorHandlerService: BitfErrorHandlerService,
    protected scheduleService: ScheduleService,
    protected scheduleItemService: ScheduleItemService
  ) {
    super('playlists', http, mapPlaylist, mapPlaylists);
  }

  update(playlist) {
    return super.update(playlist).pipe(
      tap(() => {
        this.refreshHashes(playlist);
      })
    );
  }

  addPlaylistItem(playlist, libraryItem) {
    const newPlaylistItem = {
      libraryItemId: libraryItem.id,
    };
    return this.http
      .post(`${environment.apiUrl}${this.name}/${playlist.id}/playlistitems`, newPlaylistItem)
      .pipe(
        map(item => {
          // Update zone queueHash for zones that are playing this playlist
          // Non blocking action
          this.refreshHashes(playlist);
          return item;
        })
      );
  }

  removePlaylistItem(playlist, playlistItem) {
    return this.http
      .delete(`${environment.apiUrl}${this.name}/${playlist.id}/playlistitems/${playlistItem.id}`)
      .pipe(
        map(item => {
          // Update zone queueHash for zones that are playing this playlist
          // Non blocking action
          this.refreshHashes(playlist);
          return item;
        })
      );
  }

  refreshHashes(playlist) {
    const zonesWithPlaylistInQueueSet = new Set();
    const zonesWithPlaylistInScheduleSet = new Set();
    // zones that have this playlist in the queue
    const playlistAssignedToZones = this.request({
      method: 'get',
      path: `queueItems`,
      filter: {
        where: { libraryItemId: playlist.libraryItemId },
        fields: { zoneId: true },
      },
    });

    // zones that have this playlist as zone fallback
    const zonesWithPlaylistAsStreamFallback = this.request({
      method: 'get',
      path: `streams`,
      filter: {
        where: { fallbackLibraryItemId: playlist.libraryItemId },
        fields: { libraryItemId: true },
      },
    }).pipe(
      switchMap((streams: any) => {
        const streamLibraryItemIds = streams.map(stream => stream.libraryItemId);
        return this.request({
          method: 'get',
          path: `queueItems`,
          filter: {
            where: { libraryItemId: { inq: streamLibraryItemIds } },
            fields: { zoneId: true },
          },
        });
      })
    );

    // zones that have this playlist as zone fallback
    const playlistAssignedToZonesFallback = this.request({
      method: 'get',
      path: `zones`,
      filter: {
        where: { fallbackLibraryItemId: playlist.libraryItemId },
        include: null,
        fields: { id: true },
      },
    });

    // zones that have this playlist as zone fallback
    const zonesWithPlaylistInSchedule = this.scheduleItemService.find({
      where: { libraryItemId: playlist.libraryItemId },
      include: { schedule: { libraryItem: 'zoneSchedule' } },
    });

    forkJoin([
      playlistAssignedToZones,
      zonesWithPlaylistAsStreamFallback,
      zonesWithPlaylistInSchedule,
      playlistAssignedToZonesFallback,
    ]).subscribe((data: any) => {
      try {
        // console.log(data);
        data[0].forEach(zone => {
          zonesWithPlaylistInQueueSet.add(zone.zoneId);
        });
        data[1].forEach(zone => {
          zonesWithPlaylistInQueueSet.add(zone.zoneId);
        });
        data[2].forEach(scheduleItem => {
          try {
            const zoneId = scheduleItem.schedule.libraryItem.zoneSchedule.zoneId;
            zonesWithPlaylistInScheduleSet.add(zoneId);
          } catch (error) {}
          // zonesWithPlaylistInQueueSet.add(zone.zoneId);
        });

        zonesWithPlaylistInQueueSet.forEach(zoneId => {
          const fbRef = this.fireDb.object(`zones/${zoneId}`);
          fbRef.update({
            fbHash: Date.now(),
            event: 'cms:queueHashChanged',
          });
        });

        setTimeout(() => {
          zonesWithPlaylistInScheduleSet.forEach(zoneId => {
            const fbRef = this.fireDb.object(`zones/${zoneId}`);
            fbRef.update({
              fbHash: Date.now(),
              event: 'cms:schedulesHashChanged',
            });
          });
        }, 4000);

        // console.log(zonesWithPlaylistInQueueSet);
        setTimeout(() => {
          data[3].forEach(zone => {
            const fbRef = this.fireDb.object(`zones/${zone.id}`);
            fbRef.update({ fbHash: Date.now(), event: 'cms:zoneHashChanged' });
          });
        }, 6000);
      } catch (error) {
        this.bitfErrorHandlerService.handle(error, {
          className: 'PlaylistService',
          functionName: 'refreshHashes',
        });
      }
    });
  }
}

export function mapPlaylist(playlist) {
  return new Playlist(playlist);
}
export function mapPlaylists(playlists) {
  return playlists.map(playlist => mapPlaylist(playlist));
}
