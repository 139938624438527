export class Stream {
  instanceOf = 'Stream';
  id: number;
  libraryItemId: number;
  fallbackLibraryItemId: number;
  name: string;
  url: string;
  imageUrl: string;
  isAmbimusicContent: boolean;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  get serialised() {
    return {
      id: this.id,
      libraryItemId: this.libraryItemId,
      fallbackLibraryItemId: this.fallbackLibraryItemId,
      name: this.name,
      url: this.url,
      imageUrl: this.imageUrl,
      isAmbimusicContent: this.isAmbimusicContent,
    };
  }
}
