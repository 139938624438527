/**
 * Utils Methods
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const utilsMixin = Object.assign(
  {
    utilsMixinLoaded: true,
  },
  {}
);
