import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { User } from '@models';
import { BitfTryCatch } from '@decorators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  _user: User;
  _authToken;
  appReady = false;
  user$ = new Subject();

  // ToDo
  authTokenMetaData = { token: '' };
  authState$ = new Subject<any>();
  // --------------

  constructor(private router: Router, protected bitfErrorHandlerService: BitfErrorHandlerService) {}

  get user() {
    return this._user;
  }

  set user(user) {
    this.user$.next(user);
    this._user = user;
  }

  @BitfTryCatch()
  get authToken() {
    if (this._authToken) {
      if (this.isTokenValid()) {
        return this._authToken;
      }
      this.authToken = undefined;
      this.router.navigate(['/account/login']);
      return undefined;
    } else {
      // Try to load it from the local storage
      const localStorageToken = JSON.parse(localStorage.getItem('authToken'));
      if (this.isTokenValid(localStorageToken)) {
        this._authToken = localStorageToken;
        return localStorageToken;
      }
      // NOTE this force to delete the token from localstorage
      this.authToken = undefined;
      this.router.navigate(['/account/login']);
      return undefined;
    }
  }

  set authToken(token) {
    this._authToken = token;
    if (!token) {
      localStorage.removeItem('authToken');
    } else {
      localStorage.setItem('authToken', JSON.stringify(token));
    }
  }

  @BitfTryCatch()
  isTokenValid(token = this._authToken) {
    if (!token) {
      return false;
    }
    const createdAt = new Date(token.created).getTime();
    const now = new Date().getTime();
    const timeLapesed = now - createdAt;
    if (timeLapesed / 1000 > token.ttl) {
      return false;
    }
    return true;
  }

  get canDebug() {
    return this.user && (this.user.email.includes('@box.com') || this.user.email.includes('@box.it'));
  }

  // ToDo
  renewToken() {
    return Promise.reject();
  }

  signOut() {}

  isTokenExpired() {
    return true;
  }
  // -----------
}

export interface ITokenObj {
  id: string;
  created: string;
  ttl: number;
}
