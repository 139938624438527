import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'am-debug-player',
  templateUrl: './debug-player.component.html',
  styleUrls: ['./debug-player.component.scss'],
})
export class DebugPlayerComponent implements OnInit {
  @Input()
  playerEngine;
  @Input()
  player;
  constructor() {}

  ngOnInit() {}

  goToFade() {
    this.playerEngine.goToFade();
  }

  getAllSources() {
    console.log(this.playerEngine.getAllSources());
  }
}
