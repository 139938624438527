import { sourceManagerParsersMixin } from './source-manager-parsers.mixin.js';
import { sourceManagerIndexesMixin } from './source-manager-indexes.mixin.js';
import { sourceManagerSchedulesMixin } from './source-manager-schedules.mixin.js';

/**
 * Methods for get sources infos
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const sourceManagerMixin = Object.assign(
  {
    sourceManagerMixinLoaded: true,
    /**
     * WIP!!! Return the current source data
     * @description The Box doesn't need to call this method
     * @example
     * for Songs
     * {
     *  "data": {
     *    "id": 6,
     *    "name": "03+KLAM.mp3",
     *    "type": "Song",
     *    "url": "https://am-tom-development-account-songs.s3.eu-west-1.amazonaws.com/03+KLAM.mp3"
     *  },
     *  "endTime": "tmp",
     *  "libraryItemId": 18,
     *  "startTime": "tmp",
     *  "type": "Song"
     * }
     * For Stream
     * {
     *  "data": {
     *    "id": 6,
     *    "imageUrl": "stream image url"
     *    "name": "Jazz",
     *    "type": "Stream",
     *    "url": "strream url"
     *  },
     *  "endTime": "tmp",
     *  "libraryItemId": 18,
     *  "startTime": "tmp",
     *  "type": "Stream"
     * }
     */
    getSource() {
      try {
        if (!this.queueItems || !this.queueItems.length) {
          return undefined;
        }

        if (!this.queueItems[this.queueIndex]) {
          // queue is changed index not available, reset indexes
          this.queueIndex = 0;
          this.playlistIndex = 0;
          return this.getSource();
        }

        const currentQueueItem = this.queueItems[this.queueIndex];
        if (currentQueueItem.libraryItem.type === 'Playlist') {
          const playlist = currentQueueItem.libraryItem.playlist;
          if (!playlist.playlistItems.length) {
            return this._getNextQueueItem();
          }
          // Reset for wrong playlistIndex
          if (!playlist.playlistItems[this.playlistIndex]) {
            this.playlistIndex = 0;
          }
          const currentPlaylistItem = playlist.playlistItems[this.playlistIndex];
          return this._parseLibraryItem({
            libraryItem: currentPlaylistItem.libraryItem,
            playlist,
          });
        } else {
          this.playlistIndex = 0;
          if (this.isConnected || currentQueueItem.libraryItem.type !== 'Stream') {
            return this._parseLibraryItem({
              libraryItem: currentQueueItem.libraryItem,
            });
          } else {
            return this._checkStreamFallback(currentQueueItem.libraryItem);
          }
        }
      } catch (error) {
        this.errorHandler.handle(error, {
          className: 'sourceManagerMixin',
          functionName: 'getSource',
        });
      }
    },

    _checkStreamFallback(libraryItem) {
      try {
        const streamFallbackPlaylistItems = this._getStreamFallbackPlaylistItems(libraryItem);
        if (streamFallbackPlaylistItems) {
          return this._getFallbackSource(streamFallbackPlaylistItems);
        }
        return this._getNextQueueItem();
      } catch (error) {
        this.errorHandler.handle(error, {
          className: 'sourceManagerMixin',
          functionName: '_checkStreamFallback',
        });
      }
    },

    _getFallbackSource(playlistItems) {
      try {
        if (!playlistItems || !playlistItems.length) {
          return undefined;
        }
        if (!playlistItems[this.fallbackQueueIndex]) {
          this.fallbackQueueIndex = 0;
          return this._getFallbackSource(playlistItems);
        }
        return this._parseLibraryItem({
          libraryItem: playlistItems[this.fallbackQueueIndex].libraryItem,
        });
      } catch (error) {
        this.errorHandler.handle(error, {
          className: 'sourceManagerMixin',
          functionName: '_getFallbackSource',
        });
      }
    },

    _getStreamFallbackPlaylistItems(libraryItem) {
      try {
        const streamFallbackLibraryItem = libraryItem.stream.fallbackLibraryItem;
        const zoneFallbackLibraryItem = this.zone.fallbackLibraryItem;
        if (streamFallbackLibraryItem) {
          return streamFallbackLibraryItem.playlist.playlistItems;
        } else if (zoneFallbackLibraryItem) {
          return zoneFallbackLibraryItem.playlist.playlistItems;
        } else {
          // NOTE no stream fallback or zone fallback
          return undefined;
        }
      } catch (error) {
        this.errorHandler.handle(error, {
          className: 'sourceManagerMixin',
          functionName: '_getStreamFallbackPlaylistItems',
        });
      }
    },

    /**
     * This is called for empty playlist or when the box is offline and there is not stream or zone
     * fallback.
     */
    // TODO: test me
    _getNextQueueItem() {
      if (this.queueItems.length > 1) {
        // Try to play next queueItem, since this is a playlist wothout elements
        if (!this._thereIsSomethingToPlayOffline()) {
          return undefined;
        }
        this.queueIndex++;
        this.playlistIndex = 0;
        this.fallbackQueueIndex = 0;
        return this.getSource();
      } else {
        return undefined;
      }
    },

    // TODO: test me
    _thereIsSomethingToPlayOffline() {
      return this.queueItems.some(queueItem => {
        const libraryItem = queueItem.libraryItem;
        if (libraryItem.type === 'Stream') {
          return this._getStreamFallbackPlaylistItems(libraryItem);
        }
        return true;
      });
    },

    /**
     * Return all sources that this zone can play, including the stream fallback and  the fallback zone playlist
     */
    getAllSources() {
      try {
        if (this.allSourcesCache) {
          return this.allSourcesCache;
        }
        const allSources = {
          queueItems: null,
          zoneFallbackPlaylist: null,
          zoneSchedules: null,
        };

        // Queue items
        const queueItems = [];
        this.zoneQueueItems.forEach((queueItem, queueIndex) => {
          queueItems.push(
            this._parseLibraryItem({
              libraryItem: queueItem.libraryItem,
              includeFallback: true,
              queueIndex,
            })
          );
        });

        // Zone fallback items
        const zoneFallbackPlaylist = [];
        if (this.zone.fallbackLibraryItem) {
          this.zone.fallbackLibraryItem.playlist.playlistItems.forEach((playlistItem, playlistIndex) => {
            zoneFallbackPlaylist.push(
              this._parseLibraryItem({
                libraryItem: playlistItem.libraryItem,
                includeFallback: true,
                playlistIndex,
              })
            );
          });
        }

        // Schedules items
        const zoneSchedules = [];
        if (this.zoneSchedules) {
          this.zoneSchedules.forEach(scheduleLibraryItem => {
            const schedule = scheduleLibraryItem.libraryItem.schedule;
            schedule.scheduleItems.forEach(scheduleItem => {
              const scheduling = {
                schedulingMode: scheduleItem.schedulingMode,
                dailySchedule: scheduleItem.dailySchedule,
                weeklySchedule: scheduleItem.weeklySchedule,
              };
              if (scheduling.dailySchedule && typeof scheduling.dailySchedule === 'string') {
                scheduling.dailySchedule = JSON.parse(scheduleItem.dailySchedule);
              }
              if (scheduling.weeklySchedule && typeof scheduling.weeklySchedule === 'string') {
                scheduling.weeklySchedule = JSON.parse(scheduleItem.weeklySchedule);
              }
              zoneSchedules.push(
                this._parseLibraryItem({
                  libraryItem: scheduleItem.libraryItem,
                  includeFallback: true,
                  scheduling,
                })
              );
            });
          });
        }
        if (queueItems.length) {
          allSources.queueItems = queueItems;
        }
        if (zoneFallbackPlaylist.length) {
          allSources.zoneFallbackPlaylist = zoneFallbackPlaylist;
        }
        if (zoneSchedules.length) {
          allSources.zoneSchedules = zoneSchedules;
        }
        this.allSourcesCache = allSources;

        return allSources;
      } catch (error) {
        this.errorHandler.handle(error, {
          className: 'sourceManagerMixin',
          functionName: '_getAllSourcesNoSchedule',
        });
      }
    },

    /**
     * This method will be called every time there is a CMS update that involve a change in a source that
     * can be played by the zone
     */
    triggerSourceUpdateAfterCmsChangeEvent(event) {
      // Note this needs to run to update the this.queueItems with the queue or the schedule if is active
      this._scheduleRunner();
      if (this.playerEngineMode === 'player') {
        // This is to trigger a start if the zone is empty or it removes the stream if is not that one that should play

        if (!this.zone.source) {
          this._updateZoneSource({ event: `pe:playback:${event}` });
          // , skipCrossFade: true
          return;
        }

        const newSource = this.getSource();
        // In this case we've to force the termination of the stream to have a crossfade if needed
        if (!newSource || newSource.libraryItemId !== this.zone.source.libraryItemId) {
          this.queueIndex--;
          this.playlistIndex = -1;
          this._updateZone(
            {
              event: 'pe:playback:forceNext',
            },
            true
          );
          return;
        }
        this._pushZone({ event: `pe:state:${event}` });
      } else {
        this._pushZone({ event: `pe:state:${event}` });
      }
    },
  },
  sourceManagerParsersMixin,
  sourceManagerIndexesMixin,
  sourceManagerSchedulesMixin
);
