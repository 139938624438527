const { of, timer } = require('rxjs');
const { catchError, delayWhen, switchMap } = require('rxjs/operators');

/**
 * Watcher  methods / runMixin partial
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const watchersMixin = {
  watchersMixinLoaded: true,

  _initFbNetworkWatcher() {
    setTimeout(() => {
      this.firebase.database.ref('.info/connected').on('value', connectedSnap => {
        const isConnected = connectedSnap.val();
        if (isConnected) {
          this.connect();
        } else {
          this.disconnect();
        }
      });
    }, 1000);
  },

  _initBoxNetworkWatcher() {
    this.environment.onlineCheckersHosts.forEach(host => {
      const socket = this.socketLib(host);
      socket.io.timeout(4000);
      socket.io.reconnectionDelay(10000);
      socket.io.reconnectionDelayMax(10000);
      socket.on('connect', () => {
        this._checkOnlineCheckersStatus();
      });
      socket.on('disconnect', () => {
        this._checkOnlineCheckersStatus();
      });
      socket.on('error', () => {
        this._checkOnlineCheckersStatus();
      });
      socket.on('connect_error', () => {
        this._checkOnlineCheckersStatus();
      });
      socket.on('connect_timeout', () => {
        this._checkOnlineCheckersStatus();
      });
      this.onlineCheckers.push(socket);
    });
  },

  _checkOnlineCheckersStatus() {
    const isConnected = this.onlineCheckers.some(socket => socket.connected);
    if (isConnected) {
      this.connect();
      this.isConnected = true;
    } else {
      if (!this.isDestroying) {
        this.disconnect();
        this.isConnected = false;
      }
    }
  },

  // TODO: this is deprecated
  _initBoxNetworkWatcher2() {
    // NOTE: this was too fast leading not starting audio
    this.isPinging = true;
    this._doBoxNetworkWatcher();
    this.boxNeworkWatcherIntervalHandler = setInterval(() => {
      if (!this.isPinging) {
        this.isPinging = true;
        this._doBoxNetworkWatcher();
      }
    }, this.pingInterval);
  },

  _doBoxNetworkWatcher() {
    let failCounter = 0;
    let startTime = Date.now();
    this.api
      .fetch({
        method: 'get',
        path: this.environment.pingUrl,
        timeout: this.pingTimeout,
      })
      .pipe(
        catchError(error => {
          const failedAfter = Date.now() - startTime;
          this.errorHandler.handle(error, {
            className: 'watchersMixin',
            functionName: '_doBoxNetworkWatcher',
            level: 'DEBUG',
            failedAfter,
          });
          return of('dummyVal').pipe(
            delayWhen(() => timer(300)),
            switchMap(() => {
              startTime = Date.now();
              return this.api.fetch({
                method: 'get',
                path: this.environment.fallbackPingUrl,
                timeout: this.fallbackPingTimeout,
              });
            })
          );
        })
      )
      .subscribe(
        ret => {
          if (this.isConnected === false) {
            console.log('re-connected');
          } else {
            // console.log('connected');
          }
          this.connect();
          this.isConnected = true;
          this.isPinging = false;
        },
        error => {
          const failedAfter = Date.now() - startTime;
          this.errorHandler.handle(error, {
            className: 'watchersMixin',
            functionName: '_doBoxNetworkWatcher',
            level: 'DEBUG',
            url: true,
            failedAfter,
          });
          if (this.isConnected) {
            console.log('disconnected after being connected');
          } else {
            // console.log('disconnected');
          }
          this.disconnect();
          this.isConnected = false;
          this.isPinging = false;
        }
      );
  },
};
