<div class="d-flex justify-content-end">
  <button mat-button
    [matMenuTriggerFor]="menu"
    (click)="openMenu($event)"
    class="d-block d-sm-none">
    <mat-icon>more_vert </mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container [ngTemplateOutlet]="content"
      [ngTemplateOutletContext]=""
      class="d-flex align-items-center"> </ng-container>
  </mat-menu>

  <div class="d-none d-sm-block">
    <ng-container [ngTemplateOutlet]="content"
      [ngTemplateOutletContext]=""> </ng-container>
  </div>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>