import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './apps/web/app.module';
import { environment } from './environments/environment';
// import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(module => {
    window['rootInjector'] = module.injector;

    // if (window['ngRef']) {
    //   window['ngRef'].destroy();
    // }
    // window['ngRef'] = ref;
  })
  .catch(err => console.error(err));

// create a platform
// const platform = platformBrowserDynamic();

// save reference to the global object
// window['rootInjector'] = platform.injector;

// boostrap application
// platform.bootstrapModule(AmModule).catch(err => console.error(err));

// platform.bootstrapModule(AppModule).then((module) => {
//   window['rootInjector'] = module.injector;
// });

// platform.bootstrapModule(AppModule).then((module) => {
//   let applicationRef = module.injector.get(ApplicationRef);
//   let rootComponentRef = applicationRef.components[0];
// });

// const bootstrap = () => platformBrowserDynamic().bootstrapModule(AmModule);
// if (environment.hmr) {
//   if (module['hot']) {
//     hmrBootstrap(module, bootstrap);
//   } else {
//     console.error('HMR is not enabled for webpack-dev-server!');
//     console.error('Are you using the --hmr flag for ng serve?');
//   }
// } else {
//   bootstrap();
// }

// import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AmModule } from './app/am.module';
// // import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';

// if (environment.production) {
//   enableProdMode();
// }

// // platformBrowserDynamic()
// //   .bootstrapModule(AppModule)
// //   .catch(err => console.error(err));

// platformBrowserDynamic().bootstrapModule(AmModule);
