import { Component, Injector } from '@angular/core';

import { PlayerComponent } from '@shared/player/player/player.component';

@Component({
  selector: 'am-box-dock-player',
  templateUrl: '../shared-views/dock-player.component.html',
  styleUrls: ['../shared-views/dock-player.component.scss'],
})
export class BoxDockPlayerComponent extends PlayerComponent {
  name = 'boxDockPlayer';

  constructor(protected injector: Injector) {
    super(injector);
  }

  openInfo() {
    this.showBoxDetails();
  }
}
