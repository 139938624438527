import { BitfStore } from '@bitf/core/models/bitf-store.model';
import { User, Company } from '@models';

// NOTE: default values must be defined in the StoreService, not in the model
// (otherwise the default here will override the super Object.assign())
export class Store extends BitfStore {
  logoPath = 'assets/graphics/ambimusic-logo-light-theme.png';
  logoSymbolPath = 'assets/graphics/ambimusic_logo_symbol-light-theme.png';
  ambimusicLogoPath = 'assets/graphics/ambimusic-logo-light-theme.png';
  ambimusicLogoSquaredPath = 'assets/graphics/ambimusic-logo-squared-light-theme.png';

  // Set selectedStyle to change style
  selectedStyle: string;
  // currentStyle: Is the current style, used to remove when selectedStyle is changed
  currentStyle = 'blue-dark-theme';
  user: User;
  company: Company;
  zones = {
    selectedZoneItem: undefined,
    breadcrumbs: [],
  };

  get logoUrl() {
    if (this.company && this.company.logoUrl) {
      return this.company.logoUrl;
    }
    return `assets/graphics/ambimusic-logo-${this.themeSlug}.png`;
  }

  get logoSymbolUrl() {
    return `assets/graphics/ambimusic_logo_symbol-${this.themeSlug}.png`;
  }

  get logoSquaredUrl() {
    return `assets/graphics/ambimusic-logo-squared-${this.themeSlug}.png`;
  }

  constructor(storeData: Store) {
    super(storeData);

    if (storeData.currentStyle) {
      this.currentStyle = storeData.currentStyle;
    }
  }

  private get themeSlug(): string {
    let colorTheme: string[] = [];
    if (this.user && this.user.settings.theme) {
      colorTheme = this.user.settings.theme.split('-');
    } else {
      colorTheme = this.currentStyle.split('-');
    }
    return `${colorTheme[1]}-${colorTheme[2]}`;
  }
}
