import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from 'environments/environment';
import { map, switchMap, catchError } from 'rxjs/operators';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { Company, Location, Zone } from '@models';
import { AuthService, mapZones, mapLocations } from '@core/services/api';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { BitfTryCatch } from '@decorators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends ApiSuperService {
  static traverseTree(tree, cb) {
    function traverser(item) {
      cb(item);
      if (item.children) {
        item.children.forEach(l => traverser(l));
      }
    }
    if (tree instanceof Array) {
      tree.forEach(c => traverser(c));
    } else {
      traverser(tree);
    }
  }
  static reverseTraverseTree(tree, cb) {
    function traverser(item) {
      cb(item);
      if (item.parent) {
        traverser(item.parent);
      }
    }
    traverser(tree);
  }
  static getTreeMap(tree) {
    const treeMap = { Company: [], Location: [], Zone: [], all: [] };
    CompanyService.traverseTree(tree, item => {
      treeMap[item.instanceOf].push(item);
      treeMap.all.push(item);
    });
    return treeMap;
  }
  static treeDiff(item, treeMap) {
    let isInAccessTreeMap;
    if (treeMap && treeMap[item.instanceOf]) {
      isInAccessTreeMap = treeMap[item.instanceOf].find(i => i.id === item.id);
    }
    if (!isInAccessTreeMap && item.selected) {
      return 'add';
    } else if (isInAccessTreeMap && !item.selected) {
      return 'remove';
    } else {
      return 'same';
    }
  }

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected bitfErrorHandlerService: BitfErrorHandlerService
  ) {
    super('companies', http, mapCompany, mapCompanies);
  }

  create(company: Company) {
    return super.create(company).pipe(
      switchMap((newCompany: any) => {
        if (company.logoToUpload) {
          newCompany.logoToUpload = company.logoToUpload;
          return this.uploadLogo(newCompany);
        } else {
          return of(newCompany);
        }
      }),
      catchError((error, obs) =>
        this.bitfErrorHandlerService.catchObs(error, obs, {
          className: 'CompanyService',
          functionName: 'create',
        })
      )
    );
  }

  update(company: Company) {
    return super.update(company).pipe(
      switchMap(updatedCompany => {
        if (company.logoToUpload) {
          if (company.logoUrl) {
            return this.deleteLogo(updatedCompany).pipe(
              switchMap(() => {
                return this.uploadLogo(company);
              })
            );
          } else {
            return this.uploadLogo(company);
          }
        } else {
          return of(updatedCompany);
        }
      }),
      catchError((error, obs) =>
        this.bitfErrorHandlerService.catchObs(error, obs, {
          className: 'CompanyService',
          functionName: 'update',
        })
      )
    );
  }

  delete(company) {
    // Async operation
    if (company.logoUrl) {
      this.deleteLogo(company, false).subscribe();
    }
    return super.delete(company);
    // return this.locationService.find({ where: { companyId: company.id } }).pipe(
    //   map((locations: any) => {
    //     // Async operation
    //     locations.forEach(location => {
    //       this.locationService.delete(location).subscribe();
    //     });
    //   }),
    //   switchMap(() => {
    //     return this.zoneService.find({ where: { companyId: company.id } });
    //   }),
    //   map((zones: any) => {
    //     // Async operation
    //     zones.forEach(zone => {
    //       this.zoneService.delete(zone).subscribe();
    //     });
    //   }),
    //   switchMap(() => {
    //     return super.delete(company);
    //   })
    // );
  }

  readLocations(company) {
    return this.http
      .get<Location[]>(`${environment.apiUrl}${this.name}/${company.id}/locations`)
      .pipe(map(mapLocations));
  }

  findLocations(company) {
    return this.http
      .get<Location[]>(`${environment.apiUrl}${this.name}/${company.id}/locations`)
      .pipe(map(mapLocations));
  }

  findZones(company) {
    return this.http.get<Zone[]>(`${environment.apiUrl}${this.name}/${company.id}/zones`).pipe(map(mapZones));
  }

  // TODO move this in the API (currently the filter is not applied)
  getTree() {
    const filter = {
      include: [
        {
          relation: 'locations',
          scope: {
            where: { accountId: this.authService.user.account.id },
            include: {
              relation: 'zones',
              scope: {
                where: { accountId: this.authService.user.account.id },
              },
            },
          },
        },
        {
          relation: 'zones',
          scope: {
            where: { accountId: this.authService.user.account.id },
          },
        },
      ],
    };
    return this.http.get(`${environment.apiUrl}${this.name}`, super.buildOptions({ filter })).pipe(
      map((companies: any) => {
        return companies.map(company => new Company(company));
      }),
      catchError((error, obs) =>
        this.bitfErrorHandlerService.catchObs(error, obs, {
          className: 'CompanyService',
          functionName: 'getTree',
        })
      )
    );
  }

  uploadLogo(company: Company) {
    return this.createLogo(company).pipe(
      switchMap((logoUploaded: any) => {
        company.logoName = logoUploaded.logoName;
        company.logoToUpload = undefined;
        return this.update(company);
      })
    );
  }

  @BitfTryCatch()
  createLogo(company) {
    const logoToUpload: File = company.logoToUpload;
    const endpoint = `${environment.apiUrl}companylogos/${environment.awsS3CompanyLogosBucket}/upload`;
    const formData: FormData = new FormData();
    // String(Date.now())
    formData.append('fileKey', logoToUpload, `${company.id}-${logoToUpload.name}`);
    return this.http.post(endpoint, formData).pipe(
      map((response: any) => {
        console.log(response);
        return {
          url: response.result.files.fileKey[0].providerResponse.location,
          logoName: response.result.files.fileKey[0].providerResponse.name,
        };
      }),
      catchError((error, obs) =>
        this.bitfErrorHandlerService.catchObs(error, obs, {
          className: 'CompanyService',
          functionName: 'createLogo',
        })
      )
    );
  }

  @BitfTryCatch()
  deleteLogo(company, update = true) {
    let fileName = company.logoUrl;
    if (fileName.includes('/')) {
      fileName = fileName.split('/');
      fileName = fileName[fileName.length - 1];
    }
    const endpoint = `${environment.apiUrl}companylogos/${
      environment.awsS3CompanyLogosBucket
    }/files/${fileName}`;
    const apiRequest = this.http.delete(endpoint);
    if (update) {
      apiRequest.pipe(
        switchMap(deletedLogo => {
          company.logoUrl = null;
          return this.update(company);
        })
      );
    }
    return apiRequest;
  }
}

export function mapCompany(company) {
  return new Company(company);
}
export function mapCompanies(companies) {
  return companies.map(company => mapCompany(company));
}
