import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiSuperService } from '@core/services/api/api-super.service';
import { Product } from '@models';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class StripeProductService extends ApiSuperService {
  constructor(protected http: HttpClient, private bitfErrorHandlerService: BitfErrorHandlerService) {
    super('stripeproducts', http, mapModel, mapModels);
  }

  getProductsInfos() {
    return this.request({
      method: 'get',
      path: `${this.name}/products-infos`,
    }).pipe(map(this.modelsMapper));
  }

  subscribeToProductPlans(body) {
    return this.request({
      method: 'post',
      path: `${this.name}/subscribe-to-product-plans`,
      body,
    }).pipe(
      catchError((error, obs) => {
        this.bitfErrorHandlerService.handle(error, {
          className: 'StripeProductService',
          functionName: 'subscribeToProductPlans',
        });
        return throwError(error);
      })
    );
  }

  unsubscribeFromProductPlan(zoneId) {
    return this.request({
      method: 'post',
      path: `${this.name}/unsubscribe-from-product-plan`,
      body: { zoneId },
    }).pipe(
      catchError((error, obs) => {
        this.bitfErrorHandlerService.handle(error, {
          className: 'StripeProductService',
          functionName: 'subscribeToProductPlans',
        });
        return throwError(error);
      })
    );
  }

  attachSourceToCustomer(sourceId) {
    return this.request({
      method: 'post',
      path: `${this.name}/attach-source-to-customer`,
      body: { sourceId },
    }).pipe(
      catchError((error, obs) => {
        this.bitfErrorHandlerService.handle(error, {
          className: 'StripeProductService',
          functionName: 'attachSourceToCustomer',
        });
        return throwError(error);
      })
    );
  }

  getCustomer() {
    return this.request({
      method: 'get',
      path: `${this.name}/get-customer`,
    }).pipe(
      catchError((error, obs) => {
        this.bitfErrorHandlerService.handle(error, {
          className: 'StripeProductService',
          functionName: 'getCustomer',
        });
        return throwError(error);
      })
    );
  }

  getCoupon(queryParams) {
    return this.request({
      method: 'get',
      path: `${this.name}/get-coupon?couponCode=${queryParams.couponCode}&quantity=${queryParams.quantity}`,
    });
  }

  getCartTotals(data) {
    return this.request({
      method: 'post',
      path: `openapis/get-cart-totals`,
      body: data,
    });
  }

  getPricingModel() {
    return this.request({
      method: 'get',
      path: `openapis/get-pricing-model`,
    });
  }
}

export function mapModel(user) {
  return new Product(user);
}
export function mapModels(users) {
  return users.map(user => mapModel(user));
}
