import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Company } from '@models';
import { CompanyService } from '@core/services/api';
import { BitfTryCatch } from '@decorators';

@Component({
  selector: 'am-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit {
  @Input()
  tree: any;
  @Output()
  treeChanged = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  leafChanged(leaef) {
    this.treeChanged.emit(this.tree);
  }

  checkBoxClicked(item, parent, grandParent) {
    this.updateChidren(item);
    this.updateParents(item);
    this.treeChanged.emit(this.tree);
  }

  @BitfTryCatch()
  updateChidren(item) {
    CompanyService.traverseTree(item, el => {
      el.selected = item.selected;
    });
  }

  @BitfTryCatch()
  updateParents(item) {
    if (item.selected) {
      // Select parents in path
      CompanyService.reverseTraverseTree(item, el => {
        el.selected = item.selected;
      });
    } else {
      CompanyService.reverseTraverseTree(item, el => {
        const parent = el.parent;
        if (parent) {
          const childSelected = parent.children.find(child => child.selected);
          if (!childSelected) {
            el.parent.selected = false;
          }
        }
      });
    }
  }
}
