import { IBitfApiRequest, IBitfPathBuilder } from '@interfaces';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';
export abstract class BitfRestPathBuilder implements IBitfPathBuilder {
  constructor() {}

  build(requestParams: IBitfApiRequest = {}): string {
    let path = '';
    const id = requestParams.id || bitfGetProp(requestParams, 'body', 'id');
    if (id) {
      path += `/${id}`;
    }

    const action = requestParams.action;
    if (action) {
      path += `/${action}`;
    }

    if (requestParams.relation) {
      path += `/${requestParams.relation}`;

      if (requestParams.linkUnlinkRel) {
        path += '/rel';
      }

      const relationId = bitfGetProp(requestParams, 'body', 'id') || requestParams.relationId;
      if (relationId) {
        path += `/${relationId}`;
      }
    }

    if (requestParams.count) {
      path += '/count';
    }
    return path;
  }
}
