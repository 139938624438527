import { Component, OnInit, ViewChild, ViewContainerRef, Injector } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { filter, switchMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// tslint:disable-next-line:max-line-length
import { BitfMatSidenavGlobalService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav-global.service';
import { AppSessionService, StoreService } from '@core/services';
import { AuthService } from '@core/services/api';
import { bitfRemoveAsset, bitfLoadAsset } from '@common/libs/bitforce/utils/bitf-load-assets.utils';
import { IBitfStoreEvent } from './interfaces';

import { EStoreActions } from './enums';
import { Store, User } from './models';
import { environment } from '@env/environment';

@Component({
  selector: 'am-root',
  templateUrl: './am.component.html',
  styleUrls: ['./am.component.scss'],
})
export class AmComponent implements OnInit {
  @ViewChild('sidenav', { static: true })
  sidenav: MatSidenav;
  @ViewChild('sidenavContent', { read: ViewContainerRef, static: true })
  sidenavContent;

  mSidenavGlobalService: BitfMatSidenavGlobalService;

  isEmbedded = false;
  appComponentReady = false;

  constructor(
    http: HttpClient,
    public authService: AuthService,
    private router: Router,
    public injector: Injector,
    appSessionService: AppSessionService,
    private storeService: StoreService
  ) {
    this.mSidenavGlobalService = this.injector.get(BitfMatSidenavGlobalService);

    appSessionService.init();

    // Force redirect to https
    // const appName = environment.appName;
    // if (appName !== 'am-fe-tom-development' && appName !== 'am-fe-mic-development') {
    //   let href = location.href;
    //   if (!href.includes('https://')) {
    //     href = href.replace('http://', 'https://');
    //     location.href = href;
    //   }
    // }
    window['env'] = () => {
      console.log(environment);
    };
  }

  ngOnInit() {
    this.init();

    this.loadAsset(this.storeService.store.currentStyle);
    this.authService.user$.subscribe((user: User) => {
      this.appComponentReady = false;
      if (user && user.settings.theme) {
        this.loadAsset(user.settings.theme);
      } else {
        this.loadAsset(this.storeService.store.currentStyle);
      }
    });

    this.storeService.selectStore(EStoreActions.SET_STYLE).subscribe((storeEvent: IBitfStoreEvent<Store>) => {
      this.appComponentReady = false;
      this.loadAsset(storeEvent.store.selectedStyle);
    });
  }

  private loadAsset(name: string) {
    bitfRemoveAsset({
      fileUrl: `${this.storeService.store.currentStyle}.css`,
      id: 'theme-color',
    });
    this.storeService.setStore(store => {
      store.currentStyle = name;
    });
    bitfLoadAsset({
      fileUrl: `${name}.css`,
      id: 'theme-color',
      addBuildVersion: true,
    }).then(() => {
      setTimeout(() => {
        this.appComponentReady = true;
      }, 1000);
    });
  }

  private init() {
    this.mSidenavGlobalService.init(this.sidenav, this.sidenavContent);

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((data: any) => {
      if (data.url.includes('embeds/')) {
        this.isEmbedded = true;
      }
    });
  }
}
