import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IBitfOkCancelDialogData, IBitfCloseEvent } from '@interfaces';
import { EBitfCloseEventStatus } from '@enums';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'bitf-bitf-mat-ok-cancel-dialog',
  templateUrl: './bitf-mat-ok-cancel-dialog.component.html',
  styleUrls: ['./bitf-mat-ok-cancel-dialog.component.scss'],
})
export class BitfMatOkCancelDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BitfMatOkCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IBitfOkCancelDialogData
  ) {}

  onOk() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
    } as IBitfCloseEvent<void>);
  }

  onCancel() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }
  ngOnInit() {}
}
