import { LibraryItem } from '@models';

export class PlaylistItem {
  instanceOf = 'PlaylistItem';
  id: number;
  accountId: number;
  libraryItemId: number;
  playlistId: number;
  libraryItem: LibraryItem;
  orderIndex: number;

  constructor(data?) {
    if (data.libraryItem) {
      data.libraryItem = new LibraryItem(data.libraryItem);
      data.libraryItemId = data.libraryItem.id;
    }
    Object.assign(this, data);
  }

  get item() {
    if (this.libraryItem) {
      return this.libraryItem.item;
    }
  }

  get serialised() {
    return {
      id: this.id,
      accountId: this.accountId,
      libraryItemId: this.libraryItemId,
      playlistId: this.playlistId,
      orderIndex: this.orderIndex,
    };
  }
}
