import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[amValidator]',
})
export class ValidatorDirective implements OnChanges {
  @Input()
  ngModel;
  constructor() {
    console.log(this.ngModel);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    // console.log(
    //   changes.amValidator.currentValue && changes.amValidator.currentValue.model
    // );
  }
}
