import { environment } from '@env';
import { map } from 'rxjs/operators';

const modelToCollectionMap = {
  Company: 'companies',
  Location: 'locations',
  Zone: 'zones',
  Stream: 'streams',
  Song: 'songs',
  Playlist: 'playlists',
  Schedule: 'schedule',
};

const collectionToModelMap = {
  companies: 'Company',
  locations: 'Location',
  zones: 'Zone',
  streams: 'Stream',
  songs: 'Song',
  playlists: 'Playlist',
  schedules: 'Schedule',
};

export class ApiSuperService {
  static fromModelToCollection(modelName) {
    if (modelToCollectionMap[modelName]) {
      return modelToCollectionMap[modelName];
    }
  }
  static fromCollectionToModel(collectionName) {
    if (collectionToModelMap[collectionName]) {
      return collectionToModelMap[collectionName];
    }
  }
  static fromModelToService(modelName: String) {
    return `${modelName.toLocaleLowerCase()}Service`;
  }
  constructor(protected name, protected http, protected modelMapper, protected modelsMapper) {}

  find(filter = {}) {
    return this.http
      .get(`${environment.apiUrl}${this.name}`, this.buildOptions({ filter }))
      .pipe(map(this.modelsMapper));
  }

  findById(id, filter = {}) {
    if (id instanceof Array) {
      const ids = id.map(i => `id=${i}`).join('&');
      return this.http
        .get(`${environment.apiUrl}${this.name}?${ids}`, this.buildOptions({ filter }))
        .pipe(map(this.modelsMapper));
    } else {
      return this.http
        .get(`${environment.apiUrl}${this.name}/${id}`, this.buildOptions({ filter }))
        .pipe(map(this.modelMapper));
    }
  }

  count(filter = {}) {
    const options: any = {};
    if (filter) {
      options.params = { where: JSON.stringify(filter) };
    }
    return this.http.get(`${environment.apiUrl}${this.name}/count`, options);
  }

  create(item) {
    return this.http.post(`${environment.apiUrl}${this.name}`, item.serialised).pipe(map(this.modelMapper));
  }

  update(item) {
    return this.http
      .patch(`${environment.apiUrl}${this.name}/${item.id}`, item.serialised)
      .pipe(map(this.modelMapper));
  }

  bulkUpdate(filter = {}, data) {
    return this.http.post(`${environment.apiUrl}${this.name}/update`, data, {
      params: { where: JSON.stringify(filter) },
    });
  }

  delete(item) {
    return this.http.delete(`${environment.apiUrl}${this.name}/${item.id}`);
  }

  addRef(item, releation, refId) {
    return this.http.put(`${environment.apiUrl}${this.name}/${item.id}/${releation}/rel/${refId}`, {});
  }

  deleteRef(item, releation, refId) {
    return this.http.delete(`${environment.apiUrl}${this.name}/${item.id}/${releation}/rel/${refId}`, {});
  }

  findRel(relation: string, modelInstance, filter: any = {}) {
    return this.http.get(
      `${environment.apiUrl}${this.name}/${modelInstance.id}/${relation}`,
      this.buildOptions({ filter })
    );
  }

  updateOrderIndexes(newOrderIndexes) {
    return this.http.patch(`${environment.apiUrl}${this.name}/order-index`, newOrderIndexes);
  }

  request({ method, path, body, filter, headers }: any = { filter: {}, body: {} }) {
    switch (method) {
      case 'get':
        return this.http[method](`${environment.apiUrl}${path}`, this.buildOptions({ filter, headers }));
      case 'post':
      case 'put':
      case 'patch':
        return this.http[method](`${environment.apiUrl}${path}`, body);
    }
  }

  fetch(
    { method, path, body, filter, headers, timeout }: any = {
      filter: {},
      body: {},
    }
  ) {
    switch (method) {
      case 'get':
        return this.http[method](`${path}`, this.buildOptions({ filter, headers, timeout }));
      case 'post':
      case 'put':
      case 'patch':
        return this.http[method](`${path}`, body);
    }
  }

  buildOptions({ filter, headers, timeout }: any) {
    const options: any = {
      params: {},
    };
    if (filter) {
      options.params.filter = JSON.stringify(filter);
    }
    if (timeout) {
      options.timeout = timeout;
    }
    if (headers) {
      // TODO set haders with set method
      // options.headers = new HttpHeaders(headers)
    }
    return options;
  }
}
