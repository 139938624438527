<header>
  <mat-toolbar class="d-flex justify-content-between fixed-top">
    <ng-container>
      <!-- Company logo or Ambimusic Icon -->
      <img *ngIf="storeService.store.logoUrl"
        [src]="storeService.store.logoUrl"
        class="d-none d-lg-block main-logo"
        [ngClass]="{'d-block': storeService.store.company}" />
      <!-- Full Ambimusic logo -->
      <img *ngIf="storeService.store.logoSymbolUrl && !storeService.store.company"
        [src]="storeService.store.logoSymbolUrl"
        class="d-block d-lg-none main-logo" />

      <div class="d-block d-sm-none">
        <button mat-icon-button
          (click)="openMainMenu()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </ng-container>

    <nav *ngIf="authService.user"
      class="d-none d-sm-flex">
      <button *bitfUiRoleManager="{action: ERoleActions.CAN_VIEW_LIBRARY, mode: ERoleMode.HIDDEN}"
        routerLink="library"
        routerLinkActive="selected"
        mat-button
        color="">
        <!-- <i *ngIf="!last" class="material-icons ">library_music</i>  -->
        Library
      </button>

      <button routerLink="zones"
        routerLinkActive="selected"
        mat-button
        color="">
        <!-- <i *ngIf="!last" class="material-icons ">speaker</i>  -->
        Zones
      </button>

      <button *bitfUiRoleManager="{action: ERoleActions.CAN_VIEW_USERS, mode: ERoleMode.HIDDEN}"
        routerLink="users"
        routerLinkActive="selected"
        mat-button
        color="">
        <!-- <i *ngIf="!last" class="material-icons ">group</i>  -->
        Users
      </button>

      <button *bitfUiRoleManager="{action: ERoleActions.CAN_VIEW_BOXES, mode: ERoleMode.HIDDEN}"
        routerLink="boxes"
        routerLinkActive="selected"
        mat-button
        color="">
        <!-- <i *ngIf="!last" class="material-icons ">router</i>  -->
        Boxes
      </button>

      <!-- <button
        routerLink="shop"
        routerLinkActive="selected"
        mat-button
        color="">
        Shop
      </button> -->

      <button *ngIf="authService.user"
        mat-button
        [ngClass]="{'selected': isProfileMenuActive}"
        [matMenuTriggerFor]="menu"
        color="">
        {{ authService.user.name }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item *ngIf="authService.isSuperAdmin" routerLink="shop/subscriptions">
          <mat-icon>subscriptions</mat-icon>
          <span>Subscriptions</span>
        </button>
        <button mat-menu-item *ngIf="authService.isSuperAdmin" routerLink="shop/invoices">
          <mat-icon>receipt</mat-icon>
          <span>Invoices</span>
        </button> -->
        <button mat-menu-item
          *bitfUiRoleManager="{action: ERoleActions.CAN_MANAGE_ACCOUNT_PROFILE, mode: ERoleMode.HIDDEN}"
          routerLink="settings/profile"
          routerLinkActive="selected">
          <mat-icon>account_circle</mat-icon>
          <span>Profile</span>
        </button>

        <button mat-menu-item
          routerLink="settings/general"
          routerLinkActive="selected">
          <mat-icon>settings</mat-icon>
          <span>Settings</span>
        </button>

        <button mat-menu-item
          routerLink="account/logout">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

      <!-- <button *ngIf="authService.user" routerLink="help" mat-icon-button color="">
        <i class="material-icons">help_outline</i>
      </button> -->

      <!-- <button *ngIf="!authService.user" routerLink="account/login" routerLinkActive="selected" mat-button color="">Login</button> -->
    </nav>
  </mat-toolbar>
</header>