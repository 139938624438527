import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Zone } from '@models';
import { EBitfCloseEventStatus } from '@app/enums';

@Component({
  selector: 'am-player-info-dialog',
  templateUrl: './player-info-dialog.component.html',
  styleUrls: ['./player-info-dialog.component.scss'],
})
export class PlayerInfoDialogComponent implements OnInit {
  zone: Zone;
  playerEngine;
  constructor(
    public dialogRef: MatDialogRef<PlayerInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.zone = data.zone;
    // NOTE: we have to use the playerEngine zone to get the updated boxState data
    // because the boxRefChanges is not emitted to avoid too many calls
    this.playerEngine = data.playerEngine;
  }

  restartBox() {
    if (!this.zone.box) {
      return;
    }
    this.playerEngine.restartBox();
  }

  factoryResetBox() {
    if (!this.zone.box) {
      return;
    }
    this.playerEngine.factoryResetBox();
  }

  updateBoxFirmware() {
    if (!this.zone.box) {
      return;
    }
    this.playerEngine.updateBoxFirmware();
  }

  onOK() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
    });
  }

  ngOnInit() {}
}
