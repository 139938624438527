import { Location, Zone } from '@models';
import { BitfTryCatch } from '@decorators';

export class UserSettings {
  instanceOf = 'UserSettings';
  id: number;
  customerId: number;
  language: string;
  theme: string;
  timezone: string;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  get serialised() {
    // TODO locations and zones mapped and serialised
    return {
      id: this.id,
      language: this.language,
      theme: this.theme,
      timezone: this.timezone,
    };
  }
}
