import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AngularFireDatabase } from 'angularfire2/database';
import { map } from 'rxjs/operators';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { Schedule } from '@models';
import { ApiSuperService } from '@core/services/api/api-super.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService extends ApiSuperService {
  constructor(
    protected http: HttpClient,
    protected fireDb: AngularFireDatabase,
    protected bitfErrorHandlerService: BitfErrorHandlerService
  ) {
    super('schedules', http, mapSchedule, mapSchedules);
  }

  addLibraryItem(schedule, libraryItem) {
    const newLibraryItem = {
      libraryItemId: libraryItem.id,
    };
    return this.http
      .post(`${environment.apiUrl}${this.name}/${schedule.id}/scheduleitems`, newLibraryItem)
      .pipe(
        map(item => {
          // Update zone queueHash for zones that are playing this schedule
          // Non blocking action
          this.refreshHashes(schedule);
          return item;
        })
      );
  }

  removeLibraryItem(schedule, libraryItem) {
    return this.http
      .delete(`${environment.apiUrl}${this.name}/${schedule.id}/scheduleitems/${libraryItem.id}`)
      .pipe(
        map(item => {
          // Update zone queueHash for zones that are playing this schedule
          // Non blocking action
          this.refreshHashes(schedule);
          return item;
        })
      );
  }

  refreshHashes(schedule) {
    this.request({
      method: 'get',
      path: `zoneschedules`,
      filter: {
        where: { libraryItemId: schedule.libraryItemId },
        fields: { zoneId: true },
      },
    }).subscribe(zoneSchedules => {
      zoneSchedules.forEach(zoneSchedule => {
        const fbRef = this.fireDb.object(`zones/${zoneSchedule.zoneId}`);
        fbRef.update({
          fbHash: Date.now(),
          event: 'cms:schedulesHashChanged',
        });
      });
    });
  }
}

export function mapSchedule(schedule) {
  return new Schedule(schedule);
}
export function mapSchedules(playlists) {
  return playlists.map(schedule => mapSchedule(schedule));
}
