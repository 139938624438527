import { StripePlan } from '@models';

export class StripeProduct {
  instanceOf = 'StripeProduct';
  id: string;
  active: boolean;
  attributes: any;
  caption: any;
  created: number;
  deactivate_on: any;
  description: any;
  images: any;
  livemode: boolean;
  metadata: any;
  name: string;
  package_dimensions: any;
  shippable: any;
  statement_descriptor: string;
  type: string;
  unit_label: string;
  updated: number;
  url: string;
  stripePlans: StripePlan[];

  constructor(data = {}) {
    Object.assign(this, data);
    if (this.stripePlans.length) {
      this.stripePlans = this.stripePlans.map(plan => new StripePlan(plan));
    }
  }

  get serialised() {
    return {};
  }
}
