import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { switchMapTo, map } from 'rxjs/operators';

import { UserService, AuthService } from '@core/services/api';
import { NotifierService, DialogsService } from '@core/services';
import { User } from '@models';
// tslint:disable-next-line:max-line-length
import { ConfirmDialogComponent } from '@shared/common-utilities/confirm-dialog/confirm-dialog.component';
import { IBitfCloseEvent } from '@app/interfaces';
import { toEventData } from '@common/utils/to-event-data';

interface Password {
  oldPassword: string;
  newPassword: string;
}

@Component({
  selector: 'am-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  // This will came from url parm
  userId;
  user: User;
  password: Password = {
    oldPassword: '',
    newPassword: '',
  };
  detailsForm: FormGroup;
  passwordForm: FormGroup;
  errorChangePassword: string;

  fieldRequiredErrMsg = 'is required.';

  @Input()
  showPanels = ['personalData', 'changePassword', 'account'];
  @Input()
  embedded = true;
  @Output()
  userSaved = new EventEmitter();
  constructor(
    private notifierService: NotifierService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialogService: DialogsService
  ) {}

  ngOnInit() {
    this.initForms();

    if (!this.userId) {
      this.userService.findById(this.authService.user.id).subscribe((user: User) => {
        this.user = user;

        this.detailsForm.patchValue({
          ...user,
        });
      });
    }
  }

  private initForms() {
    this.detailsForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      company: ['', Validators.required],
      vatNumber: [''],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      state: ['', Validators.required],
    });

    // TODO: we may add pattern/custom validators to force a minimum password strength
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
    });
  }

  // Forms Values ------------------------------------------------------
  get detailsFormValue() {
    return this.detailsForm.value as Partial<User>;
  }

  get passwordFormValue() {
    return this.passwordForm.value as Password;
  }

  // Forms Fields -------------------------------------------------------
  get fieldName() {
    return this.detailsForm.get('name');
  }
  get fieldSurname() {
    return this.detailsForm.get('surname');
  }
  get fieldCompany() {
    return this.detailsForm.get('company');
  }
  get fieldVatNumber() {
    return this.detailsForm.get('vatNumber');
  }
  get fieldAddress() {
    return this.detailsForm.get('address');
  }
  get fieldCity() {
    return this.detailsForm.get('city');
  }
  get fieldPostalCode() {
    return this.detailsForm.get('postalCode');
  }
  get fieldState() {
    return this.detailsForm.get('state');
  }

  get fieldOldPassword() {
    return this.passwordForm.get('oldPassword');
  }
  get fieldNewPassword() {
    return this.passwordForm.get('newPassword');
  }

  updateUser() {
    // Updating `user` instance properties with form values
    Object.entries(this.detailsFormValue).forEach(([key, val]) => {
      this.user[key] = val;
    });

    this.userService
      .update(this.user)
      .pipe(switchMapTo(this.userService.updateStripeUserAccount(this.user)))
      .subscribe(
        user => {
          this.authService.user = this.user;
          this.userSaved.emit();
          this.notifierService.success();
        },
        error => {
          this.notifierService.error();
        }
      );
  }

  confirmDeleteAccount() {
    this.askForConfirm({
      title: 'Delete',
      message: `Do you want to delete your account ?`,
    })
      .pipe(toEventData())
      .subscribe(confirmed => {
        if (confirmed) {
          const service = this.userService.delete(this.authService.user).subscribe(() => {
            this.router.navigate(['/account/login']);
          });
        }
      });
  }

  private askForConfirm(data) {
    const dialogRef = this.dialogService.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      maxWidth: '400px',
      autoFocus: false,
      data,
    });
    return dialogRef.afterClosed();
  }

  deleteAccount() {}

  changePassword() {
    this.password = this.passwordFormValue;
    this.errorChangePassword = undefined;
    this.userService.changePassword(this.password).subscribe(
      user => {
        this.notifierService.success('Password changed');
        this.router.navigate(['/account/logout']);
      },
      error => {
        console.error(error);
        if (error.error.error.code === 'INVALID_PASSWORD') {
          this.errorChangePassword = error.error.error.message;
        } else {
          this.notifierService.error();
        }
      }
    );
  }
}
