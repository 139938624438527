import { Component, Injector } from '@angular/core';

import { WebPlayerComponent } from '../web-player/web-player.component';

@Component({
  selector: 'am-web-dock-player',
  templateUrl: '../shared-views/dock-player.component.html',
  styleUrls: ['../shared-views/dock-player.component.scss'],
})
export class WebDockPlayerComponent extends WebPlayerComponent {
  name = 'webDockPlayerlayer';

  constructor(protected injector: Injector) {
    super(injector);
  }

  openInfo() {
    this.showBoxDetails();
  }
}
