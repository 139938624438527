<div>
  <section>
    <h2>General settings</h2>
    <div class="row">
      <div class="col-24">
        <mat-slide-toggle [checked]="zoneSettings.crossFadeEnabled"
          name="crossFadeEnabled"
          (change)="slideToggleChage($event)">
          Enable cross fade
        </mat-slide-toggle>
      </div>
    </div>
    <div class="row mt-1">
      <mat-form-field class="col-24 col-sm-12 col-md-8">
        <input matInput
          type="text"
          [textMask]="{mask: numberMask}"
          [(ngModel)]="zoneSettings.crossFadeDuration"
          [disabled]="!zoneSettings.crossFadeEnabled"
          placeholder="Duration (seconds)" />
      </mat-form-field>
    </div>
  </section>

  <section class="mt-4">
    <h2>Ambimusic box settings</h2>
    <div class="row">
      <div class="col-24">
        <mat-slide-toggle [checked]="zoneSettings.autoStartEnabled"
          name="autoStartEnabled"
          (change)="slideToggleChage($event)">
          Enable autoplay on Ambimusic box startup
        </mat-slide-toggle>
      </div>
    </div>
  </section>

  <section class="mt-4">
    <h2>Update firmware hours range</h2>
    <div class="row">
      <mat-form-field class="col-10 col-md-5">
        <input matInput
          [(ngModel)]="zoneSettings.autoUpdateStartTime"
          [disabled]="zoneSettings.autoUpdateEnabled"
          placeholder="Start time"
          [textMask]="{ mask: timeMask, pipe: autoCorrectedDatePipe }" />
      </mat-form-field>
      <mat-form-field class="col-10 col-md-5">
        <input matInput
          [(ngModel)]="zoneSettings.autoUpdateEndTime"
          [disabled]="zoneSettings.autoUpdateEnabled"
          placeholder="End time"
          [textMask]="{ mask: timeMask, pipe: autoCorrectedDatePipe }" />
      </mat-form-field>
    </div>
    <div class="mat-caption">
      Please notice that during firmware update the playback will stop for about 3 minutes
    </div>
  </section>

  <section class="mt-4">
    <h2>Download fallback content hours range</h2>
    <div class="row">
      <mat-form-field class="col-10 col-md-5">
        <input matInput
          [(ngModel)]="zoneSettings.downloadFallbackPlaylistStartTime"
          [disabled]="zoneSettings.autoDownloadFallbackPlaylistEnabled"
          placeholder="Start time"
          [textMask]="{ mask: timeMask, pipe: autoCorrectedDatePipe }" />
      </mat-form-field>
      <mat-form-field class="col-10 col-md-5">
        <input matInput
          [(ngModel)]="zoneSettings.downloadFallbackPlaylistEndTime"
          [disabled]="zoneSettings.autoDownloadFallbackPlaylistEnabled"
          placeholder="End time"
          [textMask]="{ mask: timeMask, pipe: autoCorrectedDatePipe }" />
      </mat-form-field>
    </div>
  </section>

  <section class="mt-4">
    <div class="row mt-3">
      <div class="col-24">
        <mat-slide-toggle [checked]="zoneSettings.setVolumeOnStartup"
          name="setVolumeOnStartup"
          (change)="slideToggleChage($event)">
          Set volume on startup<br>(otherwise the previous volume will be used)
        </mat-slide-toggle>
      </div>
      <div class="col-24 col-sm-12 mt-3">
        <mat-slider class="w-100"
          [disabled]="!zoneSettings.setVolumeOnStartup"
          [(ngModel)]="zoneSettings.volumeOnStartup"
          [max]="100"
          [min]="0"
          [step]="1"
          [thumbLabel]="true"
          [tickInterval]="1">
        </mat-slider>
      </div>
    </div>
  </section>

  <section class="mt-4">
    <div class="row">
      <mat-form-field *ngIf="timezones | async"
        class="col-24">
        <mat-select placeholder="Timezone"
          [(ngModel)]="zoneSettings.timezone"
          [compareWith]="selectTimezoneComparator">
          <mat-option *ngFor="let timezone of timezones | async"
            [value]="timezone.utc[0]">
            {{ timezone.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </section>

  <div class="d-flex justify-content-end mt-4">
    <button mat-button
      *ngIf="showCancel"
      (click)="cancel()"
      class="mr-3">Cancel</button>
    <button mat-raised-button
      color="accent"
      (click)="save()">Save</button>
  </div>
</div>