import { Component, OnInit, Injector } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

// tslint:disable-next-line:max-line-length
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { EBitfSidenavMode, EBitfSidenavPosition } from '@app/enums';

import { StoreService } from '@core/services';
import { AuthService } from '@core/services/api';
import { SidenavMenuComponent } from '@app/shared/sidenav-menu/sidenav-menu.component';
import { ERoleActions, ERoleMode } from '@enums';

@Component({
  selector: 'am-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isProfileMenuActive = false;
  ERoleActions = ERoleActions;
  ERoleMode = ERoleMode;
  constructor(
    public authService: AuthService,
    private injector: Injector,
    public router: Router,
    public storeService: StoreService
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((data: any) => {
      this.isProfileMenuActive = data.url.includes('settings/');
    });
  }

  ngOnInit() {}

  openMainMenu() {
    this.injector.get<BitfMatSidenavService>(BitfMatSidenavService).open({
      component: SidenavMenuComponent,
      sidenavOptions: {
        mode: EBitfSidenavMode.OVER,
        position: EBitfSidenavPosition.END,
        disableClose: false,
      },
    });
  }

  private setLogo() {}
}
