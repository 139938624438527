import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { NotFoundComponent } from '@core/not-found/not-found.component';
import { AuthGuard } from '@core/guards';

const routes: Routes = [
  { path: '', redirectTo: 'zones', pathMatch: 'full' },
  {
    path: 'account',
    loadChildren: () => import('apps/web/modules/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () => import('apps/web/modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'zones',
    loadChildren: () => import('apps/web/modules/zones/zones.module').then(m => m.ZonesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'library',
    loadChildren: () => import('apps/web/modules/library/library.module').then(m => m.LibraryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'boxes',
    loadChildren: () => import('apps/web/modules/boxes/boxes.module').then(m => m.BoxesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('apps/web/modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'help',
    loadChildren: () => import('apps/web/modules/help/help.module').then(m => m.HelpModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'shop',
    loadChildren: () => import('apps/web/modules/shop/shop.module').then(m => m.ShopModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'embeds',
    loadChildren: () => import('apps/web/modules/embeds/embeds.module').then(m => m.EmbedsModule),
  },
  {
    path: 'changelog',
    loadChildren: () =>
      import('./../../common/libs/bitforce/modules/changelog/bitf-changelog.module').then(
        m => m.BitfChangelogModule
      ),
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
