<h2 mat-dialog-title>Box <span *ngIf="zone?.box?.name">{{ zone.box.name }}</span> details</h2>

<mat-dialog-content>
  <div *ngIf="playerEngine?.zone?.boxState?.state?.network?.online?.isOnline">
    <mat-list>
      <h3 mat-subheader>Fallback content</h3>
      <mat-list-item *ngIf="playerEngine.zone.boxState.state.fallbackContent.cached">
        <span>Fallback content is ready!</span>
      </mat-list-item>
      <mat-list-item *ngIf="!playerEngine.zone.boxState.state.fallbackContent.cached">
        <span>Fallback content is not ready yet</span>
      </mat-list-item>
      <mat-list-item *ngIf="playerEngine.zone.boxState.state.fallbackContent.updateInProgress">
        <span>Downloading fallback content</span>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>

    <mat-list>
      <h3 mat-subheader>Network</h3>
      <mat-list-item>
        <span *ngIf="playerEngine.zone.boxState.state.network.online.isOnline">Box is connected</span>
        <span *ngIf="!playerEngine.zone.boxState.state.network.online.isOnline">Box is not connected</span>
      </mat-list-item>
      <mat-list-item>
        <span *ngIf="playerEngine.zone.boxState.state.network.online.isOnline">Connected at:&nbsp;</span>
        <span *ngIf="!playerEngine.zone.boxState.state.network.online.isOnline">Disconnected at:&nbsp;</span>
        {{ playerEngine.zone.boxState.state.network.online.lastOnlineOfflineTime | date: 'long' }}
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>

    <mat-list *ngIf="playerEngine.zone.boxState.state.network?.wireless?.address">
      <h3 mat-subheader>Wireless</h3>
      <mat-list-item>
        <span>IP Address:&nbsp;</span>
        {{ playerEngine.zone.boxState.state.network.wireless.address }}
      </mat-list-item>
      <mat-list-item>
        <span>Quality:&nbsp;</span> {{ playerEngine.zone.boxState.state.network.wireless.quality }}
      </mat-list-item>
      <mat-list-item>
        <span>Speed:&nbsp;</span> {{ playerEngine.zone.boxState.state.network.wireless.speed }}
      </mat-list-item>
      <mat-list-item>
        <span>SSID:&nbsp;</span> {{ playerEngine.zone.boxState.state.network.wireless.ssid }}
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>

    <mat-list *ngIf="playerEngine.zone.boxState.state.network.wired?.address">
      <h3 mat-subheader>Wired</h3>
      <mat-list-item>
        <span>IP Address:&nbsp;</span> {{ playerEngine.zone.boxState.state.network.wired.address }}
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>

    <mat-list *ngIf="playerEngine?.zone?.boxState?.system">
      <h3 mat-subheader>System</h3>
      <mat-list-item>
        <div class="d-flex flex-column">
          <div>
            Hardware type:&nbsp; {{ playerEngine.zone.boxState.system.hardware }} -
            {{ playerEngine.zone.boxState.system.variant }}
            <span *ngIf="playerEngine.zone.boxState.system.test"> - Debug mode active</span>
          </div>
          <div>
            Firmware v {{ playerEngine.zone.boxState.system.firmwareVersion }}
            <span *ngIf="playerEngine?.zone.boxState?.system?.updateAvailable">
              &nbsp; - Update v {{ playerEngine.zone.boxState.system.updateAvailable }} Available!
            </span>
          </div>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>

    <mat-list *ngIf="
        playerEngine?.zone?.boxState?.state?.storage?.internal ||
        playerEngine?.zone?.boxState?.state?.storage?.external
      ">
      <h3 mat-subheader>Disk usage</h3>
      <mat-list-item *ngIf="playerEngine.zone.boxState.state.storage.internal">
        <div class="d-flex flex-column">
          <div>
            Internal disk: Used: {{ playerEngine.zone.boxState.state.storage.internal.used }} -
            Free:
            {{ playerEngine.zone.boxState.state.storage.internal.free }}
            - Size: {{ playerEngine.zone.boxState.state.storage.internal.size }}
          </div>
          <div>
            <br />
            <mat-progress-bar mode="determinate"
              [value]="playerEngine.zone.boxState.state.storage.internal.usedPercentage"></mat-progress-bar>
            <br />
          </div>
        </div>
      </mat-list-item>
      <mat-divider *ngIf="playerEngine.zone.boxState.state.storage.internal"></mat-divider>

      <!-- <mat-list-item *ngIf="playerEngine.zone.boxState.state.storage.external">
        <div>
          <h3>External</h3>
          <div>
            Used: {{playerEngine.zone.boxState.state.storage.external.used}} - Free: {{playerEngine.zone.boxState.state.storage.external.free}}
            - Size: {{playerEngine.zone.boxState.state.storage.external.size}}
          </div>
          <div>
            <mat-progress-bar mode="determinate"
              [value]="playerEngine.zone.boxState.state.storage.external.usedPercentage"></mat-progress-bar>
          </div>
        </div>
      </mat-list-item>
      <mat-divider *ngIf="playerEngine.zone.boxState.state.storage.external"></mat-divider> -->
    </mat-list>

    <br />
    <br />

    <div class="d-flex justify-space-around">
      <button mat-raised-button
        color="warn"
        (click)="restartBox()"
        matTooltip="Your box will restart, should back online in a few minutes">
        Restart Box
      </button>
      <button mat-raised-button
        color="warn"
        (click)="factoryResetBox()"
        matTooltip="Your box will reset to factory default values. You'll have to register the box again, should back online in a few minutes">
        Factory reset Box
      </button>
      <button mat-raised-button
        color="warn"
        (click)="updateBoxFirmware()"
        *ngIf="playerEngine?.zone.boxState?.system?.updateAvailable"
        matTooltip="Your box will update the firmware, should back online in half hour">
        Update box firmware
      </button>
    </div>
    <hr />
  </div>

  <div *ngIf="!playerEngine?.zone?.boxState?.state?.network?.online?.isOnline">
    Box offline
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button mat-raised-button
    (click)="onOK()"
    color="primary">
    Ok
  </button>
</mat-dialog-actions>