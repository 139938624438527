import { PlaylistItem } from '@models';

export class Playlist {
  instanceOf = 'Playlist';
  id: number;
  accountId: number;
  libraryItemId: number;
  name: string;
  isShuffle: boolean;
  playlistItems: PlaylistItem[];

  constructor(data: any) {
    if (data.playlistItems) {
      data.playlistItems = data.playlistItems.map(item => new PlaylistItem(item));
    }
    Object.assign(this, data);
  }

  get serialised() {
    return {
      id: this.id,
      accountId: this.accountId,
      libraryItemId: this.libraryItemId,
      name: this.name,
      isShuffle: this.isShuffle,
    };
  }
}
