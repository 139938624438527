import { LibraryItem } from '@models';

const defaultQueueData = {
  random: false,
  repeat: false,
};
export class QueueItem {
  instanceOf = 'QueueItem';
  id: number;
  zoneId: number;
  libraryItemId: number;
  libraryItem;
  random: boolean;
  repeat: boolean;

  constructor(data: any = {}) {
    if (data.libraryItem) {
      data.libraryItem = new LibraryItem(data.libraryItem);
    }
    Object.assign(this, defaultQueueData, data);
  }

  get serialised() {
    return {
      id: this.id,
      zoneId: this.zoneId,
      libraryItemId: this.libraryItemId,
      random: this.random,
      repeat: this.repeat,
    };
  }
}
