/**
 * Methods for mange silece detection
 * All of this methods are callable via playerEngineInstance.mehtod
 * @mixin
 */
export const silenceDetectionMixin = {
  silenceDetectionMixinLoaded: true,

  /**
   * @description This function is called when the box detect silence (song broken, stream broken)
   * the player engine should try to provide an alternative source
   */
  silenceDetected(source) {
    try {
      this.errorHandler.handle(null, {
        description: 'Silence detected',
        source
      });
      this.silenceDetectedSource = source;
      const currentSourceType = this.zone.source && this.zone.source.type;
      // if (currentSourceType === 'Song') {
      //   this.next();
      // } else if (currentSourceType === 'Stream') {
      //   this.next();
      // }
      // Try to call next in all cases also when the source is not present
      // eg: empty queue or empty playlist
      this.next();
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'silenceDetectionMixin',
        functionName: 'silenceDetected'
      });
    }
  },

  /**
   * @description This function is called when the box detect that the stream passed as param is back on line /
   * is emitting sound, the PE should check if that stream is still available in the queue and put that in play
   */
  streamResumed(resumedStream) {
    this.resumedStream = resumedStream;
    this._resumeStream('pe:playback:fadeNext');
  },

  _resumeStream(event) {
    try {
      // Don't re set the stream if is playing a song
      const currentSourceType = this.zone.source && this.zone.source.type;
      if (event === 'pe:playback:fadeNext' && currentSourceType !== 'Stream') {
        return;
      }

      if (this.resumedStream) {
        const streamIndex = this.queueItems.findIndex(
          queueItem =>
            queueItem.libraryItem.type === 'Stream' &&
            queueItem.libraryItem.stream.id === this.resumedStream.id
        );

        this.silenceDetectedSource = undefined;
        this.resumedStream = undefined;

        if (streamIndex >= 0) {
          this.queueIndex = streamIndex;
          this._updateZoneSource({ event });
          return true;
        }
        return false;
      }
      return false;
    } catch (error) {
      this.errorHandler.handle(error, {
        className: 'silenceDetectionMixin',
        functionName: '_resumeStream'
      });
    }
  }
};
