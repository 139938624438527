import { LibraryItem, Box } from '@models';

export const defaultData = {
  name: 'Default name',
  volume: 50,
  prevVolume: 50,
  isPlaying: false,
  isMute: false,
  isRandom: false,
  disableTresholdDays: 3,
};

export class Zone {
  instanceOf = 'Zone';
  id: number;
  accountId: number;
  companyId: number;
  locationId: number;
  fallbackLibraryItemId: number;
  sourceType: string;
  source: any;
  name: string;
  settings: any = {};
  isWithBox: boolean;
  subscriptionStartAt: number;
  subscriptionEndAt: number;
  subscriptionTimeInterval: string;
  subscriptionLength: number;
  disableTresholdDays: number;
  invoicePdf: string;
  hostedInvoiceUrl: string;

  queueIndex: number;
  playlistIndex: number;
  volume: number;
  prevVolume: number;
  isPlaying: boolean;
  isMute: boolean;
  isRandom: boolean;
  canEnablePrevNext: boolean;
  box: Box;
  boxState: any;
  parent;

  constructor(data: any = {}) {
    Object.assign(this, defaultData, data);
    if (this.box) {
      this.box = new Box(this.box);
    }

    this.settings.downloadFallbackPlaylistEndTime = this.setDefaultTime(
      this.settings.downloadFallbackPlaylistEndTime
    );
    this.settings.downloadFallbackPlaylistStartTime = this.setDefaultTime(
      this.settings.downloadFallbackPlaylistStartTime
    );
    this.settings.autoUpdateStartTime = this.setDefaultTime(this.settings.autoUpdateStartTime);
    this.settings.autoUpdateEndTime = this.setDefaultTime(this.settings.autoUpdateEndTime);
  }

  get isSubscriptionExpired() {
    if (!this.subscriptionStartAt) {
      return false;
    }
    const nowTime = Date.now();
    if (nowTime > this.subscriptionEndAt) {
      return true;
    }
  }

  get disableTresholdDate() {
    return +this.subscriptionEndAt + +this.disableTresholdDays * 24 * 60 * 60 * 1000;
  }

  get serialised() {
    const state = this.state;
    return {
      id: this.id,
      companyId: this.companyId,
      locationId: this.locationId,
      accountId: this.accountId,
      fallbackLibraryItemId: this.fallbackLibraryItemId,
      ...state,
    };
  }

  get state() {
    return {
      name: this.name,
    };
    // id: this.id,
    //   name: this.name,
    //   queueIndex: this.queueIndex,
    //   playlistIndex: this.playlistIndex,
    //   volume: this.volume,
    //   prevVolume: this.prevVolume,
    //   isPlaying: this.isPlaying,
    //   isMute: this.isMute,
    //   isRandom: this.isRandom
  }

  get clonable() {
    return {
      settings: this.settings,
      box: this.box && this.box.serialised,
      isWithBox: this.isWithBox,
      subscriptionStartAt: this.subscriptionStartAt,
      subscriptionEndAt: this.subscriptionEndAt,
      subscriptionTimeInterval: this.subscriptionTimeInterval,
      subscriptionLength: this.subscriptionLength,
      ...this.serialised,
    };
  }

  private setDefaultTime(time: any): any {
    return !time || time === '' ? '00:00' : time;
  }
}
